import React from 'react';
import PersonalInfo from 'components/application/PersonalInfo';
import { courseDuration, courseRenewalFrequency, learningMethodMap } from 'components/helpers/resources/courses';
import { displayCurrencyString } from 'components/helpers/currency';
import { resourceShape } from 'components/helpers/serialisableResources'
import { useCurrentActor } from 'components/contexts/CurrentActor';

export default function BookingSidePanelCourseTab({ course }) {
  const currentActor = useCurrentActor();
  const currencyCode = currentActor.division.attributes.currencyCode;
  const costEstimate = course.attributes.costEstimate ? displayCurrencyString({ string: String(course.attributes.costEstimate), currencyCode }) : "";
  const method = learningMethodMap[course.attributes.learningMethod];

  return (
    <div className='popup__body-content popup__body-content--border-top tw-border-grey-100'>
      <PersonalInfo label='Renewal frequency' text={courseRenewalFrequency({ course })} />
      <PersonalInfo label='Method' text={method} />
      <PersonalInfo label='Provider' text={course.attributes.provider ? String(course.attributes.provider) : ""} />
      <PersonalInfo label='Cost estimate' text={costEstimate} />
      <PersonalInfo label='Time estimate' text={courseDuration({ course })} />
    </div>
  )
}

BookingSidePanelCourseTab.propTypes = {
  course: resourceShape('course')
}
