import classNames from 'classnames';
import React from 'react';

export default function SegmentedControls({ activeChoice, choices, onItemClick }) {
  return(
    <span className='segmented-controls tw-border-grey-200 tw-bg-white hover:tw-border-grey-300'>
      {choices.map((choice) => {
        return (
          <span
            className={classNames(
              'segmented-controls__item tw-text-s tw-font-medium tw-tracking-wide',
              activeChoice === choice ? 'segmented-controls__item--active tw-bg-blue-025 tw-text-blue-900' : 'hover:tw-bg-grey-050'
            )}
            key={`segmentedControlItem--${choice}`}
            onClick={() => onItemClick(choice)}
          >
            {choice}
          </span>
        )
      })}
    </span>
  )
}
