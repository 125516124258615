import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { resourceShape } from 'components/helpers/serialisableResources';
import { asDate } from 'components/helpers/dates';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useBreadBoard } from 'components/contexts/Toaster';

import PersonIcon from '-!svg-react-loader?name=PersonIcon!icons/person-lg.svg';
import PersonnelShow from 'components/personnel/PersonnelShow';
import ShowFooter from 'components/sidepanels/ShowFooter';
import ArchiveButton from 'components/archive/ArchiveButton';
import PersonnelDetails from 'components/personnel/PersonnelDetails';
import PersonnelTeamDetails from 'components/personnel/PersonnelTeamDetails.jsx'
import PersonnelManagementDetails from 'components/personnel/PersonnelManagementDetails.jsx'

const numberOfOngoingProjects = 6;

export default function PersonnelPanel(props) {
  const {
    currentPersonnel,
    personnel,
    user,
    division,
    subcontractor,
    lineManager,
    teams,
    roles,
    openSidePanel,
    onArchiveButtonClick,
    onDeleteClick,
    onSendInvitationClick,
    availableFields,
    availableFieldOptions
  } = props;

  const breadBoard = useBreadBoard();
  const { isViewingOwnProfile, hasPersonnelEditableAccess, hasPersonnelProfileAccessEnabled, hasProfileAccessEditableAccess, hasTeamViewableAccess } = useTrainingRegisterResources();
  const [ongoingProjects, setOngoingProjects] = useState({ loaded: false, collection: [], metaData: { totalCount: 0 } })
  const [wereOngoingProjectsPreviouslyFetched, setWereOngoingProjectsPreviouslyFetched] = useState(false);

  const isArchived = !!currentPersonnel.archivedAt;
  const isUserWithProfileAccess = user && (user.attributes.accessType !== 'personnel' || user.attributes.profileAccessEnabled);
  const isPersonnelDetailsVisible = currentPersonnel.lineManagerId || currentPersonnel.email || currentPersonnel.externalId || currentPersonnel.teamsIds.length > 0 || Object.keys(currentPersonnel.fieldValues).length > 0;
  const isProfileAccessDetailsVisible = !isViewingOwnProfile && hasPersonnelProfileAccessEnabled;

  const handleArchiveButtonMouseEnter = () => {
    if (!isArchived && currentPersonnel.isParticipatingInOngoingProjects && !wereOngoingProjectsPreviouslyFetched) {
      setWereOngoingProjectsPreviouslyFetched(true);
      fetchOngoingProjects();
    }
  };

  const fetchOngoingProjects = () => {
    axios
      .get(`/personnel/${currentPersonnel.id}/ongoing_projects`, { params: { per: numberOfOngoingProjects } })
      .then((response) => {
        setOngoingProjects(prevState => ({ loaded: true, collection: prevState.collection.concat(response.data.data), metaData: response.data.meta }))
      })
      .catch(breadBoard.addInedibleToast)
  }

  return (
    <Fragment>
      <div className='tw-border-1 tw-border-solid tw-rounded-md tw-bg-white tw-border-grey-100'>
        <Header />
        {personnel && (
          <PersonnelShow
            currentPersonnel={currentPersonnel}
            division={division}
            subcontractor={subcontractor}
            personnel={personnel}
            roles={roles}
            isCompact={true}
            withPersonnelDetails={false}
            availableFields={availableFields}
            availableFieldOptions={availableFieldOptions}
          />
        )}
        {hasPersonnelEditableAccess && (
          <ShowFooter
            onEditClick={openSidePanel}
            onDeleteClick={onDeleteClick}
            isCompact={true}
            isEditButtonHidden={isArchived}
          >
            <ArchiveButton
              disabled={!isArchived && currentPersonnel.isParticipatingInOngoingProjects}
              disabledTooltip={<DisabledArchivingPersonnelToolTip currentPersonnel={currentPersonnel} ongoingProjects={ongoingProjects} />}
              isCompact={true}
              isArchived={isArchived}
              onClick={onArchiveButtonClick}
              onMouseEnter={handleArchiveButtonMouseEnter}
            />
          </ShowFooter>
        )}
      </div>
      {(isProfileAccessDetailsVisible || isPersonnelDetailsVisible) && (
        <div className='content-box m-t-16 tw-bg-white tw-border-grey-100'>
          <div className='p-20'>
            <h2 className='tw-text-l tw-font-semibold m-0 m-b-16'>Personnel details</h2>
            {isProfileAccessDetailsVisible && (() => {
              if (subcontractor) { return <ProfileAccessDetails label='No access' text='Sub-contractors can’t access their training profiles' /> }
              if (personnel && isUserWithProfileAccess) { return <ProfileAccessStatusDetails personnel={personnel} user={user} isInviteSendable={hasProfileAccessEditableAccess} onSendInvitationClick={onSendInvitationClick} /> }
              if (personnel && !personnel.attributes.email) { return <ProfileAccessDetails label='Not invited' text='No email address' /> }
              if (personnel && !isUserWithProfileAccess) { return <ProfileAccessDetails label='Not invited' text='Personnel can’t view their profile' /> }
            })()}
            {isPersonnelDetailsVisible && (
              <PersonnelDetails
                isCompact={true}
                defaultDetails={[{ label: 'Personnel ID', text: currentPersonnel.externalId }, { label: 'Email', text: currentPersonnel.email }]}
                currentPersonnel={currentPersonnel}
                availableFields={availableFields}
                availableFieldOptions={availableFieldOptions}
              />
            )}
          </div>
        </div>
      )}
      {hasTeamViewableAccess && (
        <div className='content-box m-t-16 tw-bg-white tw-border-grey-100 tw-max-h-[640px] overflow-auto'>
          <div className='tw-px-5 tw-pt-6 tw-mb-6'>
            <h2 className={`tw-text-l tw-font-semibold m-0 ${Object.keys(teams).length === 0 ? 'tw-mb-4' : 'tw-mb-6'}`}>Teams</h2>
            <PersonnelTeamDetails teams={teams} />
          </div>
        </div>
      )}
      {
        <div className='content-box m-t-16 tw-bg-white tw-border-grey-100 tw-max-h-[640px] overflow-auto'>
          <div className='tw-px-5 tw-pt-6'>
            <h2 className='tw-text-l tw-font-semibold m-0 tw-mb-4'>Management</h2>
            <PersonnelManagementDetails
              currentPersonnel={currentPersonnel}
              lineManager={lineManager}
            />
          </div>
        </div>
      }
    </Fragment>
  )
}

PersonnelPanel.propTypes = {
  currentPersonnel: PropTypes.object.isRequired,
  personnel: resourceShape('personnel'),
  division: resourceShape('company'),
  subcontractor: resourceShape('subcontractor'),
  lineManager: resourceShape('lineManager'),
  teams: PropTypes.objectOf(resourceShape('team')),
  roles: PropTypes.arrayOf(resourceShape('companyRole')),
  openSidePanel: PropTypes.func.isRequired,
  onArchiveButtonClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  availableFields: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    collection: PropTypes.arrayOf(resourceShape('fieldAttribute'))
  }),
  availableFieldOptions: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    collection: PropTypes.arrayOf(resourceShape('fieldOption'))
  })
};

export function Header() {
  return (
    <div className='tw-flex tw-justify-center tw-items-center tw-mt-8 tw-mb-6'>
      <div className='tw-flex tw-justify-center tw-items-center tw-h-20 tw-w-20 tw-rounded-full tw-bg-grey-600'>
        <PersonIcon className='[&_path]:tw-fill-white' width={32} height={32} />
      </div>
    </div>
  )
}

export function DisabledArchivingPersonnelToolTip({ currentPersonnel, ongoingProjects }) {
  const projectNames = ongoingProjects.collection.map(item => item.attributes.name);
  const otherCount = ongoingProjects.metaData.totalCount - numberOfOngoingProjects;

  return (
    <div className='tw-w-[320px] tw-text-left'>
      <p className='tw-w-[300px]'>{currentPersonnel.firstName} must be removed from the following RAMS to be archived:</p>
      <ul className='tw-m-0 tw-px-3'>
        {projectNames.map((project, index) => {
          return (<li key={index}>{project}</li>)
        })}
      </ul>
      {otherCount > 0 && (<p className='tw-mt-3 tw-mb-0'>+{otherCount} {otherCount > 1 ? 'others' : 'other'}</p>)}
    </div>
  )
}

DisabledArchivingPersonnelToolTip.propTypes = {
  currentPersonnel: PropTypes.object.isRequired,
  ongoingProjects: PropTypes.object.isRequired
}

export function ProfileAccessDetails({ label, text, children }) {
  return (
    <div className='tw-mb-4'>
      <div className='tw-text-grey-900 tw-font-medium'>{label}</div>
      <div>{text}</div>
      {children && <div>{children}</div>}
    </div>
  )
}

ProfileAccessDetails.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export function ProfileAccessStatusDetails({ personnel, user, isInviteSendable, onSendInvitationClick }) {
  const { invitationStatus, invitationSentAt, invitationAcceptedAt } = user.attributes;

  const statusLabel = {
    'none': 'Not invited',
    'pending': 'Invite pending',
    'expired': 'Invite expired',
    'accepted': 'Invite accepted'
  }[invitationStatus];

  const statusText = {
    'none': 'Personnel can’t view their profile',
    'pending': `Sent ${invitationSentAt && asDate(invitationSentAt)}`,
    'expired': `Expired ${invitationSentAt && asDate(moment.parseZone(invitationSentAt).add(10, 'days'))}`,
    'accepted': asDate(invitationAcceptedAt)
  }[invitationStatus];

  return (
    <ProfileAccessDetails
      label={statusLabel}
      text={statusText}
    >
      {isInviteSendable && ['none', 'pending', 'expired'].includes(invitationStatus) && (
        <a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' onClick={() => onSendInvitationClick({ personnel })}>{invitationStatus === 'none' ? 'Send' : 'Resend'} invitation</a>
      )}
    </ProfileAccessDetails>
  )
}

ProfileAccessStatusDetails.propTypes = {
  personnel: resourceShape('personnel').isRequired,
  user: resourceShape('user').isRequired,
  isInviteSendable: PropTypes.bool.isRequired,
  onSendInvitationClick: PropTypes.func
}
