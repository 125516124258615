import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources'

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { TrainingRegisterResourceManagementContext } from 'components/contexts/TrainingRegisterResourceManagementContext';

import Tabs from 'components/application/Tabs';
import PersonnelTab from 'components/personnel/PersonnelTab';
import TeamsTab from 'components/teams/TeamsTab';
import RolesTab from 'components/roles/RolesTab';
import CoursesTab from 'components/courses/CoursesTab';
import SubcontractorsTab from 'components/subcontractors/SubcontractorsTab';
import BookingsTab from 'components/bookings/BookingsTab';
import TrainingRegisterReportsTab from 'components/trainingRegisterReports/TrainingRegisterReportsTab';
import { useQueryClient } from "@tanstack/react-query";
import useWindowStorage from 'components/hooks/useWindowStorage';
import useWindowStorageRemove from 'components/hooks/useWindowStorageRemove';

const trainingRegisterTabs = ['personnel', 'teams', 'roles', 'course register', 'bookings', 'sub-contractors', 'reports'];
const trainingRegisterStorageKeys = ['trainingRegister|initialActiveTab', ...trainingRegisterTabs.map(tab => `trainingRegister|${tab}`)];
export default function PersonnelPage(props) {
  const {
    hasPersonnelViewableAccess,
    hasPersonnelEditableAccess,
    hasTeamViewableAccess,
    hasTeamEditableAccess,
    hasTeamDashboardAccess,
    hasRoleEditableAccess,
    hasCourseEditableAccess,
    primaryDivision,
    isMultiDivisionAccount,
    hasSubcontractorPersonnelViewableAccess,
    hasTrainingWriteAccess,
    hasArchivedPersonnelAccess,
    hasTRPersonnelAccess,
    hasSubordinateManagementFeatureAccess,
    hasProfileAccessEditableAccess,
    // not TR within context
    managerAccessTypes,
    emailFilter,
    initialTrainingFilters,
    hasCourseViewableAccess,
    hasCompanyRolesViewableAccess,
    hasSubcontractorViewableAccess,
    isCourseExpiryDateEditable,
    hasBookingGroupViewableAccess,
    hasBookingGroupEditableAccess,
    isAutoEnrolmentIncludingMissingTraining // At account level so can be on RAMS division,
  } = props;

  const currentActor = useCurrentActor();
  const queryClient = useQueryClient();

  const [getCurrentDivision, setCurrentDivision] = useWindowStorage(`trainingRegister|currentDivision`, { store: window.sessionStorage });

  useWindowStorageRemove(trainingRegisterStorageKeys, () => {
    if (performance.getEntriesByType('navigation')[0].type === 'reload' || !document.referrer) return false

    try {
      const referrerUrl = new URL(document.referrer);
      return currentActor.division.id !== getCurrentDivision() || !referrerUrl.pathname.startsWith('/personnel')
    } catch {
      return false
    }
  }, { store: window.sessionStorage })

  setCurrentDivision(currentActor.division.id)

  const searchParams = new URLSearchParams(window.location.search);
  const searchParamsTab = trainingRegisterTabs.includes(searchParams.get('tab')) ? searchParams.get('tab') : null;
  const [getInitialActiveTabStore, setInitialActiveTabStore] = useWindowStorage(`trainingRegister|initialActiveTab`, { store: window.sessionStorage });

  const initialActiveTab = searchParamsTab || getInitialActiveTabStore();

  const hasTeamManagerAccess = managerAccessTypes.includes('teamManager');
  const hasLineManagerAccess = managerAccessTypes.includes('lineManager');
  const reportsTabViewable = currentActor.isAllowedFeature('training_register') && hasPersonnelViewableAccess;

  const trainingRegisterContextValues = {
    hasPersonnelViewableAccess,
    hasPersonnelEditableAccess,
    hasTeamViewableAccess,
    hasTeamEditableAccess,
    hasTeamDashboardAccess,
    hasRoleEditableAccess,
    hasCourseEditableAccess,
    hasSubordinateManagementFeatureAccess,
    hasProfileAccessEditableAccess,
    primaryDivision,
    isMultiDivisionAccount,
    hasLineManagerAccess,
    hasTeamManagerAccess,
    hasSubcontractorPersonnelViewableAccess,
    hasTrainingWriteAccess,
    hasArchivedPersonnelAccess,
    hasTRPersonnelAccess,
    isCourseExpiryDateEditable,
    hasBookingGroupViewableAccess,
    hasBookingGroupEditableAccess,
    isAutoEnrolmentIncludingMissingTraining
  };

  const handleTabClick = (tab) => {
    setInitialActiveTabStore(tab);
    if (tab === 'personnel') {
      queryClient.removeQueries({ queryKey: ['teams'] });
    }
  }

  return (
    <TrainingRegisterResourceManagementContext.Provider value={trainingRegisterContextValues}>
      <Tabs initialActiveTab={initialActiveTab} onTabClick={handleTabClick}>
        {hasPersonnelViewableAccess && (
          <PersonnelTab
            key='personnelTab'
            label='personnel'
            emailFilter={emailFilter}
            initialTrainingFilters={initialTrainingFilters}
          />
        )}
        {hasTeamDashboardAccess && (
          <TeamsTab
            key='teamsTab'
            label='teams'
          />
        )}
        {hasCompanyRolesViewableAccess && (
          <RolesTab
            key='rolesTab'
            label='roles'
          />
        )}
        {hasCourseViewableAccess && (
          <CoursesTab
            key='coursesTab'
            label='course register'
          />
        )}
        {hasBookingGroupViewableAccess && (
          <BookingsTab
            key='bookingsTab'
            label='bookings'
          />
        )}
        {hasSubcontractorViewableAccess && (
          <SubcontractorsTab
            key='subcontractorsTab'
            label='sub-contractors'
          />
        )}
        {reportsTabViewable && (
          <TrainingRegisterReportsTab
            key='trainingRegisterReportsTab'
            label='reports'
          />
        )}
      </Tabs>
    </TrainingRegisterResourceManagementContext.Provider>
  )
}

PersonnelPage.propTypes = {
  primaryDivision: PropTypes.object.isRequired,
  isMultiDivisionAccount: PropTypes.bool.isRequired,
  hasPersonnelViewableAccess: PropTypes.bool.isRequired,
  hasPersonnelEditableAccess: PropTypes.bool.isRequired,
  hasTeamViewableAccess: PropTypes.bool.isRequired,
  hasTeamEditableAccess: PropTypes.bool.isRequired,
  hasTeamDashboardAccess: PropTypes.bool.isRequired,
  hasRoleEditableAccess: PropTypes.bool.isRequired,
  hasCourseEditableAccess: PropTypes.bool.isRequired,
  primaryDivision: resourceShape('division'),
  isMultiDivisionAccount: PropTypes.bool.isRequired,
  hasSubcontractorPersonnelViewableAccess: PropTypes.bool.isRequired,
  hasTrainingWriteAccess: PropTypes.bool.isRequired,
  hasArchivedPersonnelAccess: PropTypes.bool.isRequired,
  initialTrainingFilters: PropTypes.object,
  ramsPluralName: PropTypes.string,
  emailFilter: PropTypes.string,
  managerAccessTypes: PropTypes.arrayOf(PropTypes.oneOf(['teamManager', 'lineManager'])),
  hasTRPersonnelAccess: PropTypes.bool,
  hasCourseViewableAccess: PropTypes.bool.isRequired,
  hasSubcontractorViewableAccess: PropTypes.bool.isRequired,
  hasCompanyRolesViewableAccess: PropTypes.bool.isRequired,
  hasBookingGroupViewableAccess: PropTypes.bool.isRequired,
  hasProfileAccessEditableAccess: PropTypes.bool.isRequired,
  hasSubordinateManagementFeatureAccess: PropTypes.bool.isRequired,
  isCourseExpiryDateEditable: PropTypes.bool.isRequired,
  hasBookingGroupEditableAccess: PropTypes.bool.isRequired,
  isAutoEnrolmentIncludingMissingTraining: PropTypes.bool.isRequired,
}
