import React, { useState, useEffect, useRef } from 'react';

import { useInView } from 'react-intersection-observer';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import Paginator from 'components/application/Paginator';
import DestroyModal from 'components/application/DestroyModal';
import CourseSidePanel from 'components/courses/sidepanel/CourseSidePanel';
import CoursesTable from 'components/courses/CoursesTable';
import CoursesBar from 'components/courses/CoursesBar';
import ResourceChangedToast from 'components/application/ResourceChangedToast';
import ResourceBlankNotice from 'components/application/ResourceBlankNotice';
import UpdateConfirmationModal from 'components/courses/UpdateConfirmationModal';
import AdditionalDestroyModalContent from 'components/courses/AdditionalDestroyModalContent';

import useForm from 'components/hooks/useForm';
import useDebounce from 'components/hooks/useDebounce';
import useSidePanel from 'components/hooks/useSidePanel';
import useRequestError from 'components/hooks/useRequestError';
import useWindowStorage from 'components/hooks/useWindowStorage';
import useValidatedStore from 'components/hooks/useValidatedStore';

import { defaultCourse, courseAsParams, asCourseObject, hasCourseProviderTypeChanged, hasCourseBecomeELearning } from 'components/helpers/resources/courses';

import AddCourseIcon from '-!svg-react-loader?name=AddCourseIcon!icons/addcourse-xl.svg';

import { z } from 'zod';
import { paramsForCreate as courseCompanyRolesParamsForCreate, paramsForUpdate as courseCompanyRolesParamsForUpdate } from 'components/helpers/resources/courseCompanyRoles'
import { autoEnrolmentConfigurationParamsForCreate, autoEnrolmentConfigurationParamsForUpdate } from 'components/helpers/resources/autoEnrolments'

const defaultCourses = { loaded: false, collection: [], originalCourseCollection: {}, eLearningCourseCollection: {}, autoEnrolmentConfigurationCollection: {} };
const defaultPersonnel = { loaded: false, collection: [], includedSubcontractors: [], includedBookings: [], includedRegistrations: [], includedTrainings: [], includedAutoEnrolmentExclusions: [], meta: { currentPage: 1, isLastPage: false } };
const formatCourseCompanyRoles = (data) => {
  return data.map(ccr => ({ companyRoleId: ccr.relationships.companyRole.data.id, required: ccr.attributes.required }))
};

const initialTabStore = {
  currentSearch: '',
  page: 1,
  currentResourceId: '',
  currentResourceName: '',
  sidePanelIsOpen: false,
};

const tabSchema = z.object({
  currentResourceId: z.string().nullable(),
  currentResourceName: z.string(),
  currentSearch: z.string(),
  page: z.number().or(z.null()),
  sidePanelIsOpen: z.boolean(),
});


export default function CoursesTab({ label }) {
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();

  const [getStore, setStore] = useWindowStorage(`trainingRegister|${label}`, { store: window.sessionStorage });
  const tabStore = useValidatedStore({ getStore, initialStore: initialTabStore, schema: tabSchema });
  const initialCurrentSearch = { coursesSearch: tabStore.currentSearch };
  const initialDefaultCourse = { ...defaultCourse, id: tabStore.currentResourceId, name: tabStore.currentResourceName };
  const isTabStoreCourseLoaded = useRef(false);
  const isTabStoreSidePanelOpen = useRef(tabStore.sidePanelIsOpen);

  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();
  const [courses, setCourses] = useState(defaultCourses);
  const [sidePanelIsOpen, _setSidePanelIsOpen, openSidePanel, closeSidePanel, _resetSidePanelContext, sidePanelContext, setSidePanelContext] = useSidePanel(false, 'show');
  const [currentCourse, setCurrentCourse, handleCourseInputChange, handleCourseOptionChange] = useForm(initialDefaultCourse);
  const [currentSearch, setCurrentSearch, handleSearchInputChange] = useForm(initialCurrentSearch);
  const [debouncedCurrentSearch, _resetDebouncedCurrentSearch] = useDebounce(currentSearch, 250);
  const [selectedCourseId, setSelectedCourseId] = useState(null)
  const [metaData, setMetaData] = useState({ currentPage: null, totalPages: null, scopedCount: 0, totalCount: 0 });
  const [destroyModalIsOpen, setDestroyModalIsOpen] = useState(false);
  const [updateConfirmationModalIsOpen, setUpdateConfirmationModalIsOpen] = useState(false);
  const [isLoadMorePersonnelActive, setIsLoadMorePersonnelActive] = useState(false);
  const [personnel, setPersonnel] = useState(defaultPersonnel);
  const [assignableRoles, setAssignableRoles] = useState({ loaded: false, collection: [] });
  const [courseCompanyRoles, setCourseCompanyRoles] = useState({ loaded: false, collection: [] });

  const currencyCode = currentActor.division.attributes.currencyCode;

  // computed
  const domainCourse = selectedCourseId && courses.collection.find(course => course.id === selectedCourseId);
  const originalCourse = (domainCourse && courses.originalCourseCollection[domainCourse.relationships.originalCourse.data?.id]) || null;

  const fetchCourseRoles = function () {
    axios
      .get(`/courses/${currentCourse.id}/company_roles`)
      .then(response => setCurrentRoles({ loaded: true, collection: response.data.data }))
      .catch(handleRequestError)
  };

  const fetchAssignableRoles = function () {
    axios
      .get('/assignable_company_roles')
      .then(response => setAssignableRoles({ loaded: true, collection: response.data.data }))
      .catch(handleRequestError)
  };

  const fetchCourseCompanyRoles = function () {
    axios
      .get(`/courses/${currentCourse.id}/course_company_roles`)
      .then((response) => {
        setCourseCompanyRoles({ loaded: true, collection: response.data })
        setCurrentCourse({ ...currentCourse, courseCompanyRoles: formatCourseCompanyRoles(response.data.data) })
      })
      .catch(breadBoard.addInedibleToast)
  }

  const fetchPersonnel = ({ increment }) => {
    setIsLoadMorePersonnelActive(false)
    let page = parseInt(personnel.meta.currentPage);
    if (increment) { page += 1 }
    axios
      .get(`/courses/${currentCourse.id}/personnel`, { params: { page } })
      .then(response => {
        setPersonnel(prevState => ({
          loaded: true,
          collection: prevState.collection.concat(response.data.data),
          includedSubcontractors: prevState.includedSubcontractors.concat(response.data.included.filter(obj => obj.type === 'subcontractor')),
          includedBookings: prevState.includedBookings.concat(response.data.included.filter(obj => obj.type === 'booking')),
          includedTrainings: prevState.includedTrainings.concat(response.data.included.filter(obj => obj.type === 'training')),
          includedRegistrations: prevState.includedRegistrations.concat(response.data.included.filter(obj => obj.type === 'registration')),
          includedAutoEnrolmentExclusions: prevState.includedAutoEnrolmentExclusions.concat(response.data.included.filter(obj => obj.type === 'autoEnrolmentExclusion')),
          meta: response.data.meta
        }))
        setIsLoadMorePersonnelActive(true)
      })
      .catch(breadBoard.addInedibleToast)
  };

  const areBookingsDestroyedOnUpdate = () => {
    return currentCourse.bookingCount > 0 && hasCourseProviderTypeChanged(currentCourse, courses.collection)
  };

  const refreshCourses = (page = 1) => {
    axios
      .get('/courses', { params: { search: currentSearch.coursesSearch, page: page } })
      .then((response) => {
        const courses = response.data.included.reduce((accumulator, includedResource) => {
          accumulator[`${includedResource.type}Collection`][includedResource.id] = includedResource;
          return accumulator
        }, { ...defaultCourses, loaded: true, collection: response.data.data });

        setCourses(courses)
        setMetaData(response.data.meta)
      })
      .catch(breadBoard.addInedibleToast)
  };

  const createCurrentCourse = () => {
    const courseCompanyRolesParams = courseCompanyRolesParamsForCreate({ currentId: currentCourse.id, frontendCourseCompanyRoles: currentCourse.courseCompanyRoles, currentType: 'course' })

    const autoEnrolmentConfigurationParams = currentActor.isAllowedFeature(['e_learning_auto_enrol', 'e_learning', 'training_register']) ? (
      autoEnrolmentConfigurationParamsForCreate({ currentCourse, currentContext: trainingRegisterResourceManagementContext })
    ) : null;

    axios
      .post('/courses', courseAsParams({ course: currentCourse, courseCompanyRolesParams, autoEnrolmentConfigurationParams, eLearningCourses: courses.eLearningCourseCollection, currencyCode }))
      .then(response => {
        resetCourses()
        breadBoard.addToast(
          <ResourceChangedToast
            resource={response.data.data.attributes.name}
            onBurnToast={breadBoard.handleBurnToast}
            status={'added'}
          />
        )
      })
      .catch(handleRequestError)
  };

  const confirmUpdateOrUpdateCurrentCourse = () => {
    areBookingsDestroyedOnUpdate() ? setUpdateConfirmationModalIsOpen(true) : updateCurrentCourse()
  };

  const updateCurrentCourse = () => {
    const courseCompanyRolesParams = courseCompanyRolesParamsForUpdate({
      currentId: currentCourse.id,
      serverCourseCompanyRoles: courseCompanyRoles.collection.data,
      frontendCourseCompanyRoles: currentCourse.courseCompanyRoles,
      currentType: 'course'
    })

    const autoEnrolmentConfigurationParams = (function determineAutoEnrolmentConfigurationParams() {
      if (currentActor.isAllowedFeature(['e_learning_auto_enrol', 'e_learning', 'training_register'])) {
        const domainAutoEnrolmentConfiguration = domainCourse?.relationships?.autoEnrolmentConfiguration?.data ? courses.autoEnrolmentConfigurationCollection[domainCourse.relationships.autoEnrolmentConfiguration.data?.id] : null

        return autoEnrolmentConfigurationParamsForUpdate({
          currentCourse,
          domainAutoEnrolmentConfiguration: domainAutoEnrolmentConfiguration,
          currentContext: trainingRegisterResourceManagementContext
        })
      } else {
        return null;
      }
    })();

    axios
      .patch(`/courses/${currentCourse.id}`, courseAsParams({ course: currentCourse, courseCompanyRolesParams, autoEnrolmentConfigurationParams, eLearningCourses: courses.eLearningCourseCollection, currencyCode }))
      .then(response => {
        areBookingsDestroyedOnUpdate() ? destroyCurrentCourseBookings() : resetCourses();
        breadBoard.addToast(
          <ResourceChangedToast
            resource={response.data.data.attributes.name}
            onBurnToast={breadBoard.handleBurnToast}
            status={'edited'}
          />
        )
      })
      .catch(handleRequestError)
  };

  const destroyCurrentCourseBookings = () => {
    axios
      .post(`/courses/${currentCourse.id}/bookings_bulk_destroy`)
      .then(_response => resetCourses())
      .catch(breadBoard.addInedibleToast)
  };

  const destroyCurrentCourse = () => {
    axios
      .delete(`/courses/${currentCourse.id}`)
      .then(_response => {
        resetCourses()
        breadBoard.addToast(
          <ResourceChangedToast
            resource={currentCourse.name}
            onBurnToast={breadBoard.handleBurnToast}
            status={'deleted'}
          />
        )
      })
      .catch(handleRequestError)
  };

  const resetCourses = () => {
    closeSidePanel()
    refreshCourses()
    setCurrentCourse(defaultCourse)
    resetRequestError()
  };

  const handleSearchReset = (event) => {
    const name = event.target.getAttribute('data-attr-name')
    setCurrentSearch({ ...currentSearch, [name]: '' })
  };

  const handlePageChange = (event) => {
    refreshCourses(event.currentTarget.getAttribute('data-page'))
  };

  const handleELearningProviderSelectedChange = ({ selected }) => {
    if (selected) {
      setCurrentCourse({ ...currentCourse, isELearningProviderSelected: true, learningMethod: 'e_learning', provider: 'iHasco' })
    } else {
      setCurrentCourse({ ...currentCourse, isELearningProviderSelected: false, learningMethod: '', provider: '' })
    }
  }

  const closePanel = () => {
    closeSidePanel()
    resetRequestError()
  };

  const viewCourse = (course, originalCourse) => {
    resetRequestError()
    setSelectedCourseId(course.id)

    const autoEnrolmentConfiguration = currentActor.isAllowedFeature(['e_learning_auto_enrol', 'e_learning', 'training_register']) ? courses.autoEnrolmentConfigurationCollection[course.relationships.autoEnrolmentConfiguration.data?.id] : null;

    setCurrentCourse(asCourseObject({ course, originalCourse, autoEnrolmentConfiguration, eLearningAllowed: currentActor.isAllowedFeature('e_learning'), currencyCode }))
    setSidePanelContext('show')
    openSidePanel()
  };

  const handleCourseRolesOptionChange = (roleId) => {
    const newCourseCompanyRoles = [...currentCourse.courseCompanyRoles, { companyRoleId: roleId, required: true }];
    setCurrentCourse({ ...currentCourse, courseCompanyRoles: newCourseCompanyRoles })
  }

  const handleCourseCompanyRoleRequiredOnChange = (roleId, value) => {
    const newCourseCompanyRoles = [...currentCourse.courseCompanyRoles];
    const courseCompanyRoleIndex = newCourseCompanyRoles.findIndex(ccr => ccr.companyRoleId == roleId);
    newCourseCompanyRoles[courseCompanyRoleIndex] = { ...newCourseCompanyRoles[courseCompanyRoleIndex], required: value };
    setCurrentCourse({ ...currentCourse, courseCompanyRoles: newCourseCompanyRoles })
  }

  const handleCourseCompanyRoleOnDelete = (roleId) => {
    const newCourseCompanyRoles = currentCourse.courseCompanyRoles.filter(ccr => ccr.companyRoleId != roleId);
    setCurrentCourse({ ...currentCourse, courseCompanyRoles: newCourseCompanyRoles })
  }

  if (tabStore.currentResourceId && !isTabStoreCourseLoaded.current && isTabStoreSidePanelOpen.current && courses.loaded && courses.collection.length !== 0) {
    const tabStoreCourse = courses.collection.find((course) => course.id === tabStore.currentResourceId);

    if (tabStoreCourse) {
      const originalCourseData = tabStoreCourse.relationships.originalCourse.data;
      const originalCourse = courses.originalCourseCollection[(originalCourseData || {}).id]
      viewCourse(tabStoreCourse, originalCourse);
    }

    isTabStoreCourseLoaded.current = true;
  };

  useEffect(() => {
    refreshCourses(tabStore.page)
  }, [])

  useEffect(() => {
    if (courses.loaded && debouncedCurrentSearch) { refreshCourses() }
  }, [debouncedCurrentSearch])

  useEffect(() => {
    // fetch roles only in these conditions to avoid request spamming
    if (!assignableRoles.loaded && sidePanelIsOpen) {
      fetchAssignableRoles()
    }

    if (currentCourse.id && sidePanelIsOpen) {
      fetchCourseCompanyRoles()
      fetchPersonnel({ increment: false })
    }

    if (!sidePanelIsOpen) {
      setPersonnel(defaultPersonnel)
      setIsLoadMorePersonnelActive(false)
    }
  }, [sidePanelIsOpen])

  const [loadMoreRef, loadMoreInView] = useInView();
  useEffect(() => {
    if (currentCourse.id && !personnel.meta.isLastPage && loadMoreInView) fetchPersonnel({ increment: true })
  }, [currentCourse.id, personnel.meta.isLastPage, loadMoreInView])

  useEffect(() => {
    setStore({
      currentSearch: currentSearch.coursesSearch,
      page: metaData.currentPage,
      currentResourceId: currentCourse.id,
      currentResourceName: currentCourse.name,
      sidePanelIsOpen
    });
  }, [currentSearch.coursesSearch, metaData.currentPage, currentCourse.id, currentCourse.name, sidePanelIsOpen])

  const coursesContent = (
    <React.Fragment>
      <CoursesTable
        courses={courses.collection}
        originalCourses={courses.originalCourseCollection}
        onRowClick={viewCourse}
      />
      {courses.loaded && courses.collection.length > 0 && metaData.totalPages > 1 && (
        <div className='m-t-80 text-center'>
          <Paginator
            currentPage={metaData.currentPage}
            totalPages={metaData.totalPages}
            onClick={handlePageChange}
          />
        </div>
      )}
    </React.Fragment>
  );

  const blankMessage = (trainingRegisterResourceManagementContext.hasCourseEditableAccess ?
    "Add the courses used in your company. All courses listed here will be available on personnel's profiles to record training."
    :
    'Any courses that have been created will be listed here'
  );

  const blankCourses = (
    <ResourceBlankNotice
      displayReadOnlyContents={!trainingRegisterResourceManagementContext.hasCourseEditableAccess}
      totalCount={metaData.totalCount}
      resource={'course'}
      icon={<AddCourseIcon width={64} height={64} className='[&_path]:tw-fill-grey-500 m-b--8' />}
      addButton={false}
      addMessage={blankMessage}
    />
  );

  return (
    <React.Fragment>
      {courses.loaded && (
        <React.Fragment>
          <CoursesBar
            actionButtonsVisible={trainingRegisterResourceManagementContext.hasCourseEditableAccess}
            setSidePanelContext={setSidePanelContext}
            setCurrentCourse={setCurrentCourse}
            openSidePanel={openSidePanel}
            coursesSearch={currentSearch.coursesSearch}
            coursesCount={metaData.totalCount}
            onSearchInputChange={handleSearchInputChange}
            onSearchReset={handleSearchReset}
            resetRequestError={resetRequestError}
          />
          {courses.collection.length > 0 ? coursesContent : blankCourses}
          <CourseSidePanel
            allowShowFooterContents={trainingRegisterResourceManagementContext.hasCourseEditableAccess}
            currentCourse={currentCourse}
            domainCourse={domainCourse}
            originalCourse={originalCourse}
            eLearningCourse={courses.eLearningCourseCollection[currentCourse && currentCourse.eLearningCourseId]}
            autoEnrolmentConfiguration={courses.autoEnrolmentConfigurationCollection[currentCourse && currentCourse.autoEnrolmentConfigurationId]}
            fetchCourseRoles={fetchCourseRoles}
            assignableRoles={assignableRoles.collection}
            isLoadMorePersonnelActive={isLoadMorePersonnelActive}
            personnel={personnel}
            requestError={requestError}
            submitDisabled={submitDisabled}
            removeErrorStyling={removeErrorStyling}
            isOpen={sidePanelIsOpen}
            closeCallback={closePanel}
            sidePanelContext={sidePanelContext}
            setSidePanelContext={setSidePanelContext}
            updateCourse={confirmUpdateOrUpdateCurrentCourse}
            createCourse={createCurrentCourse}
            onDeleteClick={() => setDestroyModalIsOpen(true)}
            onInputChange={handleCourseInputChange}
            onOptionChange={handleCourseOptionChange}
            onCourseRolesOptionChange={handleCourseRolesOptionChange}
            onCourseCompanyRoleRequiredChange={handleCourseCompanyRoleRequiredOnChange}
            onCourseCompanyRoleDelete={handleCourseCompanyRoleOnDelete}
            onRequestError={handleRequestError}
            onELearningProviderSelectedChange={handleELearningProviderSelectedChange}
            loadMoreRef={loadMoreRef}
          />
          {sidePanelContext === 'show' && (
            <DestroyModal
              isOpen={destroyModalIsOpen}
              displayText={`Are you sure you want to delete ${currentCourse.name}?`}
              confirmationText='Delete course and bookings'
              additionalContent={<AdditionalDestroyModalContent hasELearningBookings={currentCourse.isELearningProviderSelected && currentCourse.bookingCount > 0} />}
              onClose={() => setDestroyModalIsOpen(false)}
              onDestroy={destroyCurrentCourse}
            />
          )}
          {sidePanelContext === 'edit' && (
            <UpdateConfirmationModal
              isOpen={updateConfirmationModalIsOpen}
              onClose={() => setUpdateConfirmationModalIsOpen(false)}
              onSave={updateCurrentCourse}
              displayText={hasCourseBecomeELearning(currentCourse, courses.collection) ? 'This course has training booked which will be cancelled.' : 'This course has eLearning booked which will be cancelled. Any credits for these bookings will be refunded to your account.'}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
