import React from "react";
import PropTypes from "prop-types";

import { resourceShape } from "components/helpers/serialisableResources";
import { courseTrainingStatusOrdering } from "components/helpers/resources/training";
import { sortAlphabetically, sortByDate } from "components/helpers/arrays";

import Table from "components/application/Table";
import TrainingRow from "components/mobileTrainingProfile/components/homeScreen/TrainingRow";

export default function RoleTrainingTable({
  courses,
  userCoursesIndexedByCourseId,
  courseCompanyRolesIndexedByCourseId,
  bookingsIndexedByCourseId,
  registrationsIndexedByCourseId,
  trainingGroupedByCourseId,
  attachmentsGroupedByTrainingId,
  onViewCourseDetailsClick
}) {
  const rows = courses.reduce((acc, course) => {
    const latestTraining = (trainingGroupedByCourseId[course.id] && trainingGroupedByCourseId[course.id][0]) || null;
    const attachments = attachmentsGroupedByTrainingId[latestTraining?.id] || [];
    const booking = bookingsIndexedByCourseId[course.id];
    const registration = registrationsIndexedByCourseId[course.id];

    const userCourse = userCoursesIndexedByCourseId[course.id];
    const courseCompanyRole = courseCompanyRolesIndexedByCourseId[course.id];
    const isRequiredByUserCourse = userCourse && userCourse.attributes.required;
    const isRequiredByCourseCompanyRole = courseCompanyRole.attributes.required;
    const isRequired = isRequiredByUserCourse || isRequiredByCourseCompanyRole;

    let requiredSource;
    if (isRequiredByUserCourse) {
      requiredSource = "UserCourse";
    } else if (isRequiredByCourseCompanyRole) {
      requiredSource = "Role";
    }

    const orderingBand = courseTrainingStatusOrdering({ course, isRequired, training: latestTraining });

    acc.push({ course, latestTraining, attachments, booking, registration, isRequired, requiredSource, orderingBand })
    return acc
  }, []);

  const orderedRows = rows.sort((a, b) => {
    const sortByOrderingBand = (a, b) => a.orderingBand - b.orderingBand;
    const orderingSort = sortByOrderingBand(a, b);
    if (orderingSort !== 0) { return orderingSort }
    const dateSort = sortByDate(a.latestTraining && a.latestTraining.attributes.expiryDate, b.latestTraining && b.latestTraining.attributes.expiryDate);
    if (dateSort !== 0) { return dateSort }
    return sortAlphabetically(a.course.attributes.name, b.course.attributes.name)
  });

  return (
    <Table
      tableClassName="tw-border-t-0"
      headers={
        <tr>
          <th className="tw-text-s tw-text-grey-900 tw-font-medium tw-tracking-wide tw-px-9">Courses</th>
        </tr>
      }
      rows={
        orderedRows.map((row) => {
          return (
            <TrainingRow
              key={`roleCourseTraining--${row.course.id}`}
              course={row.course}
              latestTraining={row.latestTraining}
              attachments={row.attachments}
              booking={row.booking}
              registration={row.registration}
              isRequired={row.isRequired}
              requiredSource={row.requiredSource}
              onViewCourseDetailsClick={onViewCourseDetailsClick}
            />
          )
        })
      }
    />
  )
}

RoleTrainingTable.propTypes = {
  courses: PropTypes.arrayOf(resourceShape('course')).isRequired,
  userCoursesIndexedByCourseId: PropTypes.object.isRequired,
  courseCompanyRolesIndexedByCourseId: PropTypes.object.isRequired,
  bookingsIndexedByCourseId: PropTypes.object.isRequired,
  registrationsIndexedByCourseId: PropTypes.object.isRequired,
  trainingGroupedByCourseId: PropTypes.object.isRequired,
  attachmentsGroupedByTrainingId: PropTypes.object.isRequired,
  onViewCourseDetailsClick: PropTypes.func.isRequired
}
