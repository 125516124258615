import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from "components/helpers/serialisableResources";
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { sendAnalytics } from 'components/helpers/analytics';
import SidePanel from 'components/application/SidePanel';
import ErrorMessage from 'components/application/ErrorMessage';
import TrainingFormFields from 'components/training/TrainingFormFields';
import FormFooter from 'components/sidepanels/FormFooter';
import AttachmentsField from 'components/attachments/AttachmentsField';

export default function TrainingSidePanel(props) {
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const currentActor = useCurrentActor();
  const courseAttributes = (props.currentTraining.course && props.currentTraining.course.attributes) || {};
  const currentTrainingAttachments = useMemo(() => {
    return props.includedAttachments.filter(a => props.currentTraining.attachmentIds.includes(Number(a.id)))
  }, [props.includedAttachments, props.currentTraining.attachmentIds])

  const onBackdropClick = () => !(['new', 'edit'].includes(props.sidePanelContext)) && props.closeSidePanel();

  return (
    <SidePanel
      color={props.sidePanelContext === 'show' ? 'secondary' : 'tertiary'}
      isOpen={props.sidePanelIsOpen}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      displayClose={false}
      onBackdropClick={onBackdropClick}
      closeCallback={props.closeSidePanel}
      headerContent={
        <div className='popup__title popup__title--tertiary-split'>
          <h1 className='tw-text-s tw-font-medium tw-tracking-tight m-b-8'>{`${props.sidePanelContext === 'new' ? 'Record new' : 'Edit'} training`}</h1>
          <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>{courseAttributes.name}</h2>
        </div>
      }
      bodyContent={
        <div className='popup__body-form'>
          <ErrorMessage wrapperClassName='form-container' validationErrors={props.requestError.validationErrors} isFallback={props.requestError.isFallback} />
          <div className='form-container'>
            <TrainingFormFields
              currentTraining={props.currentTraining}
              domainCourse={props.domainCourse}
              requestError={props.requestError}
              removeErrorStyling={props.removeErrorStyling}
              onInputChange={props.onInputChange}
              onError={props.onError}
              includedAttachments={props.includedAttachments}
              includedCoverImages={props.includedCoverImages}
              sidePanelIsOpen={props.sidePanelIsOpen}
              submitDisabled={props.submitDisabled}
              personnelId={props.personnelId}
              onTrainingDateChange={props.onTrainingDateChange}
              onTrainingCalendarClose={props.onTrainingCalendarClose}
            />
            <AttachmentsField
              label='Upload evidence'
              tooltip='E.g. certificates or cards'
              name='attachmentIds'
              value={props.currentTraining.attachmentIds}
              isVisible={props.sidePanelIsOpen}
              seedAttachments={{ data: currentTrainingAttachments, included: props.includedCoverImages }}
              onChange={props.onInputChange}
              onError={props.onError}
              maxUploads={25}
              onUploadSuccess={() => sendAnalytics('Training evidence uploaded', { currentUser: currentActor.user, personnelId: props.personnelId })}
            />
          </div>
        </div>
      }
      footerContent={
        <FormFooter
          text={props.sidePanelContext === 'new' ? 'Record training' : 'Save changes'}
          onCancel={props.onCancelTraining}
          onSubmit={props.sidePanelContext === 'new' ? props.createTraining : props.updateTraining}
          submitButtonDisabled={props.submitDisabled}
        />
      }
      contentContext={props.sidePanelContext}
      submitDisabled={props.submitDisabled}
    />
  )
}

TrainingSidePanel.propTypes = {
  domainCourse: resourceShape('course'),
  currentTraining: PropTypes.object.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
  requestError: PropTypes.object.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  includedAttachments: PropTypes.array.isRequired,
  includedCoverImages: PropTypes.array.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  personnelId: PropTypes.string.isRequired,
  onTrainingDateChange: PropTypes.func.isRequired,
  onTrainingCalendarClose: PropTypes.func.isRequired,
  createTraining: PropTypes.func.isRequired,
  updateTraining: PropTypes.func.isRequired,
  onCancelTraining: PropTypes.func.isRequired,
}

TrainingSidePanel.defaultProps = {
  sidePanelContext: 'new'
}
