

import HandshqApp from './handshq-app';

HandshqApp.rails = {
  avatarPaths: {
    michelleKimbler: "//cdn.app.handshq.com/assets/avatars/michelle-kimbler-d9f90c50de1e56ca04c7f502c08fb5e81ce0ad282010a1393701d7502a6bb5ae.png"
  },
  accordionDownIconSvgPath: "//cdn.app.handshq.com/assets/icons/accordian_arrow_down-b7022ac833b2fd7652b16e44f861c766725ddb6dc069ef2ad846efc70071493d.svg",
  accordionRightIconSvgPath: "//cdn.app.handshq.com/assets/icons/accordian_arrow_right-f43f2caa2ef8dff6db5cc5e3594b0f4431b1d2d010e594dd3e12dd088ebdb9ec.svg",
  googleMapsApiKey: "AIzaSyDL1CUwi_5iSm-wdflj2pIcM11aVzt9j8o",
  googleMapsMapId: "765966def349b5c5",
  imageIconSvgPath: "//cdn.app.handshq.com/assets/icons/image-53a27234156717490a70a58aa5720e32196ab472bc0db68bc2fffdb73df1d732.svg",
  loadingIconSvgPath: "//cdn.app.handshq.com/assets/icons/loading-icon-709faf0fc374785f71c0253315ec93de26871c03c39c5d26c9b860320b1900db.svg",
  nhsImagePath: "//cdn.app.handshq.com/assets/nhs-de05d5cb814e44e48a0943b7a38102707e4115a29ad74a00b755a1c7a351a7c9.png",
  recurlyPublicKey: "ewr1-78LAsRt5QXIkgoxxE9dFpR",
  refreshIconSvgPath: "//cdn.app.handshq.com/assets/icons/refresh-2d0d94e5a80ae696cd4cff005c798a4581b12f1c65ea71a6fb3c36a3bc45e3c6.svg",
  testEnv: "false",
  trashIconSvgPath: "//cdn.app.handshq.com/assets/icons/trash-0055a6f1358673ed074819a5111c708c66c8514444bd7816abcfcc2ef09b1e5c.svg"
};
