import React from 'react';
import { resourceShape } from 'components/helpers/serialisableResources';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import { courseDuration, courseLearningMethod, courseRenewalFrequency, isCourseEligibleForAutoEnrolment, isELearningAvailable } from 'components/helpers/resources/courses';
import { displayCurrencyString, currencyNumberToCurrencyString } from 'components/helpers/currency';

import PersonalInfo from 'components/application/PersonalInfo';
import LogoIcon from '-!svg-react-loader?name=LogoIcon!icons/ihasco-logo-mark.svg';
import AutoEnrolIcon from '-!svg-react-loader?name=AutoEnrolIcon!icons/double-arrow-rotate.svg';

export default function CourseSidePanelDetailsTab({ course, eLearningCourse, originalCourse }) {
  const currentActor = useCurrentActor();
  const { isAutoEnrolmentIncludingMissingTraining } = useTrainingRegisterResources();

  const evidence = course.attributes.requiresEvidence ? 'Required' : 'Not Required';

  const eLearningCourseDuration = eLearningCourse && eLearningCourse.attributes.duration;
  const timeEstimate = eLearningCourse ? eLearningCourseDuration : courseDuration({ course });

  const isELearningCustom = eLearningCourse && eLearningCourse.attributes.custom;
  const currencyCode = currentActor.division.attributes.currencyCode;
  const customELearningCourseCost = isELearningCustom && currencyNumberToCurrencyString({ number: eLearningCourse.attributes.cost, currencyCode })
  const eLearningCourseCost = isELearningCustom ? displayCurrencyString({ string: customELearningCourseCost, currencyCode }) : '1 credit';
  const courseCostEstimate = currencyNumberToCurrencyString({ number: course.attributes.costEstimate, currencyCode });
  const costEstimate = eLearningCourse ? eLearningCourseCost : displayCurrencyString({ string: courseCostEstimate, currencyCode });

  return (
    <div className='popup__body-content popup__body-content--border-top tw-border-grey-100'>
      {isELearningAvailable({ currentActor, course, originalCourse }) && (
        <div className='flex content-box fh-64 p-12 m-b-24 tw-border-grey-100 tw-bg-white'>
          <div className='m-r-8'><LogoIcon width={20} height={20} /></div>
          <div>
            <div className='tw-font-medium'>eLearning available</div>
            <div>Edit the course to activate eLearning</div>
          </div>
        </div>
      )}
      <PersonalInfo label='Renewal frequency' text={courseRenewalFrequency({ course })} />
      <PersonalInfo label='Evidence' text={evidence} />
      <div className='personal-info'>
        <div className='personal-info__info-container'>
          <div className='personal-info__label tw-text-grey-900 tw-font-medium'>
            Method
          </div>
          <div className={'personal-info__text'}>
            {isCourseEligibleForAutoEnrolment({ currentActor, isAutoEnrolmentIncludingMissingTraining, course }) ? (
              <div className='tw-flex tw-items-center tw-justify-between'>
                <span>{'eLearning ('}</span>
                <AutoEnrolIcon className='[&_path]:tw-fill-grey-700'/>
                <span>{'Auto-enrols)'}</span>
              </div>
            ) : (
              <span>{courseLearningMethod({ course })}</span>
            )}
          </div>
        </div>
      </div>
      <PersonalInfo label='Provider' text={course.attributes.provider} />
      <PersonalInfo label='Cost estimate' text={costEstimate} />
      <PersonalInfo label='Time estimate' text={timeEstimate} />
      {!eLearningCourse && <PersonalInfo label='Default booking notes' text={course.attributes.defaultBookingNote} isTruncated={false} />}
    </div>
  )
}

CourseSidePanelDetailsTab.propTypes = {
  course: resourceShape('course').isRequired,
  eLearningCourse: resourceShape('eLearningCourse'),
  originalCourse: resourceShape('originalCourse')
}
