import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { z } from 'zod';
import _ from 'lodash';
import moment from 'moment';
import { useInView } from 'react-intersection-observer';

import { getNextPageParam } from "components/helpers/reactQuery";
import { TabContext } from 'components/contexts/TabContext';
import { useBreadBoard } from 'components/contexts/Toaster';
import { useCurrentActor } from 'components/contexts/CurrentActor';

import useForm from 'components/hooks/useForm';
import useValidatedStore from 'components/hooks/useValidatedStore';
import useWindowStorage from 'components/hooks/useWindowStorage';
import useDebounce from 'components/hooks/useDebounce';
import useRequestError from 'components/hooks/useRequestError';
import useSidePanel from 'components/hooks/useSidePanel';
import useModal from 'components/hooks/useModal';

import BookingsBar from 'components/bookings/BookingsBar';
import BookingsTable from 'components/bookings/BookingsTable';
import BlankBookingSearchResult from 'components/bookings/BlankBookingSearchResult';
import Paginator from 'components/application/Paginator';
import ResourceBlankNotice from 'components/application/ResourceBlankNotice';
import BookingSidePanel from 'components/training/BookingSidePanel';
import SendBookingConnectionUpdatedNotificationModal from 'components/bookings/SendBookingConnectionUpdatedNotificationModal';
import BookingSourceReminderModal from 'components/bookings/BookingSourceReminderModal';
import DestroyBookingSourceModal from 'components/bookings/DestroyBookingSourceModal';
import BookingConnectionTrainingSidePanel from 'components/bookings/BookingConnectionTrainingSidePanel';
import SuccessToast from 'components/application/SuccessToast';
import AddCalendarMonthIcon from '-!svg-react-loader?name=AddCalendarMonthIcon!icons/calendar-month.svg';
import AddImportantDevicesIcon from '-!svg-react-loader?name=AddImportantDevicesIcon!icons/important-devices.svg';
import { calculateTrainingExpiryDate, inferredTrainingExpiryDates, defaultTrainingStartDate } from 'components/helpers/resources/training';

const initialTabStore = {
  currentSearch: '',
  page: 1
};

const tabSchema = z.object({
  selectedTabName: z.string(),
  page: z.number().or(z.null()),
  currentSearch: z.string()
});

const defaultTabs = [
  {
    name: 'Upcoming bookings',
    param: 'upcoming_bookings',
    blankNotice: 'There are no upcoming bookings.',
    icon: <AddCalendarMonthIcon width={64} height={64} className='[&_path]:tw-fill-grey-300 tw-mb-2' />,
  },
  {
    name: 'Past bookings',
    param: 'past_bookings',
    blankNotice: 'There are no past bookings. Evidence can be uploaded from within individual personnel profiles.',
    icon: <AddCalendarMonthIcon width={64} height={64} className='[&_path]:tw-fill-grey-300 tw-mb-2' />,
  }
];

const eLearningTab = {
  name: 'eLearning',
  param: 'e_learning',
  blankNotice: 'There are no upcoming eLearning enrolments.',
  icon: <AddImportantDevicesIcon width={64} height={64} className='[&_path]:tw-fill-grey-300 tw-mb-2' />,
};

const defaultBookingConnection = {
  id: null,
  date: null,
  notes: '',
  personnelIds: []
};

const defaultBookingConnectionTraining = {
  expiryDate: null,
  notes: '',
  personnelIds: [],
  startDate: null
}

// TODO: probably remove these  -----

function findCourseFromCache(queryClient, courseID) {
  const queryData = queryClient.getQueriesData(['assignableCourses']);
  return queryData.flatMap(([_key, data]) => data?.data || []).flat().find(course => course.id === courseID.toString());
}

function findELearningCourseFromCache(queryClient, id) {
  const queryData = queryClient.getQueriesData(['assignableCourses']);
  const flattened = queryData.flatMap(([_key, data]) => data?.included || []).flat();
  return flattened.find((inclusion) => inclusion.type === 'eLearningCourse' && inclusion.id === id)
}
// ---------------------- c

export default function BookingsTab({ label }) {
  // utilities
  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();
  const queryClient = useQueryClient();
  const [endOfDomainPersonnelListRef, isEndOfDomainPersonnelListRefInView] = useInView();

  // local storage
  const [getStore, setStore] = useWindowStorage(`trainingRegister|${label}`, { store: window.sessionStorage });
  const tabStore = useValidatedStore({ getStore, initialStore: initialTabStore, schema: tabSchema });

  // searching
  const [currentSearch, setCurrentSearch, handleSearchInputChange] = useForm({ bookingSearch: tabStore.currentSearch });
  const [debouncedCurrentSearch, _resetDebouncedCurrentSearch] = useDebounce(currentSearch, 250);

  // request
  const [requestError, submitDisabled, removeErrorStyling, resetRequestError, handleRequestError] = useRequestError();

  // sidepanels
  const [bookingSidePanelIsOpen, _setBookingSidePanelIsOpen, openBookingSidePanel, closeBookingSidePanel, resetBookingSidePanelContext, bookingSidePanelContext, setBookingSidePanelContext] = useSidePanel(false, 'show');
  const [bookingConnectionTrainingSidePanelIsOpen, _setbookingConnectionTrainingSidePanelIsOpen, openBookingConnectionTrainingSidePanel, closeBookingConnectionTrainingSidePanel] = useSidePanel(false, 'show');

  // selections
  let tabs = defaultTabs
  if (currentActor.isAllowedFeature('e_learning')) tabs = tabs.concat([eLearningTab])

  const [selectedBookingConnectionID, setSelectedBookingConnectionID] = useState(null);
  const [selectedBookingConnectionsPage, setSelectedBookingConnectionsPage] = useState(tabStore.page);
  const [selectedCourseID, setSelectedCourseID] = useState(null);
  const [selectedTabName, setSelectedTabName] = useState(!!tabStore.selectedTabName && tabs.find(selectedTab => selectedTab.name === tabStore.selectedTabName) ? tabStore.selectedTabName : tabs[0].name);

  // modals
  const [bookingSourceReminderModalIsOpen, , openBookingSourceReminderModal, closeBookingSourceReminderModal] = useModal(false);
  const [removeBookingConnectionModalIsOpen, setRemoveBookingConnectionModal] = useState(false);
  const [sendBookingConnectionUpdatedNotificationModalIsOpen, setSendBookingConnectionUpdatedNotificationModalIsOpen] = useState(false);

  // form state
  const [currentBookingConnectionTraining, setCurrentBookingConnectionTraining, handleBookingConnectionTrainingInputChange, , , handleBookingConnectionTrainingDateChange] = useForm(defaultBookingConnectionTraining);
  const [currentBookingConnection, setCurrentBookingConnection, handleBookingConnectionInputChange, , , handleBookingConnectionDateChange] = useForm(defaultBookingConnection);

  // query computed values
  const selectedTab = tabs.find((tab) => tab.name === selectedTabName);
  const filters = { page: selectedBookingConnectionsPage, tabParam: selectedTab.param, search: debouncedCurrentSearch.bookingSearch };
  const tabNames = tabs.map((tab) => tab.name)

  const {
    data: bookingConnections,
    isSuccess: isBookingConnectionsFetchSuccess
  } = useQuery({
    queryKey: ['bookingConnections', filters],
    queryFn: async () => {
      const response = await axios.get('/dashboards/booking_connections', {
        params: {
          page: selectedBookingConnectionsPage,
          date_filter: selectedTab.param,
          search: currentSearch.bookingSearch,
          e_learning: selectedTab.param === 'e_learning',
        },
      });
      return response.data
    },
    onError: breadBoard.addInedibleToast
  });

  const selectedBookingConnection = selectedBookingConnectionID && bookingConnections?.data.find(bookingConnection => bookingConnection.id == selectedBookingConnectionID);

  const selectedBookingSource = (function getSelectedBookingSource() {
    if (!selectedBookingConnection) return null;
    const { id, type } = selectedBookingConnection.relationships.source.data;
    return bookingConnections.included.find((inclusion) => inclusion.type === type && inclusion.id === id);
  })();

  const selectedBookerId = selectedBookingSource?.relationships.booker.data?.id;

  const selectedDomainCourse = (() => {
    if (bookingSidePanelContext === 'new') return findCourseFromCache(queryClient, selectedCourseID);
    if (['show', 'edit'].includes(bookingSidePanelContext)) return selectedBookingSource && bookingConnections.included.find(inclusion => inclusion.id === selectedBookingSource.relationships.course.data.id && inclusion.type === 'course');
    return null;
  })();

  const selectedDomainELearningCourse = (() => {
    if (!selectedDomainCourse || !selectedDomainCourse.relationships.eLearningCourse.data) { return null }
    if (bookingSidePanelContext === 'new') return selectedDomainCourse.relationships.eLearningCourse.data ? findELearningCourseFromCache(queryClient, selectedDomainCourse.relationships.eLearningCourse.data.id) : null;
    if (['show', 'edit'].includes(bookingSidePanelContext)) return bookingConnections.included.find(inclusion => inclusion.id === selectedDomainCourse.relationships.eLearningCourse.data.id && inclusion.type === 'eLearningCourse')
    return null;
  })();

  const personnelListContext = (() => {
    if (bookingSidePanelIsOpen) {
      return `booking_${bookingSidePanelContext}`;
    } else if (bookingConnectionTrainingSidePanelIsOpen) {
      return 'training_new'
    }
  })()

  const isDomainBookingGroupPersonnelFetchEnabled = !!(selectedBookingSource) && selectedBookingSource.type === 'bookingGroup' && !!(personnelListContext);
  const domainPersonnelQueryKey = ['domainBookingGroupPersonnel', selectedBookingSource?.id, personnelListContext]

  const {
    data: domainBookingGroupPersonnel,
    fetchNextPage: handleFetchNextPageOfbookingGroupPersonnel,
    hasNextPage: hasNextPageOfbookingGroupPersonnel,
    isFetching: isFetchingBookingGroupPersonnel,
    isSuccess: isBookingGroupPersonnelFetchSuccess
  } = useInfiniteQuery({
    queryKey: domainPersonnelQueryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const areAllPersonnelNeeded = ['booking_edit', 'training_new'].includes(personnelListContext);
      const params = { page: areAllPersonnelNeeded ? 'all' : pageParam };
      if (personnelListContext === 'booking_show') {
        params['with_booking_write_eligibility'] = true;
        params['with_reminder_eligibility'] = true;
        params['with_training_write_eligibility'] = true;
      } else if (personnelListContext === 'training_new') {
        params['only_training_eligible'] = true;
      }
      const response = await axios.get(`/booking_groups/${selectedBookingSource?.id}/personnel`, { params });
      return response.data
    },
    getNextPageParam: getNextPageParam,
    select: (allPages) => {
      return {
        data: allPages.pages.map(item => item.data).flat(),
        meta: allPages.pages[allPages.pages.length - 1].meta
      }
    },
    onSuccess: (personnel) => {
      const personnelIds = personnel.data.map(person => person.id);
      setCurrentBookingConnection({ ...currentBookingConnection, personnelIds })
      setCurrentBookingConnectionTraining({ ...currentBookingConnectionTraining, personnelIds })
    },
    onError: breadBoard.addInedibleToast,
    enabled: isDomainBookingGroupPersonnelFetchEnabled
  })

  const loadMore = !isFetchingBookingGroupPersonnel && hasNextPageOfbookingGroupPersonnel && isEndOfDomainPersonnelListRefInView;
  if (loadMore) handleFetchNextPageOfbookingGroupPersonnel();


  const {
    data: eLearningAllowance,
    isError: isELearningAllowanceError
  } = useQuery({
    queryKey: ['eLearningAllowance', selectedDomainELearningCourse],
    queryFn: async () => {
      const response = await axios.get('/e_learning/allowance');
      return response.data
    },
    enabled: currentActor.isAllowedFeature('e_learning') && !!selectedDomainELearningCourse && !selectedDomainELearningCourse.attributes.custom,
    onError: () => {
      breadBoard.addInedibleToast({ fullMessage: 'There was a problem displaying your remaining credits' })
    },
    retry: false
  })

  const createBookingMutation = useMutation({
    mutationFn: async ({ personnelId, booking }) => {
      const response = await axios.post(`/personnel/${personnelId}/bookings`, { booking });
      return response.data
    },
    onSuccess: () => {
      invalidateQueries({ queryKeys: [['assignableBookees'], ['bookingConnections'], ['bookingGroupPersonnelPages'], ['domainBookingGroupPersonnel']]})
    },
    onSettled: () => invalidateQueries({ queryKeys: [['eLearningAllowance']] }),
    onError: handleRequestError
  });

  const createBookingGroupMutation = useMutation({
    mutationFn: async (newBookingGroup) => {
      const response = await axios.post(`/booking_groups`, newBookingGroup);
      return response.data
    },
    onSuccess: () => {
      invalidateQueries({ queryKeys: [['assignableBookees'], ['bookingConnections'], ['bookingGroupPersonnelPages'], ['domainBookingGroupPersonnel']]})
    },
    onSettled: () => invalidateQueries({ queryKeys: [['eLearningAllowance']] }),
    onError: handleRequestError
  });

  const updateBookingConnectionMutation = useMutation({
    mutationFn: async (bookingConnectionToBeUpdated) => {
      const response = await axios.patch(`/dashboards/booking_connections/${bookingConnectionToBeUpdated.id}`, bookingConnectionToBeUpdated);
      return response.data
    },
    onSuccess: () => {
      invalidateQueries({ queryKeys: [['assignableBookees'], ['bookingConnections'], ['bookingGroupPersonnelPages'], ['domainBookingGroupPersonnel']]})
    },
    onSettled: () => invalidateQueries({ queryKeys: [['eLearningAllowance']] }),
    onError: handleRequestError
  });

  const createBookingConnectionChangeNotificationMutation = useMutation({
    mutationFn: async ({ bookingConnectionId, personnelIds, sourceId, sourceType }) => {
      const response = await axios.post(`/booking_connections/${bookingConnectionId}/change_notifications`, {
        personnel_ids: personnelIds,
        source_id: sourceId,
        source_type: sourceType
      });

      return response
    },
    onError: breadBoard.addInedibleToast
  });

  const destroyBookingMutation = useMutation({
    mutationFn: async ({ personnelId, bookingId }) => {
      const response = await axios.delete(`/personnel/${personnelId}/bookings/${bookingId}`);
      return response.data
    },
    onSuccess: () => {
      invalidateQueries({ queryKeys: [['assignableBookees'], ['bookingConnections'], ['bookingGroupPersonnelPages'], ['domainBookingGroupPersonnel']]})
    },
    onError: breadBoard.addInedibleToast
  });

  const destroyBookingGroupMutation = useMutation({
    mutationFn: async ({ bookingGroup }) => {
      const response = await axios.delete(`/booking_groups/${bookingGroup.id}`);
      return response.data
    },
    onSuccess: () => {
      invalidateQueries({ queryKeys: [['assignableBookees'], ['bookingConnections'], ['bookingGroupPersonnelPages'], ['domainBookingGroupPersonnel']]})
    },
    onError: breadBoard.addInedibleToast
  });

  const createBulkTrainingMutation = useMutation({
    mutationFn: async ({ courseID, trainingParams }) => {
      const response = await axios.post(`/courses/${courseID}/trainings_bulk_creations`, trainingParams);
      return response.data
    },
    onSuccess: (bookingConnectionTraining) => {
      invalidateQueries({ queryKeys: [['assignableBookees'], ['bookingConnections'], ['bookingGroupPersonnelPages'], ['domainBookingGroupPersonnel']]});
      return bookingConnectionTraining
    },
    onError: handleRequestError
  });

  const createBulkEnrolmentMutation = useMutation({
    mutationFn: async ({ courseID, enrolmentParams }) => {
      const response = await axios.post(`/courses/${courseID}/enrolments_bulk_creations`, enrolmentParams);
      return response.data
    },
    onSuccess: () => {
      invalidateQueries({ queryKeys: [['assignableBookees'], ['bookingConnections'], ['bookingGroupPersonnelPages'], ['domainBookingGroupPersonnel']]})
    },
    onSettled: () => invalidateQueries({ queryKeys: [['eLearningAllowance']] }),
    onError: handleRequestError
  })

  function invalidateQueries({ queryKeys }) {
    queryKeys.forEach((queryKey) => queryClient.invalidateQueries(queryKey))
  }

  function handleBulkEnrolmentSubmit() {
    const createParams = {
      courseID: selectedDomainCourse.id,
      enrolmentParams: {
        enrolment_bulk_creation: {
          personnel_ids: currentBookingConnection.personnelIds
        }
      }
    }

    createBulkEnrolmentMutation.mutate(createParams, {
      onSuccess: (data, variables) => {
        const course = data.included.find((inclusion) => inclusion.type == 'course');

        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                Enrolment added for <span className="tw-font-medium">{course.attributes.name}</span> on{" "}
                <span className="tw-font-medium">{moment().format("D MMM, YYYY")}</span>{" "}
                for <span className="tw-font-medium">{variables.enrolmentParams.enrolment_bulk_creation.personnel_ids.length}</span> personnel.
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />
        );

        handleCloseBookingSidePanel();
        resetBookingConnections();
      }
    })
  }

  function handleBookingCreateSubmit() {
    const isCreatingSingularBooking = currentBookingConnection.personnelIds.length === 1;

    if (!isCreatingSingularBooking && selectedDomainELearningCourse) {
      handleBulkEnrolmentSubmit()
      return
    }

    const bookingSourceCreationMutation = isCreatingSingularBooking ? createBookingMutation : createBookingGroupMutation;

    const bookingDetails = {
      course_id: selectedDomainCourse.id,
      date: moment.parseZone(currentBookingConnection.date).format('DD/MM/YYYY'),
      notes: currentBookingConnection.notes,
    };

    const creationParams = isCreatingSingularBooking
      ? { personnelId: currentBookingConnection.personnelIds[0], booking: { ...bookingDetails } }
      : { booking_group: { ...bookingDetails, personnel_ids: currentBookingConnection.personnelIds } };

    bookingSourceCreationMutation.mutate(creationParams, {
      onSuccess: (data, variables) => {
        const courseName = selectedDomainCourse.attributes.name;
        const { date } = data.data.attributes;

        breadBoard.addToast(
          <SuccessToast
            message={
              data.data.type === 'booking' ?
                <>
                  <span className="tw-font-medium">{data.included.find(inclusion => inclusion.type === 'personnel').attributes.firstName}</span>
                  {" "}has been {!!selectedDomainELearningCourse ? 'enrolled' : 'booked'} on the course{" "}
                  <span className="tw-font-medium">{courseName}</span>
                </>
                :
                <>
                  Booking added for <span className="tw-font-medium">{courseName}</span> on{" "}
                  <span className="tw-font-medium">{moment(date).format("D MMM, YYYY")}</span>{" "}
                  for <span className="tw-font-medium">{variables.booking_group.personnel_ids.length}</span> personnel.
                </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />
        );

        handleCloseBookingSidePanel();
        resetBookingConnections();
      }
    });
  }

  function handleUpdateBookingConnectionSubmit() {
    if (currentBookingConnection.personnelIds.length === 0) {
      setRemoveBookingConnectionModal(true)
      return
    }

    updateBookingConnectionMutation.mutate(
      {
        id: currentBookingConnection.id,
        booking_connection: {
          source_attributes: {
            date: (moment.parseZone(currentBookingConnection.date).isValid() ? moment.parseZone(currentBookingConnection.date).format('DD/MM/YYYY') : null), // Moment returns 'Invalid Date' if currentBookingConnection.date is not set.
            notes: currentBookingConnection.notes
          },
          personnel_ids: currentBookingConnection.personnelIds,
          source_id: selectedBookingSource.id,
          source_type: selectedBookingSource.type
        }
      },
      {
        onSuccess: (data) => {
          handleCloseBookingSidePanel()

          if (data.meta.hasBookingDetailsChanged) {
            new Promise((resolve) => { setTimeout(() => { resolve() }, 500) })
              .then(() => setSendBookingConnectionUpdatedNotificationModalIsOpen(true)) // might be able to use advanced timers
          } else {
            addUpdateToast({ onlyChangedNotified: true, updatedBookingConnection: data })
            resetBookingConnections()
          }
        },
      }
    );
  }

  function handleUpdateBookingNotification() {
    const updatedBookingConnection = updateBookingConnectionMutation.data;

    createBookingConnectionChangeNotificationMutation.mutate(
      {
        bookingConnectionId: updatedBookingConnection.data.id,
        personnelIds: updatedBookingConnection.meta.unchangedPersonnelIds,
        sourceId: updatedBookingConnection.data.relationships.source.data.id,
        sourceType: updatedBookingConnection.data.relationships.source.data.type
      },
      {
        onSuccess: () => {
          addUpdateToast({ onlyChangedNotified: false, updatedBookingConnection })
          resetBookingConnections()
          setSendBookingConnectionUpdatedNotificationModalIsOpen(false)
        },
      }
    );
  }

  function handleDestroyBookingSourceSubmit({ bookingSource, course }) {
    const isBookingGroup = bookingSource.type === 'bookingGroup';
    const destroyParams = isBookingGroup ? { bookingGroup: bookingSource } : { personnelId: bookingSource.relationships.personnel.data.id, bookingId: bookingSource.id };

    (isBookingGroup ? destroyBookingGroupMutation : destroyBookingMutation).mutate(destroyParams, {
      onSuccess: (data) => {
        setRemoveBookingConnectionModal(false)
        handleCloseBookingSidePanel()
        breadBoard.addToast(
          <SuccessToast
            message={
              <>
                {isBookingGroup ? (
                  <>
                    <span className='tw-font-normal'>Booking for <span className='tw-font-medium'>{course.attributes.name}</span> on </span>
                    <span className='tw-font-medium'>{moment().format('D MMM, YYYY')}</span><span> has been removed for </span><span className='tw-font-medium'>{bookingSource.meta.personnelCount} personnel</span>
                  </>
                ) : (
                  <>
                    <span className='tw-font-normal'>{!!course?.relationships?.eLearningCourse?.data?.id ? 'Enrolment' : 'Booking'} has been removed for <span className='tw-font-medium'>{data.included.find(inclusion => inclusion.type === 'personnel').attributes.firstName}</span> for <span className='tw-font-medium'>{course.attributes.name}</span></span>
                  </>
                )}
              </>
            }
            onBurnToast={breadBoard.handleBurnToast}
          />
        );
      }
    });
  }

  const handleUpdateNotificationModalCancel = () => {
    addUpdateToast({ onlyChangedNotified: true, updatedBookingConnection: updateBookingConnectionMutation.data })
    resetBookingConnections()
    setSendBookingConnectionUpdatedNotificationModalIsOpen(false)
  }

  function handleEditBookingConnectionClick() {
    const personnelIds = [];
    if (selectedBookingSource.type === 'booking') {
      personnelIds.push(selectedBookingSource.relationships.personnel.data.id)
    } else {
      invalidateQueries({ queryKeys: [['domainBookingGroupPersonnel', selectedBookingSource.id, 'booking_edit']] })
    }

    setCurrentBookingConnection({
      id: selectedBookingConnection.id,
      date: moment(selectedBookingSource.attributes.date).toDate(),
      notes: selectedBookingSource.attributes.notes,
      personnelIds
    })

    setBookingSidePanelContext('edit')
  }

  function handleCourseOptionChange({ courseOptionId }) {
    const bookingCourse = findCourseFromCache(queryClient, courseOptionId);
    setSelectedCourseID(courseOptionId)
    setCurrentBookingConnection({ ...currentBookingConnection, notes: bookingCourse?.attributes?.defaultBookingConnectionNote })
    setBookingSidePanelContext('new')
    openBookingSidePanel()
  }

  function handleBookingGroupMemberSelect(personnelId) {
    if (requestError.validationErrors.personnel?.error.detail === 'Personnel must exist') removeErrorStyling({ target: { name: 'personnel' } })
    const newPersonnelIds = [personnelId, ...currentBookingConnection.personnelIds]
    setCurrentBookingConnection({ ...currentBookingConnection, personnelIds: newPersonnelIds })
  }

  function handleBookingGroupMemberDelete(personnelId) {
    if (requestError.validationErrors.personnel?.error.detail === 'Personnel with recorded or booked training limit reached for account') {
      removeErrorStyling({ target: { name: 'personnel' } })
    }

    const newPersonnelIds = currentBookingConnection.personnelIds.filter(id => id !== personnelId);
    setCurrentBookingConnection({ ...currentBookingConnection, personnelIds: newPersonnelIds })
  }

  function handleCloseBookingSidePanel() {
    resetRequestError()
    resetBookingSidePanelContext()
    closeBookingSidePanel()
  }

  function handleBookingSidePanelCancel() {
    resetBookingConnections()
    handleCloseBookingSidePanel()
  }

  function resetBookingConnections() {
    setCurrentBookingConnection(defaultBookingConnection)
    setCurrentBookingConnectionTraining(defaultBookingConnectionTraining)
    setSelectedBookingConnectionID(null)
  }

  function handleViewBookingClick(bookingConnectionId) {
    setSelectedBookingConnectionID(bookingConnectionId)
    setBookingSidePanelContext('show')
    resetRequestError()
    openBookingSidePanel()
  }

  function handleRecordTrainingClick() {
    const personnelIds = [];
    if (selectedBookingSource.type === 'booking') {
      personnelIds.push(selectedBookingSource.relationships.personnel.data.id)
    } else {
      invalidateQueries({ queryKeys: [['domainBookingGroupPersonnel', selectedBookingSource.id, 'training_new']] })
    }

    const startDate = defaultTrainingStartDate({ booking: selectedBookingSource })
    const expiryDate = startDate ? calculateTrainingExpiryDate({ course: selectedDomainCourse, startDate }) : null
    setCurrentBookingConnectionTraining({ ...currentBookingConnectionTraining, expiryDate, startDate, personnelIds })
    handleCloseBookingSidePanel()
    openBookingConnectionTrainingSidePanel()
  }

  function handleBookingConnectionTrainingMemberDelete(personnelId) {
    const newPersonnelIds = currentBookingConnectionTraining.personnelIds.filter(id => id !== personnelId);
    setCurrentBookingConnectionTraining({ ...currentBookingConnectionTraining, personnelIds: newPersonnelIds })
  }

  function resetBookingConnectionsTrainingSidePanel() {
    closeBookingConnectionTrainingSidePanel()
    resetBookingConnections()
    resetRequestError()
  }

  function handleBookingConnectionTrainingCalendarClose(calendarName) {
    setCurrentBookingConnectionTraining({
      ...currentBookingConnectionTraining,
      ...inferredTrainingExpiryDates({
        domainCourse: selectedDomainCourse,
        expiryDates: { startDate: currentBookingConnectionTraining.startDate, expiryDate: currentBookingConnectionTraining.expiryDate },
        dateToInferFrom: calendarName
      })
    })
  }

  function handleCreateBookingConnectionTrainingSubmit() {
    createBulkTrainingMutation.mutate(
      {
        courseID: selectedDomainCourse.id,
        trainingParams: {
          bulk_training_creation: {
            expiry_date: moment.parseZone(currentBookingConnectionTraining.expiryDate).format('DD/MM/YYYY'),
            notes: currentBookingConnectionTraining.notes,
            start_date: moment.parseZone(currentBookingConnectionTraining.startDate).format('DD/MM/YYYY'),
            personnel_ids: currentBookingConnectionTraining.personnelIds
          }
        }
      },
      {
        onSuccess: (data) => {
          breadBoard.addToast(
            <SuccessToast
              message={
                <>
                  <span>Training recorded for <strong>{selectedDomainCourse.attributes.name}</strong> for <strong>{data.meta.trainingCreationCount} personnel</strong>.</span>
                  <br />
                  <br />
                  <span>Evidence can be uploaded through personnel profiles.</span>
                </>
              }
              onBurnToast={breadBoard.handleBurnToast}
            />
          );

          resetBookingConnectionsTrainingSidePanel();
        }
      }
    )
  }

  function addUpdateToast({ onlyChangedNotified, updatedBookingConnection }) {
    const { changedPersonnelCount, hasBookingDetailsChanged, unchangedPersonnelIds } = updatedBookingConnection.meta;
    const bookingSourceRelation = updatedBookingConnection.data.relationships.source.data
    const bookingSource = updatedBookingConnection.included.find((inclusion) => inclusion.type === bookingSourceRelation.type && inclusion.id === bookingSourceRelation.id);
    const course = updatedBookingConnection.included.find((inclusion) => inclusion.type === 'course' && inclusion.id === bookingSource.relationships.course.data.id);
    const notifiedPersonnelCount = onlyChangedNotified ? changedPersonnelCount : unchangedPersonnelIds.length + changedPersonnelCount;

    breadBoard.addToast(
      <SuccessToast
        message={
          <>
            {hasBookingDetailsChanged ? `${course.relationships.eLearningCourse.data ? 'Enrolment' : 'Booking'} edited` : 'Personnel edited'} for <span className="tw-font-medium">{course.attributes.name}</span> on{' '}
            <span className="tw-font-medium">{moment().format("D MMM, YYYY")}.</span>
            {notifiedPersonnelCount > 0 && (
              <>
                <br />
                <br />
                {notifiedPersonnelCount} personnel and their line managers will be notified.
              </>
            )}
          </>
        }
        onBurnToast={breadBoard.handleBurnToast}
      />
    );
  }

  function handleSegmentItemClick(tabName) { setSelectedTabName(tabName) }
  function handlePageChange(event) { setSelectedBookingConnectionsPage(event.target.getAttribute('data-page')) }
  function handleSearchReset() { setCurrentSearch({ ...currentSearch, bookingSearch: '' }) }
  function handleBookingTabSelection(tabName) { setSelectedTabName(tabName) }

  const handleBookingReminderClick = () => {
    openBookingSourceReminderModal()
  };

  function handleReminderModalClose() {
    setCurrentBookingConnection(defaultBookingConnection);
    closeBookingSourceReminderModal()
  }

  const sendBookingGroupReminderMutation = useMutation({
    mutationFn: async ({ bookingSource }) => {
      const response = await axios.post(`/booking_groups/${bookingSource.id}/reminders`);
      return response.data
    },
    onSuccess: () => invalidateQueries({ queryKeys: ['bookingConnections'] }),
    onError: breadBoard.addInedibleToast
  });

  const sendBookingReminderMutation = useMutation({
    mutationFn: async ({ bookingSource }) => {
      const response = await axios.post(`/bookings/${bookingSource.id}/reminders`);
      return response.data
    },
    onSuccess: () => invalidateQueries({ queryKeys: ['bookingConnections'] }),
    onError: breadBoard.addInedibleToast
  });

  function handleSendReminderSubmit({ bookingSource }) {
    handleReminderModalClose();
    (bookingSource.type === 'bookingGroup' ? sendBookingGroupReminderMutation : sendBookingReminderMutation).mutate({ bookingSource }, {
      onSuccess: (data) => {
        breadBoard.addToast(
          <SuccessToast
            message={
              (() => {
                if (bookingSource.type === 'bookingGroup') {
                  const bookingGroup = data.included.find((inclusion) => inclusion.type === 'bookingGroup' && inclusion.id === data.data.relationships.bookingGroup.data.id);
                  const course = data.included.find((inclusion) => inclusion.type === 'course' && inclusion.id === bookingGroup.relationships.course.data.id);
                  return (
                    <>
                      <span className='tw-font-normal'>Reminder sent for <span className='tw-font-medium'>{course.attributes.name}</span> on </span>
                      <span className='tw-font-medium'>{(moment.parseZone(bookingGroup.attributes.notifiedAt)).format('D MMM, YYYY')}</span><span> to </span><span className='tw-font-medium'>{domainBookingGroupPersonnel?.meta.notifiableSetCount} personnel</span>
                    </>
                  )
                } else {
                  const booking = data.included.find((inclusion) => inclusion.type === 'booking' && inclusion.id === data.data.relationships.booking.data.id);
                  const course = data.included.find((inclusion) => inclusion.type === 'course' && inclusion.id === booking.relationships.course.data.id);
                  const personnel = data.included.find((inclusion) => inclusion.type === 'personnel' && inclusion.id === booking.relationships.personnel.data.id);
                  return (
                    <span className='tw-font-normal'>
                      <span className='tw-font-medium'>{personnel.attributes.firstName}</span> has been sent a reminder for the course <span className='tw-font-medium'>{course.attributes.name}</span>
                    </span>
                  )
                }
              })()
            }
            onBurnToast={breadBoard.handleBurnToast}
          />
        );
        handleCloseBookingSidePanel()
      },
    })
  }

  const {
    data: individualBookingPersonnel,
    isSuccess: isIndividualBookingPersonnelSuccess,
    isFetching: isFetchingIndividualBookingPersonnel
  } = useQuery({
    queryKey: ['booking', selectedBookingSource?.id, 'personnel'],
    queryFn: async () => {
      const response = await axios.get(`/bookings/${selectedBookingSource?.id}/personnel`);
      return response.data
    },
    enabled: selectedBookingSource?.type === 'booking'
  });

  useEffect(() => {
    if (isBookingConnectionsFetchSuccess) setStore({ page: bookingConnections.meta.currentPage, currentSearch: currentSearch.bookingSearch, selectedTabName: selectedTabName });
  }, [isBookingConnectionsFetchSuccess, bookingConnections?.meta?.currentPage, currentSearch.bookingSearch, selectedTabName])

  return (
    <TabContext.Provider value={{ selectedTabName, tabNames, tabs }}>
      <BookingsBar
        bookingSearch={currentSearch.bookingSearch}
        onCourseOptionChange={handleCourseOptionChange}
        onSearchInputChange={handleSearchInputChange}
        onSearchReset={handleSearchReset}
        onSegmentItemClick={handleSegmentItemClick}
      />
      {isBookingConnectionsFetchSuccess &&
        (bookingConnections.data.length > 0 ? (
          <>
            <BookingsTable bookingConnections={bookingConnections} onRowClick={handleViewBookingClick} />
            {bookingConnections.meta.totalPages > 1 && (
              <div className='tw-mt-20 tw-text-center'>
                <Paginator
                  currentPage={bookingConnections.meta.currentPage}
                  totalPages={bookingConnections.meta.totalPages}
                  onClick={handlePageChange}
                />
              </div>
            )}
          </>
        ) : (
          debouncedCurrentSearch.bookingSearch ? (
            <BlankBookingSearchResult onBookingTabSelection={handleBookingTabSelection} />
          ) : (
            <ResourceBlankNotice
              addButton={false}
              addMessage={selectedTab.blankNotice}
              addMessageClass='tw-w-[700px] tw-mt-4 tw-mx-auto'
              displayReadOnlyContents={false}
              icon={selectedTab.icon}
              isSingularResource
              resource={selectedTab.name}
              totalCount={0}
            />
          )
        ))
      }
      <BookingSidePanel
        currentBooking={currentBookingConnection}
        domainBookingSource={selectedBookingSource}
        domainPersonnel={selectedBookingSource?.type === 'booking' ? individualBookingPersonnel?.data : domainBookingGroupPersonnel?.data}
        domainPersonnelMeta={selectedBookingSource?.type === 'booking' ? individualBookingPersonnel?.data.meta : domainBookingGroupPersonnel?.meta}
        domainPersonnelFetchMeta={{
          isSuccess: selectedBookingSource?.type === 'booking' ? isIndividualBookingPersonnelSuccess : isBookingGroupPersonnelFetchSuccess,
          isFetching: selectedBookingSource?.type === 'booking' ? isFetchingIndividualBookingPersonnel : isFetchingBookingGroupPersonnel
        }}
        endOfDomainPersonnelListRef={endOfDomainPersonnelListRef}
        domainBooker={!!selectedBookerId ? bookingConnections.included.find(inclusion => inclusion.id === selectedBookerId && inclusion.type === 'simpleUser') : null}
        domainCourse={selectedDomainCourse}
        domainELearningCourse={selectedDomainELearningCourse}
        onEditBookingClick={handleEditBookingConnectionClick}
        onInputChange={handleBookingConnectionInputChange}
        onDateChange={handleBookingConnectionDateChange}
        onBookingGroupMemberSelect={handleBookingGroupMemberSelect}
        onBookingGroupMemberDelete={handleBookingGroupMemberDelete}
        onCancel={handleBookingSidePanelCancel}
        onBookingCreateSubmit={handleBookingCreateSubmit}
        onBookingUpdateSubmit={handleUpdateBookingConnectionSubmit}
        requestError={requestError}
        removeErrorStyling={removeErrorStyling}
        submitDisabled={submitDisabled || createBookingGroupMutation.isLoading || createBookingMutation.isLoading || updateBookingConnectionMutation.isLoading}
        sidePanelIsOpen={bookingSidePanelIsOpen}
        sidePanelContext={bookingSidePanelContext}
        onBookingReminderClick={handleBookingReminderClick}
        onRemoveBooking={() => setRemoveBookingConnectionModal(true)}
        showBookingGroupView={true}
        eLearningAllowance={eLearningAllowance}
        onRecordTraining={handleRecordTrainingClick}
        isELearningAllowanceError={isELearningAllowanceError}
      />
      {
        selectedDomainCourse && bookingConnectionTrainingSidePanelIsOpen && (
          <BookingConnectionTrainingSidePanel
            domainPersonnelCollection={selectedBookingSource?.type === 'booking' ? (individualBookingPersonnel?.data && [{ ...individualBookingPersonnel.data }]) : domainBookingGroupPersonnel?.data}
            currentBookingConnectionTraining={currentBookingConnectionTraining}
            domainCourse={selectedDomainCourse}
            onBookingGroupMemberDelete={handleBookingConnectionTrainingMemberDelete}
            onCalendarClose={handleBookingConnectionTrainingCalendarClose}
            onCancel={resetBookingConnectionsTrainingSidePanel}
            onDateChange={handleBookingConnectionTrainingDateChange}
            onInputChange={handleBookingConnectionTrainingInputChange}
            onSubmit={handleCreateBookingConnectionTrainingSubmit}
            removeErrorStyling={removeErrorStyling}
            requestError={requestError}
            sidePanelContext='new'
            sidePanelIsOpen={bookingConnectionTrainingSidePanelIsOpen}
            submitDisabled={submitDisabled || createBulkTrainingMutation.isLoading}
          />
        )}
      {
        sendBookingConnectionUpdatedNotificationModalIsOpen && (
          <SendBookingConnectionUpdatedNotificationModal
            bookingConnectionUpdatedMutationData={updateBookingConnectionMutation.data}
            onClose={handleUpdateNotificationModalCancel}
            onSubmit={handleUpdateBookingNotification}
          />
        )
      }
      {
        bookingSourceReminderModalIsOpen && selectedDomainCourse && selectedBookingSource && (
          <BookingSourceReminderModal
            bookingSource={selectedBookingSource}
            course={selectedDomainCourse}
            isOpen={bookingSourceReminderModalIsOpen}
            onClose={handleReminderModalClose}
            onSubmit={handleSendReminderSubmit}
            personnel={
              selectedBookingSource.type === 'booking'
                ? bookingConnections.included.find(inclusion => inclusion.id === selectedBookingSource.relationships.personnel.data.id && inclusion.type === 'personnel')
                : null
            }
            domainPersonnelMeta={selectedBookingSource.type === 'bookingGroup' ? domainBookingGroupPersonnel?.meta : null}
          />
        )
      }
      {
        (removeBookingConnectionModalIsOpen && selectedBookingSource && selectedDomainCourse && ['show', 'edit'].includes(bookingSidePanelContext)) && (
          <DestroyBookingSourceModal
            isOpen={removeBookingConnectionModalIsOpen}
            bookingSource={selectedBookingSource}
            course={selectedDomainCourse}
            onClose={() => setRemoveBookingConnectionModal(false)}
            onDestroySubmit={handleDestroyBookingSourceSubmit}
          />
        )
      }
    </TabContext.Provider>
  )
}
