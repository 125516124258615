import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { resourceShape } from 'components/helpers/serialisableResources';
import { groupAttachmentsByAttachableId } from 'components/helpers/resources/attachments';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrackedPersonnelContext } from 'components/contexts/TrackedPersonnelContext';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';

import useToggle from 'components/hooks/useToggle';
import { prioritisedAutoEnrolmentBlockers as prioritisedAutoEnrolmentBlockersList } from 'components/helpers/resources/autoEnrolments';

import TrainingRow from 'components/training/TrainingRow';
import TrainingStatus from 'components/training/TrainingStatus';
import ELearningBookings from 'components/training/ELearningBookings';
import TrainingBookings from 'components/training/TrainingBookings';
import TrainingOptions from 'components/training/TrainingOptions';
import MinimalTrainingOptions from 'components/training/MinimalTrainingOptions';

export default function CourseTraining({
  currentPersonnel,
  personnelId,
  course,
  userCourse,
  eLearningCourse,
  eLearningAllowance,
  autoEnrolmentConfiguration,
  hasTrainingEditableAccess,
  attachments,
  booking,
  booker,
  training,
  isRequired,
  isRequiredByAnything,
  isRequiredByAnotherRole,
  isWithinRoleTable,
  requiredSource,
  registration,
  creditInfoUnavailable,
  autoEnrolmentExclusion,
  isAdditional,
  onAddBooking,
  onAddELearningBooking,
  onShowBooking,
  onNewTrainingClick,
  onEditTrainingClick,
  onDeleteTrainingClick,
  onDeleteCourseClick,
  onUpdateUserCourse,
  onCreateUserCourse,
  onRemoveBooking,
  onBookingReminderClick,
  setModalBoxData,
  onCreateAutoEnrolmentExclusion,
  onDeleteAutoEnrolmentExclusion
}) {
  const currentActor = useCurrentActor();
  const trackedPersonnelContext = useTrackedPersonnelContext();
  const { isViewingOwnProfile, hasBookingEditableAccess, isAutoEnrolmentIncludingMissingTraining } = useTrainingRegisterResources();

  const attachmentsByAttachableId = groupAttachmentsByAttachableId({ attachments: attachments });
  const isArchived = !!currentPersonnel.archivedAt;
  const isReadOnlyOrArchived = !hasTrainingEditableAccess || isArchived;
  const courseHasELearningCourse = currentActor.isAllowedFeature('e_learning') && !!eLearningCourse;
  const isELearningNonCustom = courseHasELearningCourse && !eLearningCourse.attributes.custom;
  const latestTraining = training[0];
  const isBookingAllowedWithinTrackedPersonnelLimits = !trackedPersonnelContext.hasReachedLimit || trackedPersonnelContext.isCurrentPersonnelTracked;

  const [isRequiredUserSelection, setIsRequiredUserSelection] = useState(!!(isWithinRoleTable ? isRequired : (!!userCourse && userCourse.attributes.required)));
  const [currentAutoEnrolmentExclusionUserSelection, setCurrentAutoEnrolmentExclusionUserSelection] = useState(!!autoEnrolmentExclusion);
  const [isHistoricalTrainingVisible, toggleHistoricalTraining] = useToggle(false);

  const handleIsRequiredUserSelectionChange = isRequiredUserSelection => setIsRequiredUserSelection(isRequiredUserSelection);
  const handleCurrentAutoEnrolmentExclusionUserSelectionChange = currentAutoEnrolmentExclusionUserSelection => setCurrentAutoEnrolmentExclusionUserSelection(currentAutoEnrolmentExclusionUserSelection);
  const isEligibleForAutoEnrolment = eLearningCourse && currentActor.isAllowedFeature(['e_learning_auto_enrol', 'e_learning', 'training_register']);

  const prioritisedAutoEnrolmentBlockers = isEligibleForAutoEnrolment ? prioritisedAutoEnrolmentBlockersList({
    autoEnrolmentConfiguration: autoEnrolmentConfiguration,
    isExpiringCourse: course.attributes.expires,
    training: latestTraining,
    isSubcontractor: !!currentPersonnel.company.subcontractorId,
    isRequired: isRequiredUserSelection || isRequiredByAnotherRole,
    isAutoEnrolmentIncludingMissingTraining,
    isBookingAllowedWithinTrackedPersonnelLimits,
    isELearningNonCustom,
    isCreditInfoUnavailable: creditInfoUnavailable,
    isPersonnelEmailUnavailable: !currentPersonnel.email,
    currentELearningCredits: eLearningAllowance?.currentCredits,
    isFromAutoEnrollableDivision: currentPersonnel.isFromAutoEnrollableDivision
  }) : null;

  const handleTrainingOptionsClose = () => {
    manageUserCourses()
    if (isEligibleForAutoEnrolment) { manageAutoEnrolmentExclusions() }
  };

  const manageUserCourses = () => {
    if (isWithinRoleTable) return

    if (!userCourse && isRequiredUserSelection) {
      onCreateUserCourse({ course, params: { required: true } })
    } else if (userCourse && userCourse.attributes.required !== isRequiredUserSelection) {
      onUpdateUserCourse({ userCourse, params: { required: isRequiredUserSelection } })
    }
  };

  const manageAutoEnrolmentExclusions = () => {
    if (prioritisedAutoEnrolmentBlockers.length === 0) {
      if (!currentAutoEnrolmentExclusionUserSelection && autoEnrolmentExclusion) {
        onDeleteAutoEnrolmentExclusion({ autoEnrolmentExclusion })
      } else if (currentAutoEnrolmentExclusionUserSelection && !autoEnrolmentExclusion) {
        onCreateAutoEnrolmentExclusion({ autoEnrolmentConfigurationId: autoEnrolmentConfiguration.id, personnelId: currentPersonnel.id })
      }
    }
  };

  const renderStatus = ({ currentTrainingStatus }) => {
    return (
      <TrainingStatus
      currentTrainingStatus={isArchived ? 'archived' : currentTrainingStatus}
        isRequired={isRequired}
        requiredSource={requiredSource}
      />
    )
  };

  const renderBookings = ({ currentTrainingStatus }) => {
    if (isArchived) return null

    const isEligibleForManualBooking = (
      (isRequired || isRequiredByAnything) && isBookingAllowedWithinTrackedPersonnelLimits && hasTrainingEditableAccess &&
      !currentPersonnel.company.subcontractorId &&
      !['valid', 'expiryDateMissing'].includes(currentTrainingStatus)
    );

    return courseHasELearningCourse ? (
      <ELearningBookings
        booking={booking}
        booker={booker}
        course={course}
        eLearningCourse={eLearningCourse}
        registration={registration}
        latestTraining={latestTraining}
        currentTrainingStatus={currentTrainingStatus}
        currentPersonnel={currentPersonnel}
        isCreditInfoUnavailable={isELearningNonCustom && creditInfoUnavailable}
        isEligibleForManualBooking={isEligibleForManualBooking && hasBookingEditableAccess}
        prioritisedAutoEnrolmentBlockers={prioritisedAutoEnrolmentBlockers}
        isELearningLaunchable={isViewingOwnProfile}
        autoEnrolmentConfiguration={autoEnrolmentConfiguration}
        currentAutoEnrolmentExclusionUserSelection={currentAutoEnrolmentExclusionUserSelection}
        onAddELearningBooking={onAddELearningBooking}
      />
    ) : (
      <TrainingBookings
        booking={booking}
        course={course}
        currentTrainingStatus={currentTrainingStatus}
        isBookingTooltipDisplayable={hasBookingEditableAccess || hasTrainingEditableAccess}
        isEligibleForManualBooking={isEligibleForManualBooking && hasBookingEditableAccess}
        onShowBooking={onShowBooking}
        onAddBooking={onAddBooking}
      />
    )
  };

  const renderRegularTrainingActions = ({ training: optionsTraining }) => {
    if (isReadOnlyOrArchived && training.length <= 1) return null

    return (
      <TrainingOptions
        showHistoricalTrainingOnly={isReadOnlyOrArchived}
        isWithinRoleTable={isWithinRoleTable}
        isRequiredByAnotherRole={isRequiredByAnotherRole}
        isRequired={isRequired}
        isAdditional={isAdditional}
        isMultipleTraining={training.length > 1}
        isHistoricalTrainingVisible={isHistoricalTrainingVisible}
        courseHasELearningCourse={courseHasELearningCourse}
        personnelId={personnelId}
        currentPersonnel={currentPersonnel}
        training={optionsTraining}
        course={course}
        eLearningCourse={eLearningCourse}
        booking={booking}
        registration={registration}
        userCourse={userCourse}
        creditInfoUnavailable={isELearningNonCustom && creditInfoUnavailable}
        toggleHistoricalTraining={toggleHistoricalTraining}
        onNewTrainingClick={onNewTrainingClick}
        onEditTrainingClick={onEditTrainingClick}
        onDeleteTrainingClick={onDeleteTrainingClick}
        onDeleteCourseClick={onDeleteCourseClick}
        onAddBooking={onAddBooking}
        onAddELearningBooking={onAddELearningBooking}
        onRemoveBooking={onRemoveBooking}
        onBookingReminderClick={onBookingReminderClick}
        prioritisedAutoEnrolmentBlockers={prioritisedAutoEnrolmentBlockers}
        isRequiredUserSelection={isRequiredUserSelection}
        currentAutoEnrolmentExclusionUserSelection={currentAutoEnrolmentExclusionUserSelection}
        onIsRequiredUserSelectionChange={handleIsRequiredUserSelectionChange}
        onCurrentAutoEnrolmentExclusionUserSelectionChange={handleCurrentAutoEnrolmentExclusionUserSelectionChange}
        onTrainingOptionsClose={handleTrainingOptionsClose}
      />
    )
  };

  const renderHistoricalTrainingActions = ({ training }) => {
    if (isReadOnlyOrArchived) return null
    return (
      <MinimalTrainingOptions
        onEditTrainingClick={() => onEditTrainingClick({ training, course })}
        onDeleteTrainingClick={() => onDeleteTrainingClick({ training, course })}
      />
    )
  };

  useEffect(() => {
    if (training.length <= 1 && isHistoricalTrainingVisible) toggleHistoricalTraining(false)
  }, [JSON.stringify(training)])

  return (
    <Fragment>
      <TrainingRow
        isArchived={isArchived}
        training={latestTraining}
        attachments={latestTraining && attachmentsByAttachableId[latestTraining.id]}
        course={course}
        isRequired={isRequired}
        setModalBoxData={setModalBoxData}
        hasTrainingEditableAccess={hasTrainingEditableAccess}
        renderStatus={renderStatus}
        renderBookings={renderBookings}
        renderActions={renderRegularTrainingActions}
      />
      {isHistoricalTrainingVisible && training.length > 1 && training.slice(1).map(historicalTraining => {
        return (
          <TrainingRow
            key={`historicalTraining--${historicalTraining.id}`}
            isHistorical={true}
            isArchived={isArchived}
            training={historicalTraining}
            attachments={attachmentsByAttachableId[historicalTraining.id]}
            course={course}
            isRequired={isRequired}
            setModalBoxData={setModalBoxData}
            renderActions={renderHistoricalTrainingActions}
          />
        )
      })}
    </Fragment>
  )
}

CourseTraining.propTypes = {
  isRequired: PropTypes.bool,
  isWithinRoleTable: PropTypes.bool,
  course: resourceShape('course').isRequired,
  autoEnrolmentConfiguration: resourceShape('autoEnrolmentConfiguration'),
  autoEnrolmentExclusion: resourceShape('autoEnrolmentExclusion'),
  eLearningCourse: resourceShape('eLearningCourse'),
  eLearningAllowance: PropTypes.object,
  training: PropTypes.array,
  booker: PropTypes.object,
  booking: PropTypes.object,
  registration: PropTypes.object,
  attachments: PropTypes.array,
  creditInfoUnavailable: PropTypes.bool,
  onBookingReminderClick: PropTypes.func.isRequired,
  onShowBooking: PropTypes.func.isRequired,
  onRemoveBooking: PropTypes.func.isRequired,
  onCreateAutoEnrolmentExclusion: PropTypes.func,
  onDeleteAutoEnrolmentExclusion: PropTypes.func,
  currentPersonnel: PropTypes.shape({
    archivedAt: PropTypes.node,
    company: PropTypes.object,
    email: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  personnelId: PropTypes.string,
  userCourse: resourceShape('userCourse'),
  hasTrainingEditableAccess: PropTypes.bool,
  isRequiredByAnything: PropTypes.bool,
  isRequiredByAnotherRole: PropTypes.bool,
  requiredSource: PropTypes.string,
  isAdditional: PropTypes.bool,
  onAddBooking: PropTypes.func,
  onAddELearningBooking: PropTypes.func,
  onNewTrainingClick: PropTypes.func,
  onEditTrainingClick: PropTypes.func,
  onDeleteTrainingClick: PropTypes.func,
  onDeleteCourseClick: PropTypes.func,
  onUpdateUserCourse: PropTypes.func,
  onCreateUserCourse: PropTypes.func,
  onRemoveBooking: PropTypes.func,
  onBookingReminderClick: PropTypes.func,
  setModalBoxData: PropTypes.func,
  onCreateAutoEnrolmentExclusion: PropTypes.func,
  onDeleteAutoEnrolmentExclusion: PropTypes.func,
}

CourseTraining.defaultProps = {
  hasTrainingEditableAccess: true,
  isRequired: true,
  isWithinRoleTable: true,
  attachments: [],
  training: [],
  booking: null,
  registration: null
}
