import React from 'react';
import PropTypes from 'prop-types';
import PersonalInfo from 'components/application/PersonalInfo';
import moment from 'moment';

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources'
import PersonnelLabel from 'components/personnel/PersonnelLabel';
import PersonnelCourseTrainingStatusIndicator from 'components/personnel/PersonnelCourseTrainingStatusIndicator';

export default function BookingSidePanelDetailsTab({
  domainBookingSource,
  booker,
  course,
  domainBookingSourcePersonnelCollection,
  totalReadableBookingCount,
  bookingGroupInfoBanner,
  endOfListRef,
  isFetching,
  shouldShowPersonnel = false
}) {
  const currentActor = useCurrentActor();
  const hasPersonnelAccessType = currentActor.user.attributes.accessType === 'personnel';

  const bookerText = (() => {
    if (!booker) return null

    return hasPersonnelAccessType ? (
      <><span>{personDisplayName(booker.attributes)}</span> <span>&#40;</span><a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href={`mailto:${booker.attributes.email}`}>{booker.attributes.email}</a><span>&#41;</span></>
    ) : (
      `${personDisplayName(booker?.attributes)} on ${moment.parseZone(domainBookingSource?.attributes.createdAt).format('DD MMM YY [at] HH:mm')}`
    )
  })();

  const courseHasELearningCourse = !!course.relationships.eLearningCourse.data?.id;

  return (
    <>
      <div className='popup__body-content popup__body-content--border-top tw-border-grey-100'>
        {
          !courseHasELearningCourse && (
            <>
              <PersonalInfo label='Training date' text={moment.parseZone(domainBookingSource?.attributes.date).format('DD MMM YY')} />
              <PersonalInfo label='Booking notes' isTruncated={false} text={domainBookingSource?.attributes.notes} />
            </>
          )
        }
        <PersonalInfo label={`${courseHasELearningCourse ? 'Enrolled' : 'Booked'} by`} text={bookerText} />
        {!hasPersonnelAccessType && !shouldShowPersonnel && bookingGroupInfoBanner}
      </div>
      {
        shouldShowPersonnel && (
          <div className='popup__body-content popup__body-content--border-top tw-border-grey-100'>
            <div className='tw-flex'>
              <div className='personal-info__label tw-text-grey-900 tw-font-medium tw-mb-2 tw-mr-2'>
                Personnel
              </div>
              <div className='tw-bg-grey-050 tw-h-5 tw-px-1.5 tw-rounded-lg tw-flex tw-text-center tw-items-center tw-justify-center'>
                <div className='tw-text-xs tw-font-semibold tw-font-inter tw-m-0'>
                  {totalReadableBookingCount}
                </div>
              </div>
            </div>
            <>
              <div className='tw-mb-2'>
                {bookingGroupInfoBanner}
              </div>
              <div className='tw-flex tw-flex-col tw-gap-1'>
                {domainBookingSourcePersonnelCollection?.map((personnel) => (
                  <PersonnelLabel key={personnel.id} personnel={personnel} />
                ))}
                {isFetching && <div className='tw-bg-grey-050 tw-flex tw-gap-2 tw-h-8 tw-items-center tw-px-3 tw-rounded-lg'>
                  <PersonnelCourseTrainingStatusIndicator status={5} />
                  <div className='tw-font-medium tw-text-s'>Loading...</div>
                </div>
                }
              </div>
            </>
            <div ref={endOfListRef}></div>
          </div>
        )
      }

    </>
  )
}

BookingSidePanelDetailsTab.propTypes = {
  booker: resourceShape('simpleUser'),
  domainBookingSource: resourceShape(['booking', 'bookingGroup']),
  domainBookingSourcePersonnelCollection: PropTypes.arrayOf(resourceShape('personnel')),
  totalReadableBookingCount: PropTypes.number,
  bookingGroupInfoBanner: PropTypes.node,
  endOfListRef: PropTypes.func,
  isFetching: PropTypes.bool,
  shouldShowPersonnel: PropTypes.bool,
  course: resourceShape('course')
}
