import React from 'react';
import { resourceShape } from 'components/helpers/serialisableResources';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { courseDuration, courseLearningMethod, courseRenewalFrequency, isCourseEligibleForAutoEnrolment } from 'components/helpers/resources/courses';
import { displayCurrencyString, currencyNumberToCurrencyString } from 'components/helpers/currency';
import PersonalInfo from 'components/application/PersonalInfo'
import AutoEnrolIcon from '-!svg-react-loader?name=AutoEnrolIcon!icons/double-arrow-rotate.svg';

export default function BookingCourseDetails({ course }) {
  const currentActor = useCurrentActor();
  const { isAutoEnrolmentIncludingMissingTraining } = useTrainingRegisterResources();

  const currencyCode = currentActor.division.attributes.currencyCode;
  const courseCostEstimate = currencyNumberToCurrencyString({ number: course.attributes.costEstimate, currencyCode });
  const costEstimate = displayCurrencyString({ string: courseCostEstimate, currencyCode });

  return (
    <>
      <PersonalInfo label='Renewal frequency' text={courseRenewalFrequency({ course })} />
      <div className='personal-info'>
        <div className='personal-info__info-container'>
          <div className='personal-info__label tw-text-grey-900 tw-font-medium'>
            Method
          </div>
          <div className={'personal-info__text'}>
            {isCourseEligibleForAutoEnrolment({ currentActor, isAutoEnrolmentIncludingMissingTraining, course }) ? (
              <div className='tw-flex tw-items-center tw-justify-between'>
                <span>{'eLearning ('}</span>
                <AutoEnrolIcon className='[&_path]:tw-fill-grey-700'/>
                <span>{'Auto-enrols)'}</span>
              </div>
            ) : (
              <span>{courseLearningMethod({ course })}</span>
            )}
          </div>
        </div>
      </div>
      <PersonalInfo label='Provider' text={course.attributes.provider} />
      <PersonalInfo label='Cost estimate' text={costEstimate} />
      <PersonalInfo label='Time estimate' text={courseDuration({ course })} />
    </>
  )
}

BookingCourseDetails.propTypes = {
  course: resourceShape('course').isRequired
}
