import PropTypes from 'prop-types';

export function resourceShape(resourceType) {
  return PropTypes.shape({ type: PropTypes.oneOf(Array.isArray(resourceType) ? resourceType : [resourceType]).isRequired })
}

export function resourcesAsResourceMap(resources) {
  return resources.reduce((accumulator, resourceName) => {
    accumulator[resourceName] = new Map;
    return accumulator;
  }, new Map)
}
