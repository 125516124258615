import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { resourcesAsResourceMap, resourceShape } from 'components/helpers/serialisableResources';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useBreadBoard } from 'components/contexts/Toaster';
import Select, { components } from 'react-select';
import CheckCircle from '-!svg-react-loader?name=CheckCircle!icons/check-circle.svg';
import { DropdownIndicator } from 'components/application/CollectionSelect';
import PersonnelCourseTrainingStatusIndicator from 'components/personnel/PersonnelCourseTrainingStatusIndicator';
import Tooltip from 'components/application/Tooltip';
import { getNextPageParam } from "components/helpers/reactQuery";
import moment from 'moment';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import { personDisplayName } from 'components/helpers/users';

const assignmentBlockerTooltips = {
  fetchingDomainPersonnel: 'Loading...',
  unableToCalculateTrackedPersonnel: 'Unable to calculate training limit. Please try again later.',
  wouldGoOverTrackedLimit: 'You have reached your training limit',
  emailRequiredForEnrolment: 'Personnel does not have email address',
  lacksBookingPermission: 'You do not have permissions to book training for this personnel',
  noAssignableBookees: 'Training for this course has not been assigned to any personnel.',
  problemDisplayingELearningCredits: 'There was a problem displaying your remaining credits'
}

function Option(props) {
  const { member, training, booking, course, disabledReason } = props.data.optionProps;
  const courseHasELearningCourse = !!course?.relationships?.eLearningCourse?.data?.id;

  const additionalText = (() => {
    switch (true) {
      case disabledReason === 'bookedAsPartOfConnection':
        return (
          <div className='tw-flex'>
            <div>Added to booking</div>
            <div className="tw-ml-1 tw-pt-0.5"><CheckCircle height={16} width={16} /></div>
          </div>
        )
      case disabledReason === 'bookedOutsideOfConnection':
        return (
          <>
            <div>
              {courseHasELearningCourse
                ? `Enrolled on ${moment(booking.attributes.createdAt).format('D MMM YYYY')}`
                : `Booked for ${moment(booking.attributes.date).format('D MMM YYYY')}`}
            </div>
          </>
        )
      case course.attributes.expires:
        switch (member.attributes.trainingStatus) {
          case 0:
          case 1:
          case 5:
            return training ? '' : 'Missing'
          case 2:
            return 'Expired';
          case 3:
          case 4:
            return `Expires on ${moment(training.attributes.expiryDate).format("D MMM YYYY")}`;
          default:
            return;
        }
      case !course.attributes.expires:
        return training ? 'Does not expire' : 'Missing'
    }
  })();

  return (
    <components.Option {...props} className={classNames(!!(disabledReason) && 'tw-bg-grey-025 tw-text-grey-400')}>
      <div className='collection-select__option_container tw-min-h-5 tw-flex tw-justify-between tooltip-parent'>
        {['wouldGoOverTrackedLimit', 'unableToCalculateTrackedPersonnel', 'emailRequiredForEnrolment', 'lacksBookingPermission'].includes(disabledReason) && <Tooltip tooltip={assignmentBlockerTooltips[disabledReason]} placement='bottom' trigger='hover' arrow />}
        <div className='tw-inline-flex tw-flex-1'>
          <div className='tw-self-start tw-mt-[5px]'>
            <PersonnelCourseTrainingStatusIndicator status={member.attributes.trainingStatus} />
          </div>
          <div className='tw-ml-2'>
            <div className='tw-font-medium'>{personDisplayName(member.attributes)}</div>
            <div className='tw-self-center tw-text-s tw-text-grey-500'>{member.attributes.externalId}</div>
          </div>
        </div>
        <div className='tw-ml-1 tw-whitespace-nowrap'>
          {additionalText}
        </div>
      </div>
    </components.Option>
  );
};

export default function SelectMembers({
  selectedMemberIDs,
  domainBookingSource,
  domainPersonnelCollection,
  domainCourse,
  onBookingGroupMemberSelect,
  isFetching,
  isELearningAllowanceError
}) {
  const breadBoard = useBreadBoard();
  const currentActor = useCurrentActor();
  const [valueMenuOpen, setValueMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isDebouncing, setIsDebouncing] = useState(false);

  const trackedPersonnelAllowance = currentActor.subscription.attributes.trackedPersonnelAllowance;
  const isTrackingPersonnel = trackedPersonnelAllowance != null;

  // handlers
  const handleMenuOpen = () => setValueMenuOpen(true);
  const handleMenuClose = () => setValueMenuOpen(false);
  const handleDebounce = (searchText) => {
    setSearchText(searchText);
    setIsDebouncing(false);
  };
  const debounceInput = useRef(_.debounce(searchText => handleDebounce(searchText), 500)).current;
  const handleInputChange = (searchText) => {
    if (searchText && searchText.length > 0) setIsDebouncing(true);
    debounceInput(searchText);
  };

  // queries
  const {
    data: assignableBookees,
    fetchNextPage: fetchAssignableBookees,
    hasNextPage: assignableBookeesHasNextPage,
    isFetching: assignableBookeesIsFetching,
    isSuccess: assignableBookeesIsSuccess
  } = useInfiniteQuery({
    queryKey: ['assignableBookees', 'courses', domainCourse.id, { search: searchText }],
    queryFn: async ({ pageParam = 1 }) => {
      const assignableBookeesResponse = await axios.get(`/courses/${domainCourse.id}/assignable_bookees`, { params: { page: pageParam, search: searchText } });
      return assignableBookeesResponse.data
    },
    getNextPageParam: getNextPageParam,
    select: (results) => {
      const resourceMap = resourcesAsResourceMap(['booking', 'training']);
      results.pages.map((page) => page.included).flat()?.forEach((inclusion) => {
        if (resourceMap[inclusion.type]) { resourceMap[inclusion.type][inclusion.id] = inclusion; }
      })
      return {
        data: results.pages.map((page) => page.data).flat(),
        resourceMap: resourceMap
      }
    },
    enabled: !!domainCourse.id && valueMenuOpen,
    onError: breadBoard.addInedibleToast
  });

  const {
    data: assignableBookeesMeta
  } = useQuery({
    queryKey: ['assignableBookeesMeta', 'courses', domainCourse.id],
    queryFn: async () => {
      const assignableBookeesResponse = await axios.get(`/courses/${domainCourse.id}/assignable_bookees`, { params: { meta_only: true } });
      return assignableBookeesResponse.data.meta
    },
    enabled: !!domainCourse.id,
    onError: breadBoard.addInedibleToast
  });

  const {
    data: trackedPersonnelUsage,
    isSuccess: trackedPersonnelUsageIsSuccess
  } = useQuery({
    queryKey: ['trackedPersonnelUsage'],
    enabled: isTrackingPersonnel,
    queryFn: async () => {
      const response = await axios.get('/personnel?tracked=true&meta_only=true&account_personnel=true');
      return response.data.meta.scopedCount;
    }
  })

  // computed
  const selectedMemberIDsSet = new Set(selectedMemberIDs);

  const assignmentBlockers = (function calculateMemberAssignmentBlocker() {
    const blockers = [];
    if (isFetching) { blockers.push('fetchingDomainPersonnel') }
    if (assignableBookeesMeta?.totalPages === 0) { blockers.push('noAssignableBookees') }
    if (isELearningAllowanceError) { blockers.push('problemDisplayingELearningCredits') }
    if (isTrackingPersonnel) {
      if (!trackedPersonnelUsageIsSuccess) { blockers.push('unableToCalculateTrackedPersonnel') }

      (function ensureProposedTrackedPersonnelUsage() {
        const nonPersistedMembers = domainPersonnelCollection ? selectedMemberIDs.filter(personnelID => !domainPersonnelCollection.some((existingMember) => existingMember.id == personnelID)) : selectedMemberIDs;
        if (nonPersistedMembers.length > 0 && !assignableBookeesIsSuccess) {
          blockers.push('unableToCalculateTrackedPersonnel')
          return
        }
        const personnelWhoWouldBecomeTrackedCount = (() => {
          if (nonPersistedMembers.length > 0) {
            const assignableMembersMap = new Map(assignableBookees.data.map(member => [member.id, member]));
            const nonTrackedUnpersistedMembers = nonPersistedMembers.filter(personnelID => !assignableMembersMap.get(personnelID)?.attributes?.isTracked)
            return nonTrackedUnpersistedMembers.length
          } else {
            return 0
          }
        })();

        const personnelWhoWouldBecomeUntrackedCount = domainPersonnelCollection
          ? domainPersonnelCollection.filter(personnel => !selectedMemberIDsSet.has(personnel.id) && personnel.meta.isTrackedOnlyForThisBookingSource).length
          : 0;

        const currentProposedUsage = trackedPersonnelUsage + personnelWhoWouldBecomeTrackedCount - personnelWhoWouldBecomeUntrackedCount;
        if (currentProposedUsage >= trackedPersonnelAllowance) blockers.push('trackedPersonnelLimitReached')
      }());
    }
    return blockers
  }())

  const hardBlockers = assignmentBlockers.filter(blocker =>
    [
      'fetchingDomainPersonnel',
      'unableToCalculateTrackedPersonnel',
      'problemDisplayingELearningCredits',
      'noAssignableBookees'
    ].includes(blocker)
  );

  const assignableOptions = (() => {
    if (isDebouncing || !assignableBookeesIsSuccess) { return [] }
    const isProhibitedFromAddingNonTracked = assignmentBlockers.includes('trackedPersonnelLimitReached');
    return assignableBookees.data.map((member) => {
      const training = assignableBookees.resourceMap['training'][member.relationships.training?.data?.id];
      const booking = assignableBookees.resourceMap['booking'][member.relationships.booking?.data?.id];
      let disabledReason;
      if (selectedMemberIDsSet.has(member.id)) {
        disabledReason = 'bookedAsPartOfConnection';
      } else if (booking && (
        // new connection
        (!domainBookingSource) ||
        // not within same domain connection for booking group
        ((domainBookingSource.type === 'bookingGroup') && (booking?.relationships.bookingGroup?.data?.id != domainBookingSource.id)) ||
        // not within same domain connection for booking
        (domainBookingSource.type === 'booking' && booking.id != domainBookingSource.id)
      )) {
        disabledReason = 'bookedOutsideOfConnection';
      } else if (isProhibitedFromAddingNonTracked && !member.attributes.isTracked) {
        disabledReason = 'wouldGoOverTrackedLimit';
      } else if (assignmentBlockers.includes('unableToCalculateTrackedPersonnel')) {
        disabledReason = 'unableToCalculateTrackedPersonnel';
      } else if (!!domainCourse?.relationships?.eLearningCourse?.data?.id && !member.attributes.email) {
        disabledReason = 'emailRequiredForEnrolment';
      } else if (!member.meta.isBookingEligible) {
        disabledReason = 'lacksBookingPermission';
      }

      return {
        value: member.id,
        label: `${member.attributes.firstName} ${member.attributes.lastName}`,
        disabled: !!(disabledReason),
        optionProps: { booking, course: domainCourse, disabledReason, member, training }
      }
    })
  })();

  return (
    <>
      <label
        className="collection-select__label tw-font-medium after:tw-absolute after:tw-text-grey-500 after:tw-content-['Required'] after:tw-right-0 after:tw-font-normal"
        htmlFor="bookingGroupMemberIds"
      >
        Add personnel
      </label>
      <div className='tooltip-parent'>
        {hardBlockers.length > 0 && <Tooltip tooltip={assignmentBlockerTooltips[hardBlockers[0]]} placement='bottom' trigger='hover' arrow />}
        <Select
          className="collection-select__select-container collection-select--grey-disabled tw-mb-4"
          classNamePrefix="collection-select"
          name="bookingGroupMemberIds"
          id="bookingGroupMemberIds"
          components={{ DropdownIndicator, Option }}
          value={null}
          placeholder="Search for personnel..."
          isDisabled={hardBlockers.length > 0}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          onChange={(e) => onBookingGroupMemberSelect(e.value)}
          options={assignableOptions}
          isOptionDisabled={(option) => option.disabled}
          isLoading={isDebouncing || assignableBookeesIsFetching}
          loadingMessage={() => "Loading..."}
          noOptionsMessage={() => (!!searchText ?
            <>
              No matching results.
              <br/>
              <a
                className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300'
                href="https://intercom.help/hands-hq/en/articles/10248439-new-bulk-actions-mass-book-and-record-training#h_c975352937"
                target="_blank"
                rel="noopener noreferrer"
              >Learn more about booking personnel</a>
            </>
            : null)}
          onInputChange={handleInputChange}
          onMenuScrollToBottom={() => { if (assignableBookeesHasNextPage) fetchAssignableBookees() }}
          openMenuOnFocus={false}
          filterOption={() => true}
        />
      </div>
    </>
  );
}

SelectMembers.propTypes = {
  selectedMemberIDs: PropTypes.array.isRequired,
  domainBookingSource: resourceShape(['booking', 'bookingGroup']),
  domainPersonnelCollection: PropTypes.arrayOf(resourceShape('personnel')),
  domainCourse: resourceShape('course').isRequired,
  onBookingGroupMemberSelect: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  isELearningAllowanceError: PropTypes.bool
}
