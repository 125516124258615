import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import DatePickerField from 'components/application/DatePickerField';
import moment from 'moment';
import TextArea from 'components/application/TextArea';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import { resourceShape } from 'components/helpers/serialisableResources'

import { isExpiryDateAfterRenewalDate } from 'components/helpers/resources/training';

export default function TrainingFormFields({
  currentTraining,
  domainCourse,
  requestError,
  onInputChange,
  removeErrorStyling,
  submitDisabled,
  onTrainingDateChange,
  onTrainingCalendarClose,
  notesExplanatoryMessage
}) {

  const courseInterval = `${domainCourse?.attributes?.renewalInterval}`;
  const courseIntervalS = `${domainCourse?.attributes?.renewalFrequency > 1 ? 's' : ''}`;
  const courseExpires = domainCourse?.attributes?.expires;

  return (
    <Fragment>
      {courseExpires &&
        <div className='tw-flex tw-border-0 tw-rounded-lg tw-text-cyan-800 tw-bg-cyan-025 tw-p-3 m-b-24'>
          <div className='fh-20 m-r-12'>
            <InfoIcon width={20} height={20} className='[&_path]:tw-fill-cyan-800' />
          </div>
          <p className='m-0'>Fill in <span className='tw-font-semibold'>either date</span> and the other will be automatically filled in based off a renewal frequency of <span className='tw-font-semibold'>{`${domainCourse?.attributes?.renewalFrequency} ${courseInterval}${courseIntervalS}`}.</span></p>
        </div>
      }
      <div className='flex flex--justify-content__space-between'>
        <div className='m-r-12'>
          <DatePickerField
            label='Valid from date'
            name='startDate'
            value={currentTraining.startDate}
            fieldError={requestError.validationErrors.startDate}
            isRequired={true}
            removeErrorStyling={removeErrorStyling}
            submitDisabled={submitDisabled}
            latestDate={moment().toDate()}
            onChange={onTrainingDateChange}
            onCalendarClose={onTrainingCalendarClose}
          />
        </div>
        <div>
          <DatePickerField
            label='Expiry date'
            name='expiryDate'
            placement='bottom-end'
            value={currentTraining.expiryDate}
            fieldError={requestError.validationErrors.expiryDate}
            isRequired={courseExpires}
            removeErrorStyling={removeErrorStyling}
            submitDisabled={submitDisabled}
            isDisabled={!courseExpires}
            disabledPlaceholderText='Does not expire'
            earliestDate={currentTraining.startDate}
            onChange={onTrainingDateChange}
            onCalendarClose={onTrainingCalendarClose}
          />
        </div>
      </div>

      {
        isExpiryDateAfterRenewalDate({
          startDate: currentTraining.startDate,
          expiryDate: currentTraining.expiryDate,
          renewalFrequency: domainCourse?.attributes?.renewalFrequency,
          renewalInterval: domainCourse?.attributes?.renewalInterval
        }) &&
        <div className='tw-mb-6 tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 tw-flex'>
          <div className='tw-h-5 tw-w-4 tw-flex tw-items-center tw-mr-3'>
            <InfoIcon width={16} height={16} className='[&_path]:tw-fill-amber-800' />
          </div>
          Selected dates exceed the recommended renewal frequency set for this course.
        </div>
      }

      <TextArea label='Notes' name='notes' value={currentTraining.notes} fieldError={requestError.validationErrors.notes} onChange={onInputChange} additionalText={notesExplanatoryMessage} />
    </Fragment>
  )
}

TrainingFormFields.propTypes = {
  currentTraining: PropTypes.object,
  domainCourse: resourceShape('course'),
  requestError: PropTypes.object,
  onInputChange: PropTypes.func.isRequired,
  removeErrorStyling: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  onTrainingDateChange: PropTypes.func.isRequired,
  onTrainingCalendarClose: PropTypes.func.isRequired,
  notesExplanatoryMessage: PropTypes.string
}
