import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { resourceShape } from 'components/helpers/serialisableResources'

import { useCurrentActor } from 'components/contexts/CurrentActor';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import ConditionalWrapper from 'components/application/ConditionalWrapper';
import SidePanel from 'components/application/SidePanel';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import InfoIcon from '-!svg-react-loader?name=InfoIcon!icons/info.svg';
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';
import BookingConnectionsSidePanelForm from 'components/bookings/BookingConnectionsSidePanelForm.jsx';
import BookingSidePanelShow from 'components/training/BookingSidePanelShow.jsx';

export function isBookingSubmitBlocked({ domainELearningCourse, eLearningAllowance, remainingCredits }) {
  const blockers = []
  if (domainELearningCourse && !domainELearningCourse.attributes.custom) {
    if (!eLearningAllowance) {
      blockers.push('allowanceNotLoaded')
    } else if (remainingCredits < 0) {
      blockers.push('overCreditUsage')
    }
  }
  return blockers.length != 0
}

export default function BookingSidePanel({
  currentBooking,
  domainPersonnel,
  domainPersonnelMeta,
  domainPersonnelFetchMeta,
  endOfDomainPersonnelListRef,
  eLearningAllowance,
  onCancel,
  onSubmit,
  submitDisabled,
  requestError,
  removeErrorStyling,
  sidePanelIsOpen,
  onInputChange,
  onDateChange,
  onBookingGroupMemberSelect,
  onBookingGroupMemberDelete,
  sidePanelContext,
  domainBookingSource,
  domainBooker,
  domainCourse,
  domainELearningCourse,
  onEditBookingClick,
  onBookingCreateSubmit,
  onBookingUpdateSubmit,
  onRemoveBooking,
  onBookingReminderClick,
  showBookingGroupView,
  onRecordTraining,
  isELearningAllowanceError
}) {
  const [scrolledAmount, setScrolledAmount] = useState(0);
  const currentActor = useCurrentActor();
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();

  const isBooking = domainBookingSource?.type === 'booking';
  const isBookingGroup = domainBookingSource?.type === 'bookingGroup';
  const isBookingWithBookingGroup = isBooking && !!(domainBookingSource.relationships?.bookingGroup?.data);
  const isCourseCreditBlockable = domainELearningCourse && !domainELearningCourse.attributes.custom;
  const isPastBookingSource = moment.parseZone(domainBookingSource?.attributes.date).isBefore(moment().startOf('day'));

  const persistedMembersCount = (() => {
    switch (true) {
      case sidePanelContext === 'new': return 0
      case isBooking: return 1
      case isBookingGroup: return domainBookingSource.meta.personnelCount
      default: null
    }
  })()

  const remainingCredits = (() => {
    if (!(isCourseCreditBlockable) || !(eLearningAllowance) || persistedMembersCount === null) {
      return null
    }
    return eLearningAllowance.data.attributes.currentCredits - (currentBooking.personnelIds.length - persistedMembersCount)
  })()

  const header = (function renderHeader() {
    return (
      <div className='popup__title popup__title--tertiary-split'>
        {sidePanelContext !== 'show' && (
          <h1 className='tw-text-s tw-font-medium tw-tracking-tight tw-mb-2'>
            {(function determineTitle() {
              switch (sidePanelContext) {
                case 'new':
                  return `New training ${!!(domainCourse?.relationships?.eLearningCourse?.data) ? 'enrolment' : 'booking'}`
                case 'edit':
                  // assumed that enrolments cannot be edited
                  return 'Edit training booking'
              }
            })()}
          </h1>
        )}
        <h2 className='truncated-text-container--wrapped truncated-text-container--five-lines tw-text-xl tw-text-grey-900 tw-font-semibold tw-tracking-tight'>
          {domainCourse?.attributes.name}
        </h2>
      </div>
    )
  })();

  const body = (function renderBody() {
    if (!(!!domainCourse)) { return null };
    switch (true) {
      case sidePanelContext === 'new' || sidePanelContext === 'edit': return (
        <BookingConnectionsSidePanelForm
          currentBooking={currentBooking}
          domainBookingSource={domainBookingSource}
          domainPersonnelCollection={isBooking ? (domainPersonnel && [{ ...domainPersonnel }]) : domainPersonnel}
          domainCourse={domainCourse}
          onBookingGroupMemberDelete={onBookingGroupMemberDelete}
          onBookingGroupMemberSelect={onBookingGroupMemberSelect}
          onDateChange={onDateChange}
          onInputChange={onInputChange}
          remainingCredits={remainingCredits}
          removeErrorStyling={removeErrorStyling}
          requestError={requestError}
          showBookingGroupView={showBookingGroupView}
          eLearningAllowance={eLearningAllowance}
          isFetching={domainPersonnelFetchMeta?.isFetching}
          isELearningAllowanceError={isELearningAllowanceError}
        />
      )
      case sidePanelContext === 'show' && !!domainBookingSource:
        const totalReadableBookingCount = isBookingGroup ? domainPersonnelMeta?.totalReadableBookingCount : 1;
        const totalBookingGroupPersonnelCount = domainPersonnelMeta?.totalUnscopedCount;
        const totalWriteableBookingCount = domainPersonnelMeta?.totalWriteableBookingCount;
        const shouldDisplayBanner = currentActor.user.attributes.accessType !== 'personnel' && (isBookingGroup || isBookingWithBookingGroup) && !!domainPersonnelMeta;

        return (
          <BookingSidePanelShow
            booker={domainBooker}
            bookingGroupInfoBanner={
              shouldDisplayBanner && <BookingGroupInfoBanner
                isBookingGroup={isBookingGroup}
                isBookingWithBookingGroup={isBookingWithBookingGroup}
                totalBookingGroupPersonnelCount={totalBookingGroupPersonnelCount}
                totalReadableBookingCount={totalReadableBookingCount}
                totalWriteableBookingCount={totalWriteableBookingCount}
                isPastBookingSource={isPastBookingSource}
              />
            }
            course={domainCourse}
            domainBookingSource={domainBookingSource}
            shouldShowPersonnel={showBookingGroupView}
            domainBookingSourcePersonnelCollection={showBookingGroupView ? (isBooking ? (domainPersonnel && [{ ...domainPersonnel }]) : domainPersonnel) : null}
            endOfListRef={endOfDomainPersonnelListRef}
            isFetching={domainPersonnelFetchMeta?.isFetching}
            sidePanelIsOpen={sidePanelIsOpen}
            totalReadableBookingCount={totalReadableBookingCount}
          />
        )
    }
  }
  )()

  const footer = (function renderFooter() {
    switch (true) {
      case sidePanelContext === 'new': return (
        <FormFooter
          text={!!domainELearningCourse ? 'Enrol personnel' : 'Add booking'}
          onCancel={onCancel}
          onSubmit={() => { showBookingGroupView ? onBookingCreateSubmit() : onSubmit(currentBooking.courseName) }}
          submitButtonDisabled={submitDisabled || isBookingSubmitBlocked({ domainELearningCourse, eLearningAllowance, remainingCredits })}
          requestError={requestError}
          onSubmitTooltip={isELearningAllowanceError ? 'There was a problem displaying your remaining credits' : null}
        />
      )
      case sidePanelContext === 'show':
        const totalBookingGroupPersonnelCount = domainPersonnelMeta?.totalUnscopedCount;
        const totalReadableBookingCount = domainPersonnelMeta?.totalReadableBookingCount;
        const totalWriteableBookingCount = domainPersonnelMeta?.totalWriteableBookingCount;

        const hasFullReadAccess = domainPersonnelFetchMeta?.isSuccess && totalBookingGroupPersonnelCount === totalReadableBookingCount;
        const hasFullWriteAccess = domainPersonnelFetchMeta?.isSuccess && totalBookingGroupPersonnelCount === totalWriteableBookingCount;
        const isNotifiable = domainPersonnelFetchMeta?.isSuccess && domainPersonnelMeta.notifiableSetCount > 0
        const isBookingWithoutBookingGroup = isBooking && !(domainBookingSource?.relationships?.bookingGroup?.data);

        let sendReminderDisabledTooltip;
        if (domainBookingSource?.type === 'bookingGroup') {
          if (!isNotifiable) {
            sendReminderDisabledTooltip = 'No email addresses have been added for any personnel on this booking'
          }
        } else if (domainBookingSource?.type === 'booking') {
          if (!domainPersonnel || (!domainPersonnel.email && !domainPersonnel.attributes?.email)) {
            sendReminderDisabledTooltip = 'Personnel has no email'
          }
        }

        return (
          <>
            {(showBookingGroupView
              ? isBooking || hasFullReadAccess
              : trainingRegisterResourceManagementContext.hasBookingRemindableAccess) && !isPastBookingSource && (
                <div className='tw-border-solid tw-border-0 tw-border-t-1 tw-border-grey-100 fh-58'>
                  <ConditionalWrapper
                    condition={!!sendReminderDisabledTooltip}
                    wrapper={(children) => (
                      <Tooltip placement='left' className='tooltip-dark--max-w-xxs' trigger='hover' tooltip={sendReminderDisabledTooltip}>
                        {children}
                      </Tooltip>
                    )}
                  >
                    <TextButton color='blue' disabled={!!sendReminderDisabledTooltip} onClick={() => onBookingReminderClick(domainBookingSource)} size='full'>
                      {isBookingWithoutBookingGroup || isBookingGroup ? 'Send reminder' : 'Send reminder to this personnel'}
                    </TextButton>
                  </ConditionalWrapper>
                </div>
              )}
            {
              !(!!domainELearningCourse) && (showBookingGroupView ? domainPersonnelMeta?.isTrainingEligible : trainingRegisterResourceManagementContext.hasTrainingEditableAccess)
              && (
                <div className='tw-border-solid tw-border-0 tw-border-t-1 tw-border-grey-100 fh-58'>
                  <TextButton color='blue' onClick={onRecordTraining} size='full'>
                    {isBookingWithoutBookingGroup || isBookingGroup ? 'Record training' : 'Record training for this personnel'}
                  </TextButton>
                </div>
              )}
            {(showBookingGroupView ?
              (isBooking ? (domainPersonnelMeta?.isEditingEligible) : (trainingRegisterResourceManagementContext.hasBookingGroupEditableAccess && hasFullWriteAccess))
              : isBookingWithoutBookingGroup && trainingRegisterResourceManagementContext.hasBookingEditableAccess)
              && (
                <ShowFooter
                  type={!!domainELearningCourse ? 'enrolment' : 'booking'}
                  deleteDisabled={!!(domainBookingSource?.relationships?.activeRegistration?.data?.id)}
                  deleteDisabledTooltip="eLearning is in progress"
                  onDeleteClick={() => onRemoveBooking({ bookingId: domainBookingSource?.id })}
                  onEditClick={onEditBookingClick}
                  resource={domainCourse?.attributes.name}
                  isEditButtonHidden={!!(domainELearningCourse || isPastBookingSource)}
                />
              )}
            {(!isBookingWithoutBookingGroup && !showBookingGroupView && trainingRegisterResourceManagementContext.hasBookingEditableAccess) && (
              <div className='tw-border-solid tw-border-0 tw-border-t-1 tw-border-grey-100 fh-58'>
                <TextButton color='red' onClick={() => onRemoveBooking({ bookingId: domainBookingSource?.id })} size='full'>
                  Delete booking for this personnel
                </TextButton>
              </div>
            )}
          </>
        )
      case sidePanelContext === 'edit':
        return (
          <FormFooter
            text='Save changes'
            submitButtonDisabled={showBookingGroupView && (!(domainPersonnelFetchMeta?.isSuccess)) || submitDisabled || isBookingSubmitBlocked({ domainELearningCourse, eLearningAllowance, remainingCredits })}
            onSubmit={onBookingUpdateSubmit}
            onCancel={onCancel}
          />
        )
      default:
        return <></>;
    }
  })();

  return (
    <SidePanel
      color='tertiary'
      isOpen={sidePanelIsOpen}
      scrolledAmount={scrolledAmount}
      setScrolledAmount={setScrolledAmount}
      displayClose={sidePanelContext === 'show'}
      onBackdropClick={onCancel}
      closeCallback={onCancel}
      headerContent={header}
      bodyContent={body}
      footerContent={footer}
      submitDisabled={submitDisabled}
      contentContext={sidePanelContext}
    />
  )
}

export function BookingGroupInfoBanner({
  isBookingGroup,
  isBookingWithBookingGroup,
  totalBookingGroupPersonnelCount,
  totalReadableBookingCount,
  totalWriteableBookingCount,
  isPastBookingSource
}) {
  const otherPersonnelCount = isBookingGroup ? totalBookingGroupPersonnelCount - totalReadableBookingCount : totalBookingGroupPersonnelCount - 1;
  const cannotViewAllPersonnel = totalBookingGroupPersonnelCount !== totalReadableBookingCount;
  const completeWritableAccess = totalWriteableBookingCount === totalBookingGroupPersonnelCount;

  const bookingGroupInfoText = (function renderBookingGroupInfoText() {
    switch (true) {
      case isBookingWithBookingGroup:
        return (
          <>
            This booking includes <b className='tw-font-medium'>{otherPersonnelCount} other personnel</b>.
            {completeWritableAccess && !isPastBookingSource && (
              <>
                &nbsp;To make changes for all, edit through the <a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href='/personnel?tab=bookings'>bookings</a> section.
              </>
            )}
          </>
        );
      case isBookingGroup && cannotViewAllPersonnel && (totalWriteableBookingCount > 0 && (totalWriteableBookingCount !== totalReadableBookingCount)): return (
        <>This booking includes <b className='tw-font-medium'>{otherPersonnelCount} other personnel</b>. You may remind or remove booking only for personnel you can access from within their profile.</>
      )
      case isBookingGroup && cannotViewAllPersonnel && ((totalWriteableBookingCount === 0 || (totalWriteableBookingCount > 0 && totalWriteableBookingCount === totalReadableBookingCount))): return (
        <>This booking includes <b className='tw-font-medium'>{otherPersonnelCount} other personnel</b> you may not have access to. You may remind or remove booking for the following from their profile.</>
      )
      default:
        return null;
    }
  })();

  if (!bookingGroupInfoText) { return null }

  return (
    <div className='tw-bg-cyan-025'>
      <div className='tw-flex tw-p-3'>
        <div>
          <InfoIcon width={18} height={18} className='[&_path]:tw-fill-cyan-800' />
        </div>
        <div>
          <p className='tw-ml-3 tw-m-0 tw-text-m tw-text-cyan-800 tw-font-inter'>
            {bookingGroupInfoText}
          </p>
        </div>
      </div>
    </div>
  );
};

BookingSidePanel.propTypes = {
  currentBooking: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    notes: PropTypes.string,
    personnelIds: PropTypes.array
  }).isRequired,
  domainBooker: resourceShape('simpleUser'),
  domainPersonnel: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(resourceShape('personnel'))]),
  domainELearningCourse: resourceShape('eLearningCourse'),
  domainBookingSource: resourceShape(['booking', 'bookingGroup']),
  domainCourse: resourceShape('course'),
  domainPersonnelFetchMeta: PropTypes.object,
  domainPersonnelMeta: PropTypes.object,
  eLearningAllowance: PropTypes.object,
  onBookingCreateSubmit: PropTypes.func,
  onBookingGroupMemberDelete: PropTypes.func,
  onBookingGroupMemberSelect: PropTypes.func,
  onBookingReminderClick: PropTypes.func.isRequired,
  onBookingUpdateSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onEditBookingClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onRecordTraining: PropTypes.func,
  onRemoveBooking: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  personnel: resourceShape('personnel'),
  removeErrorStyling: PropTypes.func,
  requestError: PropTypes.object,
  showBookingGroupView: PropTypes.bool,
  sidePanelContext: PropTypes.string,
  sidePanelIsOpen: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  endOfDomainPersonnelListRef: PropTypes.func,
  isELearningAllowanceError: PropTypes.bool
}
BookingGroupInfoBanner.propTypes = {
  isBookingGroup: PropTypes.bool,
  isBookingWithBookingGroup: PropTypes.bool,
  totalBookingGroupPersonnelCount: PropTypes.number,
  totalReadableBookingCount: PropTypes.number,
  totalWriteableBookingCount: PropTypes.number,
  isPastBookingSource: PropTypes.bool,
}
