import React from 'react';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources'
import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import moment from 'moment';

export default function DestroyBookingSourceModal({
  isOpen,
  onClose,
  bookingSource,
  course,
  onDestroySubmit
}) {
  const isBookingGroup = bookingSource.type === 'bookingGroup';
  const personnelCount = isBookingGroup && bookingSource.meta.personnelCount;
  const courseHasELearningCourse = course && !!course.relationships.eLearningCourse.data;
  const headerText = isBookingGroup ? `Are you sure you want to remove this ${courseHasELearningCourse ? 'enrolment' : 'booking'} for ${personnelCount} personnel?` : `Are you sure you want to remove the ${courseHasELearningCourse ? 'enrolment' : 'booking'} for ${course && course.attributes.name}?`

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onClose}
      customFooter={
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
            Cancel
          </TextButton>
          <FilledButton color='red' onClick={() => onDestroySubmit({ bookingSource, course })}>
          {isBookingGroup ? `Remove and notify ${personnelCount} personnel` : `Remove ${courseHasELearningCourse ? 'enrolment' : 'booking'}`}
          </FilledButton>
        </div>
      }
    >
      <div className='tw-p-6'>
        <h2 className={`tw-text-l tw-text-grey-900 tw-font-semibold m-0 p-0 ${isBookingGroup ? 'tw-mb-6' : ''}`}>{headerText}</h2>
        {isBookingGroup && (
          <>
            <p className='tw-font-normal tw-text-m tw-text-grey-700 m-0 p-0'>Course: <span className='tw-font-medium tw-text-m tw-text-grey-700 m-0 p-0'>{course.attributes.name}</span></p>
            <p className='tw-font-normal tw-text-m tw-text-grey-700 m-0 p-0'>Training Date: <span className='tw-font-medium tw-text-m tw-text-grey-700 m-0 p-0'>{(moment.parseZone(bookingSource.attributes.date)).format('D MMM YYYY')}</span></p>
          </>
        )}
        <br />
        <p className='tw-font-normal tw-text-m tw-text-grey-700 m-0 p-0'>{isBookingGroup ? `A notification will be sent to ${personnelCount} personnel and their line managers if they have email addresses.` : 'Email notifications will be sent to the personnel and their line manager if they have email addresses.'}</p>
      </div>
    </ModalBox>
  )
}

DestroyBookingSourceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bookingSource: resourceShape(['booking', 'bookingGroup']).isRequired,
  course: resourceShape('course').isRequired,
  onDestroySubmit: PropTypes.func.isRequired
};
