import React from 'react';
import { resourceShape } from 'components/helpers/serialisableResources';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query'
import ConditionalWrapper from 'components/application/ConditionalWrapper';
import Tooltip from 'components/application/Tooltip';
import classNames from 'classnames';

import PersonnelCourseTrainingStatusIndicator from 'components/personnel/PersonnelCourseTrainingStatusIndicator';

export default function SelectedMembersList({
  selectedMemberIDs,
  domainPersonnelCollection,
  onBookingGroupMemberDelete,
  isFetching = false,
  shouldBlockFullRemoval = false
}) {
  const queryClient = useQueryClient();

  const collectedAssignableMembers = queryClient.getQueriesData(['assignableBookees']).map((queryResult) => {
    const [_queryKey, queryData] = queryResult;
    if (!queryData) return null
    return queryData.pages.map((pageData) => pageData.data).flat()
  }).flat().filter(Boolean);

  const currentBookingGroupMembers = selectedMemberIDs.map(personnelId => {
    const persistedPersonnel = domainPersonnelCollection?.find(personnel => personnel.id === personnelId);
    const selectedMember = (!!persistedPersonnel ? domainPersonnelCollection : collectedAssignableMembers).find(assignableMember => assignableMember.id === personnelId);
    if (!selectedMember) return null;

    return {
      id: personnelId,
      externalId: selectedMember.attributes.externalId,
      trainingStatus: selectedMember.attributes.trainingStatus,
      name: `${selectedMember.attributes.firstName} ${selectedMember.attributes.lastName}`
    }
  }).filter(Boolean);

  const isBlockingLastMemberRemoval = shouldBlockFullRemoval && currentBookingGroupMembers.length === 1;

  return (
    <div className='form-container'>
      {isFetching &&
        <div
          key={'loading-pill'}
          className='tw-group/pill tw-rounded-md tw-bg-grey-050 tw-h-10 chip-item-pill w-100 tw-py-2 tw-px-3 tw-mb-2 tw-flex tw-relative tw-justify-between tw-items-center'
        >
          <div className='tw-py-2 tw-flex tw-items-center'>
            <PersonnelCourseTrainingStatusIndicator status={5} />
            <div className='tw-text-s tw-font-medium tw-mr-2 tw-ml-2 tw-text-grey-900'>
              Loading...
            </div>
          </div>
        </div>
      }
      {currentBookingGroupMembers?.map((member) => (
        <div
          key={member.id}
          className='tw-group/pill tw-rounded-md tw-bg-grey-050 tw-h-10 chip-item-pill w-100 tw-py-2 tw-px-3 tw-mb-2 tw-flex tw-relative tw-justify-between tw-items-center'
        >
          <div className='tw-py-2 tw-flex tw-items-center'>
            <PersonnelCourseTrainingStatusIndicator status={member.trainingStatus} />
            <div className='tw-text-s tw-font-medium tw-mr-2 tw-ml-2 tw-text-grey-900'>
              {member.name}
            </div>
            {member.externalId && (
              <div className='tw-text-s tw-font-normal tw-text-grey-500'>
                {member.externalId}
              </div>
            )}
          </div>
          {!(shouldBlockFullRemoval && domainPersonnelCollection?.length === 1) && (
          <ConditionalWrapper
            condition={isBlockingLastMemberRemoval}
            wrapper={children => <Tooltip placement='top' trigger='hover' tooltip={'Requires at least 1 personnel'}>{children}</Tooltip>}
          >
            <div
              data-element-name="member-removal-icon"
              onClick={() => !isBlockingLastMemberRemoval && onBookingGroupMemberDelete(member.id)}
              className={classNames(
                "tw-ml-2 circle--remove before:tw-content-[''] after:tw-content-['']",
                isBlockingLastMemberRemoval ?
                  'before:tw-bg-grey-300 after:tw-bg-grey-300 hover:before:tw-bg-grey-300 hover:after:tw-bg-grey-300'
                  : 'before:tw-bg-grey-900 after:tw-bg-grey-900 hover:before:tw-bg-white hover:after:tw-bg-white'
              )}
            />
          </ConditionalWrapper>
          )}
        </div>
      ))}
    </div>
  )
}

SelectedMembersList.propTypes = {
  selectedMemberIDs: PropTypes.array.isRequired,
  domainPersonnelCollection: PropTypes.arrayOf(resourceShape('personnel')),
  onBookingGroupMemberDelete: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  shouldBlockFullRemoval: PropTypes.bool
}
