import React, { useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';

import RolesShow from 'components/roles/RolesShow';
import RolesForm from 'components/roles/RolesForm';
import FormFooter from 'components/sidepanels/FormFooter';
import ShowFooter from 'components/sidepanels/ShowFooter';
import SidePanel from 'components/application/SidePanel';
import { useTrainingRegisterResources } from 'components/contexts/TrainingRegisterResourceManagementContext';
import { useCurrentActor } from 'components/contexts/CurrentActor';
import TrainingRegisterReportsSelector from 'components/trainingRegisterReports/TrainingRegisterReportsSelector';


export default function RolesSidePanel({
  allowShowFooterContents,
  currentRole,
  assignableCourses,
  requestError,
  sidePanelIsOpen,
  setSidePanelContext,
  sidePanelContext,
  closeSidePanel,
  onRoleInputChange,
  onDeleteClick,
  updateRole,
  createRole,
  removeErrorStyling,
  submitDisabled,
  onRoleOptionChange,
  onCourseCompanyRoleRequiredChange,
  onCourseCompanyRoleDelete
}) {

  const openEditRole = () => { setSidePanelContext('edit') }
  const sidePanelFieldRef = useRef();
  const currentActor = useCurrentActor();
  const trainingRegisterResourceManagementContext = useTrainingRegisterResources();
  const reportsTabViewable = currentActor.isAllowedFeature('training_register') && trainingRegisterResourceManagementContext.hasPersonnelViewableAccess;

  let heading = ''
  switch(sidePanelContext) {
    case 'edit':
      heading = 'Edit Role'
      break
    case 'new':
      heading = 'Add new role'
      break
    case 'show':
      heading = 'Role'
      break
  }

  const headingStyle = sidePanelContext === 'show' ? (
    'tw-text-s tw-text-grey-500 tw-font-medium tw-tracking-wide' ) : (
    'tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'
  )

  const titleHeaderContent =
    <>
      <div className='popup__title popup__title--tertiary'>
        <h1 className={`${headingStyle}`}>{heading}</h1>
      </div>
      {sidePanelContext === 'show' && (
        <div>
          <h2 className={`tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-700 tw-mt-2 ${reportsTabViewable ? 'tw-mb-4' : 'tw-mb-0' }`}>{currentRole.position}</h2>
          {reportsTabViewable && (
            <div>
              <TrainingRegisterReportsSelector filter={{ roleId: { eq: currentRole.id } }} />
            </div>
          )}
        </div>
      )}
      {sidePanelContext === 'edit' && trainingRegisterResourceManagementContext.isMultiDivisionAccount && (
        <p className='tw-border-0 tw-rounded-lg tw-text-amber-800 tw-bg-amber-025 tw-p-3 m-0 m-t-20'>
          Changes could affect divisions and personnel you do not have access to.
        </p>
      )}
    </>


  const showBodyContent = (
    <RolesShow
      currentRole={currentRole}
      assignableCourses={assignableCourses}
      sidePanelIsOpen={sidePanelIsOpen}
    />
  )

  const formBodyContent = (
    <RolesForm
      currentRole={currentRole}
      onInputChange={onRoleInputChange}
      onOptionChange={onRoleOptionChange}
      onCourseCompanyRoleRequiredChange={onCourseCompanyRoleRequiredChange}
      onCourseCompanyRoleDelete={onCourseCompanyRoleDelete}
      requestError={requestError}
      removeErrorStyling={removeErrorStyling}
      submitDisabled={submitDisabled}
      assignableCourses={assignableCourses}
      sidePanelFieldRef={sidePanelFieldRef}
    />
  )

  const newFooterContent = (
    <FormFooter
      text='Add role'
      onCancel={closeSidePanel}
      onSubmit={createRole}
      submitButtonDisabled={submitDisabled}
    />
  )

  const showFooterContent = (
    allowShowFooterContents &&
      <ShowFooter
        type='role'
        resource={currentRole.position}
        deleteDisabled={currentRole.isDeleteProhibited}
        deleteDisabledTooltip='Roles with personnel cannot be deleted'
        onEditClick={openEditRole}
        onDeleteClick={onDeleteClick}
        requestError={requestError}
      />
  )

  const editFooterContent = (
    <FormFooter
      text='Save changes'
      onCancel={closeSidePanel}
      onSubmit={updateRole}
      submitButtonDisabled={submitDisabled}
    />
  )

  const footerContent = function() {
    switch(sidePanelContext) {
      case 'show': return showFooterContent
      case 'edit': return editFooterContent
      case 'new': return newFooterContent
    }
  }

  const onBackdropClick = () => {
    const panelLocked = (['new', 'edit'].includes(sidePanelContext))
    if (!panelLocked) { closeSidePanel() }
  }

  return (
    <SidePanel
      color={'tertiary'}
      isOpen={sidePanelIsOpen}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
      displayClose={sidePanelContext === 'show'}
      onBackdropClick={onBackdropClick}
      closeCallback={closeSidePanel}
      scrollHeader={false}
      headerContent={titleHeaderContent}
      bodyContent={sidePanelContext === 'show' ? showBodyContent : formBodyContent}
      footerContent={footerContent()}
      contentContext={sidePanelContext}
      requestError={requestError}
      submitDisabled={submitDisabled}
    />
  )
}

RolesSidePanel.propTypes = {
  setSidePanelContext: PropTypes.func.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired,
  closeSidePanel: PropTypes.func.isRequired
};
