import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useBreadBoard } from "components/contexts/Toaster";
import { usePersonnel, usePersonnelTraining } from "components/mobileTrainingProfile/hooks/reactQuery";
import { formatResources, filterIncluded } from "components/mobileTrainingProfile/helpers/requests";
import { uniqueTrainingCollectionByCourse } from "components/helpers/resources/training";
import { courseTrainingStatus } from "components/helpers/resources/courses";
import { sortRolesByPrimaryAndPosition } from "components/helpers/personnel";

import Polling from "handshq-app/utilities/polling";

import Tabs from "components/application/Tabs";
import PersonnelRoleTrainingStatusIndicator from "components/training/PersonnelRoleTrainingStatusIndicator";
import TrainingRecordsTab from "components/mobileTrainingProfile/components/homeScreen/TrainingRecordsTab";

export default function TrainingRecordsTabs({
  personnelId,
  onViewCourseDetailsClick
}) {
  const breadBoard = useBreadBoard();

  const {
    data: personnelQuery,
    isLoading: isPersonnelQueryLoading,
    isError: isPersonnelQueryError
  } = usePersonnel({
    personnelId,
    select: personnelQuerySelector,
    onError: breadBoard.addInedibleToast
  });

  const {
    data: personnelTrainingQuery,
    isLoading: isPersonnelTrainingQueryLoading,
    isError: isPersonnelTrainingQueryError
  } = usePersonnelTraining({
    personnelId,
    select: personnelTrainingQuerySelector,
    onError: breadBoard.addInedibleToast
  });

  const [isTrainingEvidenceProcessed, setIsTrainingEvidenceProcessed] = useState(false);

  const evidenceStatusPoller = new Polling({
		interval: 1000,
		url: `/personnel/${personnelId}/training_evidence_status`,
		responseCheck: (response) => { return response.data.processed },
		successCallback: () => { setIsTrainingEvidenceProcessed(true) }
	});

  useEffect(() => {
    evidenceStatusPoller.start()
    return () => { evidenceStatusPoller.end() }
  }, [])

  if (isPersonnelQueryLoading || isPersonnelQueryError || isPersonnelTrainingQueryLoading || isPersonnelTrainingQueryError) return null

  const courses = [
    ...personnelQuery.courses.resources,
    ...personnelTrainingQuery.courses.ids.filter(id => !personnelQuery.courses.ids.includes(id)).map(id => personnelTrainingQuery.courses.indexedById[id])
  ];

  const individuallyRequiredCourses = personnelQuery.userCourses.resources.reduce((acc, uc) => {
    if (uc.attributes.required) acc.push(personnelQuery.courses.indexedById[uc.relationships.course.data.id])
    return acc
  }, []);

  const uniqueTrainingCollection = uniqueTrainingCollectionByCourse({ training: personnelTrainingQuery.training.resources });
  const personnelCompanyRoles = sortRolesByPrimaryAndPosition(personnelQuery.userCompanyRoles.resources, personnelQuery.companyRoles.resources);

  const trainingTabs = personnelQuery.companyRoles.resources.length === 0 ? (
    [
      <TrainingRecordsTab
        key="noCompanyRoles"
        label={null}
        icon={null}
        trainingStatus={courseTrainingStatus(individuallyRequiredCourses, uniqueTrainingCollection)}
        isTrainingEvidenceProcessed={isTrainingEvidenceProcessed}
        personnelId={personnelId}
        companyRole={null}
        courses={courses}
        companyRoles={personnelQuery.companyRoles.resources}
        training={personnelTrainingQuery.training.resources}
        bookings={personnelQuery.bookings.resources}
        userCourses={personnelQuery.userCourses.resources}
        courseCompanyRoles={personnelQuery.courseCompanyRoles.resources}
        userCoursesIndexedByCourseId={personnelQuery.userCourses.indexedByCourseId}
        bookingsIndexedByCourseId={personnelQuery.bookings.indexedByCourseId}
        registrationsIndexedByCourseId={personnelQuery.registrations.indexedByCourseId}
        trainingGroupedByCourseId={personnelTrainingQuery.training.groupedByCourseId}
        attachmentsGroupedByTrainingId={personnelTrainingQuery.attachments.groupedByTrainingId}
        onViewCourseDetailsClick={onViewCourseDetailsClick}
      />
    ]
  ) : (
    personnelCompanyRoles.map((companyRole) => {
      const tabCompanyRoleCourses = companyRole.relationships.courses.data.map(crc => personnelQuery.courses.indexedById[crc.id]);
      const requiredRoleCourses = tabCompanyRoleCourses.reduce((acc, c) => {
        const currentCourseCompanyRole = personnelQuery.courseCompanyRoles.resources.find((ccr) => {
          return ccr.relationships.companyRole.data.id == companyRole.id && ccr.relationships.course.data.id == c.id
        });

        if (currentCourseCompanyRole.attributes.required) {
          acc.push(personnelQuery.courses.indexedById[currentCourseCompanyRole.relationships.course.data.id])
        }

        return acc
      }, []);

      const requiredCourses = [...requiredRoleCourses, ...individuallyRequiredCourses];
      const roleTrainingStatus = courseTrainingStatus(requiredCourses, uniqueTrainingCollection);

      return (
        <TrainingRecordsTab
          key={`companyRoleTrainingTab--${companyRole.id}`}
          label={personnelCompanyRoles.length === 1 ? null : companyRole.attributes.position}
          icon={personnelCompanyRoles.length > 1 ? <div className='flex m-r-6'><PersonnelRoleTrainingStatusIndicator status={roleTrainingStatus} /></div> : undefined}
          trainingStatus={roleTrainingStatus}
          isTrainingEvidenceProcessed={isTrainingEvidenceProcessed}
          personnelId={personnelId}
          companyRole={companyRole}
          courses={courses}
          companyRoles={personnelQuery.companyRoles.resources}
          training={personnelTrainingQuery.training.resources}
          bookings={personnelQuery.bookings.resources}
          userCourses={personnelQuery.userCourses.resources}
          courseCompanyRoles={personnelQuery.courseCompanyRoles.resources}
          userCoursesIndexedByCourseId={personnelQuery.userCourses.indexedByCourseId}
          bookingsIndexedByCourseId={personnelQuery.bookings.indexedByCourseId}
          registrationsIndexedByCourseId={personnelQuery.registrations.indexedByCourseId}
          trainingGroupedByCourseId={personnelTrainingQuery.training.groupedByCourseId}
          attachmentsGroupedByTrainingId={personnelTrainingQuery.attachments.groupedByTrainingId}
          onViewCourseDetailsClick={onViewCourseDetailsClick}
        />
      )
    })
  );

  return trainingTabs.length > 1 ? (
    <Tabs
      modifiers={["truncated"]}
      navClassName="tw-overflow-y-hidden tw-whitespace-nowrap [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] tw-h-[22px] tw-my-3"
    >
      {trainingTabs}
    </Tabs>
  ) : trainingTabs
}

TrainingRecordsTabs.propTypes = {
  personnelId: PropTypes.string.isRequired,
  onViewCourseDetailsClick: PropTypes.func.isRequired
}

const personnelQuerySelector = (response) => {
  const included = response.included;

  return {
    courses: formatResources({ resources: filterIncluded({ included, type: "course" }), options: { resources: true, ids: true, indexedById: { path: "id" } } }),
    companyRoles: formatResources({ resources: filterIncluded({ included, type: "companyRole" }), options: { resources: true } }),
    bookings: formatResources({ resources: filterIncluded({ included, type: "booking" }), options: { resources: true, indexedByCourseId: { path: "relationships.course.data.id" } } }),
    registrations: formatResources({ resources: filterIncluded({ included, type: "registration" }), options: { indexedByCourseId: { path: "relationships.course.data.id" } } }),
    userCourses: formatResources({ resources: filterIncluded({ included, type: "userCourse" }), options: { resources: true, indexedByCourseId: { path: "relationships.course.data.id" } } }),
    userCompanyRoles: formatResources({ resources: filterIncluded({ included, type: "userCompanyRole" }), options: { resources: true } }),
    courseCompanyRoles: formatResources({ resources: filterIncluded({ included, type: "courseCompanyRole" }), options: { resources: true } })
  }
}

const personnelTrainingQuerySelector = (response) => {
  const data = response.data;
  const included = response.included;

  return {
    training: formatResources({ resources: data, options: { resources: true, groupedByCourseId: { path: "relationships.course.data.id" } } }),
    courses: formatResources({ resources: filterIncluded({ included, type: "course" }), options: { ids: true, indexedById: { path: "id" } } }),
    attachments: formatResources({ resources: filterIncluded({ included, type: "attachment" }), options: { groupedByTrainingId: { path: "relationships.attachable.data.id" } } })
  }
}
