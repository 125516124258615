import React from 'react';
import PropTypes from 'prop-types';
import NoResultsIcon from '-!svg-react-loader?name=NoResultsIcon!icons/noresults-xl.svg';
import { useTabContext } from 'components/contexts/TabContext';

export default function BlankBookingSearchResult({ onBookingTabSelection }) {
  const tabContext = useTabContext()

  const [firstNonSelectedTabName, secondNonSelectedTabName] = tabContext.tabNames.filter(tabName => tabContext.selectedTabName !== tabName);

  const isSharedSuffix = secondNonSelectedTabName ? firstNonSelectedTabName.split(' ').pop() == secondNonSelectedTabName.split(' ').pop() : false

  return (
    <div className='section section--notice tw-border-grey-100 tw-bg-white tw-rounded-tl-none tw-rounded-tr-none'>
      <NoResultsIcon width={64} height={64} className='[&_path]:tw-fill-grey-300' />
      <h2 className='section__header tw-text-grey-900 tw-text-l tw-font-semibold'>
        {['Upcoming bookings', 'Past bookings'].includes(tabContext.selectedTabName) ? 'No bookings found' : 'No enrolments found'}
      </h2>
      <p className='section__content tw-mb-2 tw-mt-8'>
        <span>
          Try searching again with a different spelling or search in&nbsp;
          <a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href='#0' onClick={() => onBookingTabSelection(firstNonSelectedTabName)}>
            {presentableTabDescription((isSharedSuffix ? firstNonSelectedTabName.split(' ')[0] : firstNonSelectedTabName))}
          </a>
          {secondNonSelectedTabName && (
            <>
              &nbsp;or&nbsp;
              <a className='app-link tw-align-baseline tw-text-blue-500 hover:tw-text-blue-300 active:tw-text-blue-300' href='#0' onClick={() => onBookingTabSelection(secondNonSelectedTabName)}>
                {presentableTabDescription(secondNonSelectedTabName)}
              </a>
            </>
          )}
        </span>
      </p>
    </div>
  )
}

BlankBookingSearchResult.propTypes = {
  onBookingTabSelection: PropTypes.func.isRequired
}

function presentableTabDescription(tabName) { return tabName == 'eLearning' ? tabName : tabName.toLowerCase() }
