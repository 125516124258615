import React, { useState, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { resourceShape } from "components/helpers/serialisableResources";
import { indexResources } from "components/mobileTrainingProfile/helpers/requests";
import useOutsideClick from "components/hooks/useOutsideClick";
import useModal from "components/hooks/useModal";

import DocumentGeneratorService from "components/helpers/DocumentGeneratorService";

import AddNotedIcon from '-!svg-react-loader?name=VisibleIcon!icons/add-notes.svg';
import DownloadIcon from "-!svg-react-loader?name=DownloadIcon!icons/ic-download.svg";

import Modal from "components/mobileTrainingProfile/components/Modal";
import TrainingRecordsTabStatus from "components/mobileTrainingProfile/components/homeScreen/TrainingRecordsTabStatus";
import RoleTrainingTable from "components/mobileTrainingProfile/components/homeScreen/RoleTrainingTable";
import AdditionalTrainingTable from "components/mobileTrainingProfile/components/homeScreen/AdditionalTrainingTable";
import DownloadReportModalContent from "components/mobileTrainingProfile/components/homeScreen/DownloadReportModalContent";

export default function TrainingRecordsTab({
  trainingStatus,
  isTrainingEvidenceProcessed,
  personnelId,
  companyRole,
  courses,
  companyRoles,
  training,
  bookings,
  userCourses,
  courseCompanyRoles,
  userCoursesIndexedByCourseId,
  bookingsIndexedByCourseId,
  registrationsIndexedByCourseId,
  trainingGroupedByCourseId,
  attachmentsGroupedByTrainingId,
  onViewCourseDetailsClick
}) {
  const reportMenuRef = useRef();
  const generator = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, _setIsModallOpen, openModal, closeModal] = useModal(false);
  const [isReportError, setIsReportError] = useState(false);

  const tabCompanyRoleCourseIds = companyRole ? companyRole.relationships.courses.data.map(c => c.id) : [];
  const tabCompanyRoleRequiredCourses = tabCompanyRoleCourseIds.map((id) => courses.find(course => course.id === id));
  const tabCompanyRoleCourseCompanyRoles = courseCompanyRoles.filter(ccr => ccr.relationships.companyRole.data.id == companyRole.id);

  const allUserCourseCourseIds = userCourses.map(uc => uc.relationships.course.data.id);
  const allCompanyRoleCourseIds = companyRoles.reduce((acc, cr) => {
    const ids = cr.relationships.courses.data.map(c => c.id);
    return [...acc, ...ids]
  }, []);

  const nonAssociatedTraining = training.filter(t => ![...allUserCourseCourseIds, ...allCompanyRoleCourseIds].includes(t.relationships.course.data.id));
  const nonAssociatedTrainingCourseIds = nonAssociatedTraining.map(t => t.relationships.course.data.id);
  const bookingCourseIds = bookings.map((b) => b.relationships.course.data.id);

  const additionalCourses = [...new Set([...allUserCourseCourseIds, ...allCompanyRoleCourseIds, ...nonAssociatedTrainingCourseIds, ...bookingCourseIds])].filter((id) => !tabCompanyRoleCourseIds.includes(id)).map((id) => courses.find(course => course.id === id));
  const roleHasRequiredCourses = tabCompanyRoleRequiredCourses.length > 0;

  const courseCompanyRolesIndexedByCourseId = indexResources({ resources: tabCompanyRoleCourseCompanyRoles, path: "relationships.course.data.id" });

  const successCallback = (docUrl) => {
    closeModal()
    window.location.href = docUrl;
  };

  const failureCallback = () => {
    setIsReportError(true)
  }

  const generateReport = (options = {}) => {
    setIsReportError(false)
    generator.current = new DocumentGeneratorService({
      generateUrl: `/personnel/${personnelId}/training_report_pdfs`,
      generateParams: { ...options, personnel_id: personnelId, company_role_id: companyRole?.id },
      successCallback,
      failureCallback
    })
    generator.current.start()
  }

  const handleDownloadReportClick = (options) => {
    generator.current?.end()
    generateReport(options)
    openModal()
  }

  useOutsideClick(() => {
    setIsDropdownOpen(false)
  }, reportMenuRef)

  const toggleMenu = () => setIsDropdownOpen(prevState => !prevState)

  if (tabCompanyRoleRequiredCourses.length === 0 && additionalCourses.length === 0) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-flex-col tw-border-1 tw-border-solid tw-rounded-lg tw-border-grey-100 tw-bg-white tw-px-4 tw-h-90">
        <AddNotedIcon className="-tw-mt-3" width={92} height={92} />
        <div className="tw-text-center tw-text-l tw-font-semibold tw-text-grey-900 tw-mt-3">No training added</div>
        <div className="tw-text-center tw-text-grey-700 tw-mt-4">Contact your manager to request training to be added.</div>
      </div>
    )
  }

  return (
    <>
      <div className={classNames("tw-flex tw-items-center tw-justify-between tw-border-solid tw-border-1 tw-rounded-tl-lg tw-rounded-tr-lg tw-border-grey-100 tw-bg-white tw-px-4 tw-h-16", !roleHasRequiredCourses && "tw-rounded-bl-lg tw-rounded-br-lg")}>
        <TrainingRecordsTabStatus trainingStatus={trainingStatus} />
        {isTrainingEvidenceProcessed && (
          <div ref={reportMenuRef} className="tw-relative">
            <div data-element-name="report-menu-toggle" className={classNames("tw-flex-none tw-flex tw-items-center tw-justify-center tw-border-solid tw-border-1 hover:tw-border-grey-100 tw-rounded-lg tw-cursor-pointer tw-w-8 tw-h-8", isDropdownOpen ? "tw-border-grey-100" : "tw-border-transparent")} onClick={toggleMenu}>
              <div className="tw-flex tw-justify-between tw-w-4 tw-h-1">
                <div className="tw-bg-grey-700 tw-rounded-full tw-w-1 tw-h-1"></div>
                <div className="tw-bg-grey-700 tw-rounded-full tw-w-1 tw-h-1"></div>
                <div className="tw-bg-grey-700 tw-rounded-full tw-w-1 tw-h-1"></div>
              </div>
            </div>
            {isDropdownOpen && (
              <div data-element-name="report-menu-dropdown" className="tw-absolute tw-right-0 tw-top-9 tw-rounded-lg tw-border-1 tw-border-solid tw-bg-white tw-border-grey-100 tw-shadow-lg tw-w-72 tw-z-[9999]">
                <div className="tw-flex tw-flex-col tw-w-72" onClick={toggleMenu}>
                  <div className="tw-flex tw-items-center tw-cursor-pointer tw-font-medium tw-text-grey-900 tw-border-grey-100 hover:tw-bg-blue-025 tw-px-3 tw-h-11" onClick={() => handleDownloadReportClick({ evidence: true })}>
                    <DownloadIcon className="[&_polygon]:tw-fill-grey-900 tw-mr-1" width={24} height={24}/>
                    Download report with evidence
                  </div>
                  <div className="tw-flex tw-items-center tw-cursor-pointer tw-border-0 tw-border-t-1 tw-border-solid tw-font-medium tw-text-grey-900 tw-border-grey-100 hover:tw-bg-blue-025 tw-px-3 tw-h-11" onClick={() => handleDownloadReportClick({ evidence: false })}>
                    <DownloadIcon className="[&_polygon]:tw-fill-grey-900 tw-mr-1" width={24} height={24}/>
                    Download report without evidence
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {roleHasRequiredCourses && (
        <RoleTrainingTable
          courses={tabCompanyRoleRequiredCourses}
          userCoursesIndexedByCourseId={userCoursesIndexedByCourseId}
          courseCompanyRolesIndexedByCourseId={courseCompanyRolesIndexedByCourseId}
          bookingsIndexedByCourseId={bookingsIndexedByCourseId}
          registrationsIndexedByCourseId={registrationsIndexedByCourseId}
          trainingGroupedByCourseId={trainingGroupedByCourseId}
          attachmentsGroupedByTrainingId={attachmentsGroupedByTrainingId}
          onViewCourseDetailsClick={onViewCourseDetailsClick}
        />
      )}
      {additionalCourses.length > 0 && (
        <>
          <div className={classNames("tw-flex tw-items-center tw-justify-between tw-border-solid tw-border-1 tw-rounded-tl-lg tw-rounded-tr-lg tw-border-grey-100 tw-bg-white tw-px-4 tw-h-16 tw-mt-3", additionalCourses === 0 && "tw-rounded-bl-lg tw-rounded-br-lg")}>
            <span className="tw-text-l tw-font-semibold">{roleHasRequiredCourses ? "Additional training" : "Training"}</span>
          </div>
          <AdditionalTrainingTable
            courses={additionalCourses}
            userCoursesIndexedByCourseId={userCoursesIndexedByCourseId}
            bookingsIndexedByCourseId={bookingsIndexedByCourseId}
            registrationsIndexedByCourseId={registrationsIndexedByCourseId}
            trainingGroupedByCourseId={trainingGroupedByCourseId}
            attachmentsGroupedByTrainingId={attachmentsGroupedByTrainingId}
            onViewCourseDetailsClick={onViewCourseDetailsClick}
          />
        </>
      )}
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        <DownloadReportModalContent
          isReportError={isReportError}
          closeModal={closeModal}
        />
      </Modal>
    </>
  )
}

TrainingRecordsTab.propTypes = {
  trainingStatus: PropTypes.string.isRequired,
  isTrainingEvidenceProcessed: PropTypes.bool.isRequired,
  personnelId: PropTypes.string.isRequired,
  companyRole: resourceShape('companyRole'),
  courses: PropTypes.arrayOf(resourceShape('course')).isRequired,
  companyRoles: PropTypes.arrayOf(resourceShape('companyRole')).isRequired,
  training: PropTypes.arrayOf(resourceShape('training')).isRequired,
  bookings: PropTypes.arrayOf(resourceShape('booking')).isRequired,
  userCourses: PropTypes.arrayOf(resourceShape('userCourse')).isRequired,
  courseCompanyRoles: PropTypes.arrayOf(resourceShape('courseCompanyRole')).isRequired,
  userCoursesIndexedByCourseId: PropTypes.object.isRequired,
  bookingsIndexedByCourseId: PropTypes.object.isRequired,
  registrationsIndexedByCourseId: PropTypes.object.isRequired,
  trainingGroupedByCourseId: PropTypes.object.isRequired,
  attachmentsGroupedByTrainingId: PropTypes.object.isRequired,
  onViewCourseDetailsClick: PropTypes.func.isRequired
}
