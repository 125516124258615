import DOMPurify from 'dompurify';
import { setupRichTextEditor } from '../../shared/rich-text-editor';
import HandshqApp from '../handshq-app';

const textFieldInvalidClass = 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';

HandshqApp.EditableTextarea = function(options) {
  if (this instanceof HandshqApp.EditableTextarea) {
    const _this = this;
    let autosave;
    let clearFlag;

    this.node = $(options['node']);
    this.attribute = _this.node.attr('data-attribute');
    this.richText = _this.node.attr('data-rich-text');
    this.richTextActions = _this.node.find('.editable-textarea__rich-text-actions');
    this.richTextAdditionalContentStyle = _this.node.attr('data-rich-text-additional-content-style');
    this.richTextAutosave = _this.node.attr('data-rich-text-autosave');
    this.richTextEditor = undefined;
    this.richTextEditorInitialised = false;
    this.richTextFlag = $(_this.node.attr('data-rich-text-flag-selector'));
    this.richTextInlineSelectorId = _this.node.attr('data-rich-text-inline-selector-id');
    this.richTextPlaceholder = _this.node.attr('data-rich-text-placeholder');
    this.richTextToolbarOptions = _this.node.attr('data-rich-text-toolbar-options');
    this.spinner = _this.node.find('.editable-textarea__rich-text-spinner');
    this.textWrapper = _this.node.find('.editable-textarea__text-wrapper');
    this.textEditor = _this.node.find('.editable-textarea__text-editor');
    this.textField = _this.node.find("textarea, input[type='text']").eq(0);
    this.cancelButton = _this.node.find('.editable-textarea__cancel');
    this.submitButton = _this.node.find('.editable-textarea__submit');
    this.regularCallbacks = options['regularCallbacks'];
    this.successCallback = options['successCallback'];
    this.failureCallback = options['failureCallback'];
    this.cancelCallback = options['cancelCallback'];
    this.textFieldFocusCallback = options['textFieldFocusCallback'];
    this.textFieldFocusTrigger = options['textFieldFocusTrigger'] || _this.textWrapper

    this.setup = function() {
      _this.textChangeEvent()
      _this.triggerTextFieldValueCheck()
      _this.textFieldFocusClick()
      _this.cancelClick()
      _this.submitForm()

      HandshqApp.domHelper.clickStopPropagation(_this.textEditor)

      if (_this.richText && !_this.richTextInlineSelectorId && !_this.richTextEditorInitialised) {
        _this.initializeRichTextEditor({ height: 292, selector: `#${_this.textField.attr('id')}` })
      }
    };

    this.textChangeEvent = function() {
      if (validatableTextField()) { _this.textField.on('change paste keyup', function() { _this.textFieldValueCheck(this) }) }
    }

    this.triggerTextFieldValueCheck = function() {
      if (validatableTextField()) { _this.textFieldValueCheck(_this.textField[0]) }
    }

    this.textFieldValueCheck = function(field) {
      setTimeout(function () {
        _this.submitButton.attr('data-disabled', field.value.length < 1)
      }, 100)
    }

    this.textFieldFocusClick = function() {
      _this.textFieldFocusTrigger.on('click', function(event) {
        HandshqApp.domHelper.toggleElements(_this.textWrapper, _this.textEditor)

        if (_this.textFieldFocusCallback) _this.textFieldFocusCallback(_this)

        if (_this.richText && _this.richTextInlineSelectorId && !_this.richTextEditorInitialised) {
          _this.initializeRichTextEditor({
            autoFocus: _this.richTextInlineSelectorId,
            inline: true,
            selector: `#${_this.richTextInlineSelectorId}`
          })
        } else {
          _this.textField.focus()
        }

        toggleFullWidthForEditor(true)
        event.stopPropagation()
      })
    }

    this.initializeRichTextEditor = function({ autoFocus, height, inline, selector }) {
      setupRichTextEditor({
        additionalContentStyleString: _this.richTextAdditionalContentStyle,
        autoFocus,
        height,
        inline,
        onInit: richTextInitInstanceCallback.bind(this),
        onSetup: richTextSetupCallback.bind(this),
        placeholder: _this.richTextPlaceholder,
        selector,
        toolbar: _this.richTextToolbarOptions
      })

      _this.richTextEditorInitialised = true;
    }

    this.cancelClick = function() {
      _this.cancelButton.on('click', function(event) {
        fireCallbacksAndChecks({
          regularCallback: 'regularCancelCallback',
          definedCallback: 'cancelCallback'
        })

        toggleFullWidthForEditor(false)
        event.stopPropagation()
      })
    }

    this.submitForm = function() {
      _this.submitButton.on('click', function(event) {
        _this.submitButton.attr('data-disabled', true)
        _this.saveRichTextEditor()

        event.preventDefault()

        asyncRequest({
          success: function(response) {
            clearErrors()
            fireCallbacksAndChecks({ response, regularCallback: 'regularSuccessCallback', definedCallback: 'successCallback' })
            _this.submitButton.attr('data-disabled', false)
            toggleFullWidthForEditor(false)
          },
          error: function(response) {
            displayErrors(response)
            fireCallbacksAndChecks({ response, regularCallback: 'regularFailureCallback', definedCallback: 'failureCallback' })
            _this.submitButton.attr('data-disabled', false)
            toggleFullWidthForEditor(false)
          }
        })
      })
    }

    this.saveRichTextEditor = function() { if (_this.richTextEditor) { _this.richTextEditor.save() } }

    this.regularSuccessCallback = function(response) {
      const sanitizedHtml = DOMPurify.sanitize(response['data']['attributes'][_this.attribute])
      _this.textWrapper.find('span').removeClass().html(sanitizedHtml)
      _this.submitButton.attr('data-disabled', false)
      HandshqApp.domHelper.toggleElements(_this.textEditor, _this.textWrapper)
    }

    this.regularFailureCallback = function(_response) {}

    this.regularCancelCallback = function() { HandshqApp.domHelper.toggleElements(_this.textEditor, _this.textWrapper) }

    this.reset = function() {
      _this.submitButton.attr('data-disabled', false)

      if (_this.richText && _this.richTextInlineSelectorId) {
        _this.richTextEditor.setContent('')
      } else {
        _this.textField.val('')
        _this.triggerTextFieldValueCheck()
      }
    }

    // Needed to expand the editor to full width when editing
    // and then back to normal width when done editing
    function toggleFullWidthForEditor(show = true) {
      _this.textEditor.parent().toggleClass('tw-w-full', show)
    }

    function asyncRequest(options) {
      const form = _this.submitButton.closest('form');

      $.ajax({
        type: form.attr('method'),
        data: form.serialize(),
        dataType: 'json',
        url: form.attr('action'),
        success: options['success'],
        error: options['error']
      })
    }

    function clearErrors() {
      if ($('.js__errors').length) {
        HandshqApp.jsErrors.hideErrors()
        _this.textField.removeClass(textFieldInvalidClass)
      }
    }

    function displayErrors(response) {
      const errors = (response.responseJSON || {}).errors;

      if ($('.js__errors').length && typeof errors !== 'undefined') {
        HandshqApp.jsErrors.displayErrors(errors)
        _this.textField.addClass(textFieldInvalidClass)
      }
    }

    function fireCallbacksAndChecks(args) {
      if (_this.regularCallbacks) { _this[args['regularCallback']](args['response']) }
      if (_this[args['definedCallback']]) { _this[args['definedCallback']](_this, args['response']) }

      _this.triggerTextFieldValueCheck()
    }

    function validatableTextField() { return (!_this.richText && _this.attribute !== 'caption') }

    function richTextSetupCallback(editor) {
      editor.on('init', function() {
        _this.spinner.fadeOut('fast', function() {
          _this.textEditor.fadeIn('fast')
        })
      })
    }

    function richTextInitInstanceCallback(editor) {
      editor.on('change formatapply formatremove input paste redo undo', function() {
        _this.richTextEditor = editor;

        if (_this.richTextInlineSelectorId) {
          const content = _this.richTextEditor.getContent()
          _this.textField.html(content)
        }

        if (_this.richTextAutosave) {
          _this.saveRichTextEditor()
          clearTimeout(autosave)

          if (_this.richTextFlag) {
            clearTimeout(clearFlag)
            _this.richTextFlag.html(_this.richTextFlag.attr('data-rich-text-flag-editing'))
          }

          autosave = setTimeout(function() {
            asyncRequest({
              success: function() {
                if (_this.richTextFlag) {
                  _this.richTextFlag.html(_this.richTextFlag.attr('data-rich-text-flag-persisted'))
                  clearFlag = setTimeout(function() { _this.richTextFlag.empty() }, 3000);
                }
              },
              error: function() {
                HandshqApp.GlobalToastRackAPI.addInedibleToast()
                _this.richTextFlag.empty()
              }
            })
          }, 500)
        } else {
          _this.richTextActions.show()
        }
      })
    }
  } else {
    throw new Error('HandshqApp.EditableTextarea invoked without new')
  }
}

HandshqApp.editableTextareaFactory = {
  regularTextAreas: function(node) {
    const _this = this;

    node.find('.editable-textarea').each(function() {
      _this.regularTextArea.call(this)
    })
  },

  customTextAreas: function(node, customTextarea) {
    node.find('.editable-textarea').each(function() {
      customTextarea.call(this)
    })
  },

  regularTextArea: function() {
    new HandshqApp.EditableTextarea({
      node: this,
      regularCallbacks: true
    }).setup()
  }
}
