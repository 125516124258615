import React from 'react';
import PropTypes from 'prop-types';

import { personDisplayName } from 'components/helpers/users';

import ModalBox from 'components/application/ModalBox';

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function SendInvitationModal(props) {
  const {
    isOpen,
    onClose,
    personnel,
    user,
    onSendConfirmationClick
  } = props;

  if (!personnel || !user) return null

  const wasUserNotInvited = user.attributes.invitationStatus === 'none';

  const customFooter = (
    <div className='modalbox__footer-actions modalbox__footer-actions--right'>
      <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
        Cancel
      </TextButton>
      <FilledButton color='red' onClick={onSendConfirmationClick}>
        {wasUserNotInvited ? 'Send' : 'Resend'}
      </FilledButton>
    </div>
  );

  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onClose}
      customFooter={customFooter}
    >
      <>
        <div className='modalbox-header tw-border-grey-100'>
          <h2 className='modalbox-header__title truncated-text-container tw-text-l tw-text-grey-900 tw-font-semibold'>
            {wasUserNotInvited ? 'Send' : 'Resend'} the invitation to {personDisplayName(personnel.attributes)}?
          </h2>
        </div>
        <div className='modalbox-body'>
          An email will be sent to {personnel.attributes.email} with a link to accept the invite. Once accepted, they can view their profile.{wasUserNotInvited ? '' : ' The old link will no longer be valid.'}
        </div>
      </>
    </ModalBox>
  )
}

SendInvitationModal.propsTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  personnel: PropTypes.object.isRequired,
  onSendConfirmationClick: PropTypes.func.isRequired
}
