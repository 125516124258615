import React from 'react';
import ModalBox from 'components/application/ModalBox';
import PropTypes from 'prop-types';
import { resourceShape } from 'components/helpers/serialisableResources'

import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';
import moment from 'moment';
import NotificationsIcon from '-!svg-react-loader?name=notificationsIcon!icons/notifications.svg';

export default function BookingSourceReminderModal({
  isOpen,
  onClose,
  bookingSource,
  course,
  onSubmit,
  personnel,
  domainPersonnelMeta
}) {
  return (
    <ModalBox
      mode='flexible'
      isOpen={isOpen}
      onClose={onClose}
      customFooter={
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton size='md' color='blue' className='tw-mr-3' onClick={onClose}>
            Cancel
          </TextButton>
          <FilledButton color='mint' onClick={() => onSubmit({ bookingSource })}>
            {bookingSource.type === 'bookingGroup' ? (`Send reminder to ${domainPersonnelMeta?.notifiableSetCount} personnel`) : 'Send reminder'}
          </FilledButton>
        </div>
      }
    >
      <div className='p-32'>
        {bookingSource.type === 'booking' && (
          <>
            <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold m-0 p-0 tw-mb-6'>Are you sure you want to send a reminder to {personnel.attributes.firstName} to take {course.attributes.name}?</h2>
            <p className='tw-font-normal tw-text-m tw-text-grey-700 m-0 p-0'>An email notification will be sent to personnel with email addresses.</p>
          </>

        )}

        {bookingSource.type === 'bookingGroup' && (
          <>
            <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold m-0 p-0 tw-mb-6'>Are you sure you want to send a reminder?</h2>
            <p className='tw-font-normal tw-text-m tw-text-grey-700 m-0 p-0'>Course: <span className='tw-font-medium tw-text-m tw-text-grey-700 m-0 p-0'>{course.attributes.name}</span></p>
            <p className='tw-font-normal tw-text-m tw-text-grey-700 m-0 p-0'>Training Date: <span className='tw-font-medium tw-text-m tw-text-grey-700 m-0 p-0'>{(moment.parseZone(bookingSource.attributes.date)).format('D MMM YYYY')}</span></p>
            <br />
            <p className='tw-font-normal tw-text-m tw-text-grey-700 m-0 p-0'>A notification will be sent to personnel with email addresses</p>
            {bookingSource.attributes.notifiedAt && (
              <div className='tw-bg-cyan-025 tw-mt-6'>
                <div className='tw-flex tw-p-3 tw-items-center'>
                  <div className='tw-relative tw-top-0.5'>
                    <NotificationsIcon width={12} height={16} className='[&_path]:tw-fill-cyan-800' />
                  </div>
                  <div className='tw-ml-4'>
                    <p className='tw-font-normal tw-text-cyan-800 tw-font-inter tw-p-0 tw-m-0'>Last reminded <span className='tw-font-medium'>{moment.parseZone(bookingSource.attributes.notifiedAt).fromNow()}</span></p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </ModalBox>
  )
}

BookingSourceReminderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bookingSource: resourceShape(['booking', 'bookingGroup']).isRequired,
  course: resourceShape('course').isRequired,
  personnel: resourceShape('personnel'),
  domainPersonnelMeta: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};
