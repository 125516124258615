import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { learningMethodMap } from 'components/helpers/resources/courses';
import { personDisplayName } from 'components/helpers/users';
import { resourceShape } from 'components/helpers/serialisableResources'
import { useTabContext } from 'components/contexts/TabContext';
import ActionsPill from 'components/application/ActionsPill';
import AutoEnrolIcon from '-!svg-react-loader?name=AutoEnrolIcon!icons/auto-enrol.svg';
import CalendarIcon from '-!svg-react-loader?name=CalendarIcon!icons/calendar.svg';
import TableCell from 'components/application/TableCell';

export default function BookingsRow({ bookingConnection, bookingSource, course, onRowClick, personnel }) {
  const tabContext = useTabContext();

  const bookingDate = moment.parseZone(bookingSource.attributes.date);
  const isDateReached = moment().isAfter(bookingDate, 'day');

  const courseInfo = {
    'Past bookings': learningMethodMap[course.attributes.learningMethod],
    'Upcoming bookings': '',
    'eLearning': course.attributes.provider
  }[tabContext.selectedTabName];

  return (
    <tr className='hover:tw-bg-grey-025' onClick={() => onRowClick(bookingConnection.id)}>
      <TableCell>
        <div className='tw-text-m truncated-text-container'>{course.attributes.name}</div>
      </TableCell>
      <TableCell>
        {personnel && bookingSource && bookingConnection && (
          <div className='tw-text-m truncated-text-container'>{personnelNamesForRow(personnel, bookingSource, bookingConnection)}</div>
        )}
      </TableCell>
      <TableCell>
        <div className='tw-text-m truncated-text-container'>{courseInfo}</div>
      </TableCell>
      <TableCell>
        {tabContext.selectedTabName === 'eLearning' ? (
          <ActionsPill
            color='green'
            displayText={`${!!(bookingSource.relationships.activeRegistration?.data?.id) ? 'In progress' : `Enrolled ${(moment.parseZone(bookingSource.attributes.createdAt)).format('D MMM YYYY')}`}`}
            icon={<AutoEnrolIcon height={16} width={16} />}
          />
        ) : (
          <ActionsPill
            color={isDateReached ? 'cyan' : 'green'}
            displayText={`Booked ${bookingDate.format('D MMM YYYY')}`}
            icon={<CalendarIcon height={16} width={16} />}
          />
        )}
      </TableCell>
    </tr>
  )
}

export function personnelNamesForRow(personnel, bookingSource, bookingConnection) {
  const personnelArray = personnel.map(personnel => personDisplayName(personnel.attributes))

  const totalPersonnelCount = bookingConnection.relationships.source.data.type === 'bookingGroup' ?  bookingSource.meta.personnelCount : 1
  const othersCounts = totalPersonnelCount - personnel.length;

  let personnelNames;
  if (othersCounts >= 1) {
    personnelNames = `${personnelArray.join(', ')} +${pluralize('other', othersCounts, true)}`;
  } else {
    personnelNames = personnelArray.join(', ');
  }

  return personnelNames
}

BookingsRow.propTypes = {
  bookingConnection: resourceShape('bookingConnection'),
  bookingSource: PropTypes.oneOfType([resourceShape('booking'), resourceShape('bookingGroup')]),
  course: resourceShape('course'),
  onRowClick: PropTypes.func.isRequired,
  personnel: PropTypes.arrayOf(resourceShape('personnel'))
};
