import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import voca from 'voca';

import Tooltip from 'components/application/Tooltip';
import CircleQuestion from 'components/application/CircleQuestion';

export default function TextArea({
  autoFocus,
  value,
  fieldError,
  labelVisible,
  isRequired,
  isOptional,
  name,
  tooltip,
  tooltipClassName,
  label,
  additionalText,
  onChange,
  onFocus,
  placeholderText,
  autoHeight,
  padded,
  smallFontSize,
  resizeVertical
}) {
  // this should be a helper
  const fieldRef = useCallback(node => { autoFocus && node !== null && focusNode(node) }, []);
  const focusNode = (node) => {
    node.focus()
    node.setSelectionRange(value.length, value.length)
    node.scrollTop = node.scrollHeight;
  }

  const isInvalid = !!fieldError?.fieldHighlighted;

  const textareaClassName = (() => {
    switch (true) {
      case (isInvalid):
        return 'tw-border-red-600 hover:tw-border-red-600 focus-within:tw-border-red-600 hover:focus-within:tw-border-red-600';
      default:
        return 'tw-border-grey-300 hover:tw-border-grey-400 focus-within:tw-border-blue-300 hover:focus-within:tw-border-blue-300 placeholder:tw-text-grey-300';
    }
  })();

  return (
    <div className='text-field'>
      <div className='form-group'>
        <label className={`field__label tw-font-medium${labelVisible ? '' : ' hide'}${isRequired || isOptional ? ` after:tw-absolute after:tw-text-grey-500 after:tw-right-0 after:tw-font-normal` : ''}${isRequired ? " after:tw-content-['Required']" : ''}${isOptional ? " after:tw-content-['Optional']" : ''}${isInvalid ? " after:tw-text-red-600" : ''}`} htmlFor={name}>
          {label}
          {tooltip &&
            <Tooltip placement='top' trigger='hover' tooltip={tooltip} className={tooltipClassName}>
              <CircleQuestion />
            </Tooltip>
          }
        </label>
        {additionalText && (
          <p className='tw-text-grey-500 tw-text-s tw-mb-2'>
            {additionalText}
          </p>
        )}
        <div className='flex flex--vertically-centered'>
          <textarea
            id={voca.snakeCase(name)}
            name={name}
            className={`field__input field__input--textarea tw-font-inter tw-text-m tw-font-normal tw-tracking-auto ${textareaClassName}${autoHeight ? ' field__input--textarea-growable' : ''}${padded ? ' field__input--textarea-padded' : ''}${smallFontSize ? ' field__input--textarea-small-font-size' : ''}${resizeVertical ? ' field__input--resize-vertical' : ''}`}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            ref={fieldRef}
            placeholder={placeholderText}
          />
        </div>
      </div>
    </div>
  )
}

TextArea.defaultProps = {
  labelVisible: true,
  autoHeight: false,
  padded: false,
  tooltip: null,
  resizeVertical: false,
  isRequired: false,
  isOptional: false,
  placeholder: null,
  onFocus: () => { },
}

TextArea.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  fieldError: PropTypes.object,
  labelVisible: PropTypes.bool,
  isRequired: PropTypes.bool,
  isOptional: PropTypes.bool,
  name: PropTypes.string,
  tooltip: PropTypes.node,
  tooltipClassName: PropTypes.string,
  label: PropTypes.node,
  additionalText: PropTypes.node,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholderText: PropTypes.node,
  autoHeight: PropTypes.bool,
  padded: PropTypes.bool,
  smallFontSize: PropTypes.bool,
  resizeVertical: PropTypes.bool
}
