import currency from 'currency.js';

const thousandsRegex = /\B(?=(\d{3})+(?!\d))/g;

const currencyCodes = {
  GBP: { separator: ".", delimiter: ",", unit: "£", label: "GBP (£)" },
  EUR: { separator: ",", delimiter: ".", unit: "€", label: "Euro (€)" },
  AUD: { separator: ".", delimiter: ",", unit: "$", label: "Australian Dollar ($)" },
  USD: { separator: ".", delimiter: ",", unit: "$", label: "United States Dollar ($)" }
};

function currencyNumberToCurrencyString({ number, currencyCode = "GBP" }) {
  if (!number) return ""
  return (number / 100).toFixed(2).toString().replace(".", currencyCodes[currencyCode].separator)
}

function currencyStringToCurrencyNumber({ string, currencyCode = "GBP" }) {
  if (!string) return null

  return currency(string.replace(currencyCodes[currencyCode].separator, ".")).multiply(100).value
}

function displayCurrencyString({ string, currencyCode = "GBP" }) {
  if (!string) return ""

  const parts = string.split(currencyCodes[currencyCode].separator);
  const numberPart = parts[0].replace(thousandsRegex, currencyCodes[currencyCode].delimiter);
  const decimalPart = parts[1] ? `${currencyCodes[currencyCode].separator}${parts[1]}` : "";

  return `${currencyCodes[currencyCode].unit}${numberPart}${decimalPart}`
}

export {
  currencyCodes,
  currencyNumberToCurrencyString,
  currencyStringToCurrencyNumber,
  displayCurrencyString
}
