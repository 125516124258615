import React, { useRef } from 'react';
import { focusField } from 'components/helpers/refs';
import PropTypes from 'prop-types';

import SidePanel from 'components/application/SidePanel';
import ShowFooter from 'components/sidepanels/ShowFooter';
import FormFooter from 'components/sidepanels/FormFooter';
import CoshhShow from 'components/projectCoshh/CoshhShow';
import CoshhForm from 'components/projectCoshh/CoshhForm';

export default function ProjectCoshhSidePanel(props) {
  const {
    coshhDocument,
    sidePanelContext,
    closeSidePanel,
    sidePanelIsOpen,
    setSidePanelContext,
    onDeleteProjectCoshhClick,
    onUpdateCoshhDocument,
    onNewCoshhDocument,
    onCoshhInputChange,
    onCoshhOptionChange,
    onOptionSelect,
    workerExposureTimeOptions,
    exposureFrequencyOptions,
    locationOptions,
    methodOfUseOptions,
    personsAtRiskOptions,
    submitDisabled,
    secondaryFieldLabel,
    secondaryFieldDefaultValue,
    requestError,
    removeErrorStyling,
  } = props;

  const openEditCoshhDocument = () => { setSidePanelContext('edit') };
  const sidePanelBodyRef = useRef();
  const sidePanelFieldRef = useRef();

  let heading = ''
  switch(sidePanelContext) {
    case 'edit':
      heading = 'Edit COSHH assessment'
      break
    case 'new':
      heading = 'Add COSHH assessment'
      break
    case 'show':
      heading = 'COSHH assessment'
      break
  }

  const headingStyle = sidePanelContext === 'show' ? (
    'tw-text-s tw-font-medium tw-tracking-tight m-b-8' ) : (
    'tw-text-grey-900 tw-text-xl tw-font-semibold tw-tracking-tight'
  )

  const titleHeaderContent = (
    <div className='popup__title popup__title--tertiary-split'>
      <h1 className={`${headingStyle}`}>{heading}</h1>
      <h2 className={'tw-text-xl tw-font-semibold tw-tracking-tight tw-text-grey-700 tw-mt-2'}>
        { coshhDocument.title }
      </h2>
      {sidePanelContext === 'show' && (
        <>
          {coshhDocument.manufacturer && <p className={`tw-text-l tw-font-normal tw-text-grey-500 tw-mt-2 ${coshhDocument.reference ? 'tw-mb-2' : ''}`}>{coshhDocument.manufacturer.toUpperCase()}</p>}
          {coshhDocument.reference && <p className={'tw-text-l tw-font-normal tw-text-grey-500 tw-mt-2 tw-mb-0'}>REF: {coshhDocument.reference.toUpperCase()}</p>}
        </>
      )}
    </div>
  );

  const showBodyContent = (
    <CoshhShow
      coshhDocument={coshhDocument}
      secondaryFieldLabel={secondaryFieldLabel}
      secondaryFieldDefaultValue={secondaryFieldDefaultValue}
    />
  );

  const formBodyContent = (
    <CoshhForm
      coshhDocument={coshhDocument}
      onCoshhInputChange={onCoshhInputChange}
      onCoshhOptionChange={onCoshhOptionChange}
      onOptionSelect={onOptionSelect}
      workerExposureTimeOptions={workerExposureTimeOptions}
      exposureFrequencyOptions={exposureFrequencyOptions}
      locationOptions={locationOptions}
      methodOfUseOptions={methodOfUseOptions}
      personsAtRiskOptions={personsAtRiskOptions}
      secondaryFieldLabel={secondaryFieldLabel}
      requestError={requestError}
      removeErrorStyling={removeErrorStyling}
      sidePanelFieldRef={sidePanelFieldRef}
    />
  );

  const showFooterContent = (
    <ShowFooter
      type={'assessment'}
      resource={'resource'}
      onEditClick={openEditCoshhDocument}
      onDeleteClick={() => onDeleteProjectCoshhClick(true)}
      editType={'assessment'}
    >
    </ShowFooter>
  );

  const formFooterContent = (
    <FormFooter
      text={sidePanelContext === 'new' ? 'Add COSHH' : 'Save changes'}
      onCancel={closeSidePanel}
      onSubmit={sidePanelContext === 'new' ? onNewCoshhDocument : onUpdateCoshhDocument}
      submitButtonDisabled={submitDisabled}
    />
  );

  const footerContent = () => {
    switch(sidePanelContext) {
      case 'show': return showFooterContent
      case 'edit': return formFooterContent
      case 'new': return formFooterContent
    }
  };

  const onBackdropClick = () => {
    const panelLocked = (['edit', 'new'].includes(sidePanelContext))
    if (!panelLocked) { closeSidePanel() }
  }

  return (
    <SidePanel
      color={'tertiary'}
      isOpen={sidePanelIsOpen}
      displayClose={sidePanelContext === 'show'}
      onBackdropClick={onBackdropClick}
      closeCallback={closeSidePanel}
      headerContent={titleHeaderContent}
      bodyContent={sidePanelContext === 'show' ? showBodyContent : formBodyContent}
      bodyRef={sidePanelBodyRef}
      scrollHeader={false}
      footerContent={footerContent()}
      contentContext={sidePanelContext}
      submitDisabled={false}
      onOpen={() => sidePanelContext === 'new' && focusField(sidePanelFieldRef)}
    />
  )
}

ProjectCoshhSidePanel.propTypes = {
  coshhDocument: PropTypes.object.isRequired,
  sidePanelContext: PropTypes.string.isRequired,
  closeSidePanel: PropTypes.func.isRequired,
  sidePanelIsOpen: PropTypes.bool.isRequired,
  setSidePanelContext: PropTypes.func.isRequired,
  onDeleteProjectCoshhClick: PropTypes.func,
  onUpdateCoshhDocument: PropTypes.func,
  onNewCoshhDocument: PropTypes.func,
  onCoshhInputChange: PropTypes.func.isRequired,
  onCoshhOptionChange: PropTypes.func.isRequired,
  workerExposureTimeOptions: PropTypes.array.isRequired,
  exposureFrequencyOptions: PropTypes.array.isRequired,
  locationOptions: PropTypes.array.isRequired,
  methodOfUseOptions: PropTypes.array.isRequired,
  secondaryFieldLabel: PropTypes.string.isRequired,
  secondaryFieldDefaultValue: PropTypes.string.isRequired
};
