import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { resourceShape } from 'components/helpers/serialisableResources'
import ModalBox from 'components/application/ModalBox';
import TextButton from 'components/application/buttons/TextButton';
import FilledButton from 'components/application/buttons/FilledButton';

export default function SendBookingConnectionUpdatedNotificationModal({
  onClose,
  onSubmit,
  bookingConnectionUpdatedMutationData
}) {
  const bookingSourceRelation = bookingConnectionUpdatedMutationData.data.relationships.source.data;
  const bookingSource = bookingConnectionUpdatedMutationData.included.find((inclusion) => inclusion.type === bookingSourceRelation.type && inclusion.id === bookingSourceRelation.id);
  const course = bookingConnectionUpdatedMutationData.included.find((inclusion) => inclusion.type === 'course' && inclusion.id === bookingSource.relationships.course.data.id);
  const { changedPersonnelCount, unchangedPersonnelIds } = bookingConnectionUpdatedMutationData.meta;
  const unchangedPersonnelCount = unchangedPersonnelIds.length;
  const totalNotificationsCount = unchangedPersonnelCount + changedPersonnelCount;

  return (
    <ModalBox
      mode='letterbox'
      isOpen={true}
      onClose={onClose}
      customFooter={
        <div className='modalbox__footer-actions modalbox__footer-actions--right'>
          <TextButton size='md' color='blue' className='m-r-12' onClick={onClose}>
            {changedPersonnelCount > 0 ? `Only notify ${changedPersonnelCount} added/removed personnel` : "Don't send notification"}
          </TextButton>
          <FilledButton color='mint' onClick={onSubmit}>
            Notify all {totalNotificationsCount} personnel
          </FilledButton>
        </div>
      }
    >
      <div className='tw-p-6'>
        <h2 className='tw-text-l tw-text-grey-900 tw-font-semibold tw-m-0 tw-mb-6'>Notify {changedPersonnelCount > 0 ? 'existing' : unchangedPersonnelCount} personnel of changes in booking details?</h2>
        <p className='tw-m-0'>Course: <span className='tw-font-semibold'>{course.attributes.name}</span></p>
        <p className='tw-m-0 tw-mb-6'>Training Date: <span className='tw-font-semibold'>{moment(bookingSource.attributes.date).format("D MMM YYYY")}</span></p>
        <p className='tw-m-0'>
          A notification will be sent to {totalNotificationsCount} personnel and their line manager if they have email addresses.
        </p>
      </div>
    </ModalBox>
  )
}

SendBookingConnectionUpdatedNotificationModal.propTypes = {
  bookingConnectionUpdatedMutationData: PropTypes.shape({ data: resourceShape('bookingConnection') }),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
