import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/application/Tooltip';
import TextButton from 'components/application/buttons/TextButton';

export default function ShowFooter(props) {
  const buttonWrapperClassName = props.isCompact ? 'fh-48' : 'fh-58';

  return (
    <React.Fragment>
      {!props.isEditButtonHidden && (
        <div className={`tooltip-parent${props.editDisabled ? ' tooltip-parent--disallowed' : ''}`}>
          <div className={`tw-border-solid tw-border-0 tw-border-t-1 tw-border-grey-100 ${buttonWrapperClassName}`}>
            <TextButton size='full' color='blue' onClick={props.onEditClick} disabled={props.editDisabled}>
              Edit {props.type || 'details'}
            </TextButton>
          </div>
          {props.editDisabled && <Tooltip className={props.isCompact ? 'pos-t-12' : 'pos-t-16'} placement='top' trigger='hover' tooltip={props.editDisabledTooltip} />}
        </div>
      )}
      {props.children}
      <div className={`tooltip-parent${props.deleteDisabled ? ' tooltip-parent--disallowed' : ''}`}>
        <div className={`tw-border-solid tw-border-0 tw-border-t-1 tw-border-grey-100 ${buttonWrapperClassName}`}>
          <TextButton size='full' color='red' disabled={props.deleteDisabled} onClick={props.onDeleteClick}>
            {`Delete${props.type ? ` ${props.type}` : ''}`}
          </TextButton>
        </div>
        {props.deleteDisabled && <Tooltip className={props.isCompact ? 'pos-t-12' : 'pos-t-16'} placement='top' trigger='hover' tooltip={props.deleteDisabledTooltip} />}
      </div>
    </React.Fragment>
  )
}

ShowFooter.propTypes = {
  type: PropTypes.string,
  buttonProps: PropTypes.object,
  editDisabled: PropTypes.bool,
  editDisabledTooltip: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  deleteDisabledTooltip: PropTypes.string,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func
}

ShowFooter.defaultProps = {
  editDisabled: false,
  deleteDisabled: false,
  isCompact: false,
  isEditButtonHidden: false
}
